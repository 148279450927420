import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { EntitytableService } from '../entitytable.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-entitytable-designer',
  templateUrl: './entitytable-designer.component.html',
  styleUrls: ['./entitytable-designer.component.scss']
})
export class EntitytableDesignerComponent implements OnInit {
  tableLayout = null;
  tableSettingsJson = null;
  dataTableItem = null;
  entityId = 1;
  availableEntities = [];
  toggledItems = [];
  editorMode = 1;

  newEntry = {
    entityId: 1,
    memberName: "id",
    memberId: null,
    controlType: 0,
    sortable: true,
    filterable: true,
    sortColumn: false,
    sortDirection: "asc",
    lookupTableId: null,
    lookupTableSource: null,
    alternateLookupTable: null,
    nameTranslationToken: null,
    nameTranslationTokenAlt: null
  }

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService,
    private eventService: EventService,
    private loaderService: LoaderService,
    public entitytableService: EntitytableService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit(): void {
    this.dataTableItem = this.entitytableService.getEntityTableDefinitionForControl(this.applicationInfoService.miscSettings['entityTableLogicalControlId']);

    if (this.dataTableItem != null) {
      this.tableLayout = JSON.parse(this.dataTableItem.jsonLayout);      
      this.tableSettingsJson = JSON.parse(this.dataTableItem.jsonSettings);      
      this.entityId = this.dataTableItem.mainEntityId;
      const filterSectionName = 'entityTableFilter'.concat(this.dataTableItem.id);
      this.applicationInfoService.miscSettings[filterSectionName] = null;  
    }
    this.getAvailableEntities();
  }

  // changeBaseEntity() {
  //   this.getAvailableEntities();
  //   this.entityId = this.dataTableItem.mainEntityId;
  //   this.tableLayout = [];
  // }

  getAvailableEntities() {
    this.availableEntities = [];
    if (this.dataTableItem == null) { return; }
    switch (this.dataTableItem.mainEntityId) {
      case 1:
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.vipSettingEntity.toString()));        
        break;
      case 2:
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('2'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('4'));
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.vipSettingEntity.toString()));        
        this.availableEntities.push(this.applicationInfoService.entities.Item('462'));        
        this.availableEntities.push(this.applicationInfoService.entities.Item('780'));        
        break;
      case 9:
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('2'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('4'));
        break;
      case 4: 
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('4'));
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.aiContactEntity.toString()));        
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.vipSettingEntity.toString()));        
        break;
      case 462: // conversion
        this.availableEntities.push(this.applicationInfoService.entities.Item('462'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('4'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        break;
      case 917: // prospect  
        this.availableEntities.push(this.applicationInfoService.entities.Item('917'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('975'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        break;
      case 934: // recording
        this.availableEntities.push(this.applicationInfoService.entities.Item('934'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        break;
      case this.entitytableService.vipEntity: // vip
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.vipEntity.toString()));
        break;
      case this.entitytableService.aiContactEntity:
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('4'));
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.aiContactEntity.toString()));
        break;
      case this.entitytableService.optinEntity:
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('4'));
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.optinEntity.toString()));
        break;
      case this.entitytableService.emailEntity:
        this.availableEntities.push(this.applicationInfoService.entities.Item('462'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('4'));
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.emailEntity.toString()));
        break;
      case this.entitytableService.eventsEntity:
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.eventsEntity.toString()));
        break;  
      case this.entitytableService.contentLibraryFileEntity:
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.contentLibraryFileEntity.toString()));
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('4'));
        break;
      case this.entitytableService.opportunityEntity:
        this.availableEntities.push(this.applicationInfoService.entities.Item(this.entitytableService.opportunityEntity.toString()));
        this.availableEntities.push(this.applicationInfoService.entities.Item('1'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('4'));
        this.availableEntities.push(this.applicationInfoService.entities.Item('1256'));
        break;
    }
  }

  availableMembers() {    
    if (this.entityId == null) {
      return [];
    }    
    let entityId = null;
    switch(this.entityId) {
      case 9:
        entityId = 2;
        break;
      default: 
        entityId = this.entityId;
        break;
    }
    const entity = this.applicationInfoService.entities.Item(entityId.toString());    
    this.commonService.sortArrayWithTranslationToken(entity.entityMembers);
    return entity.entityMembers;
  }

  moveInList(event: CdkDragDrop<string[]>, item: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  addMember(member, entityId, memberName, stealthMember = false, additionalSettings = null) {
    if (member) {
      const tableItem = this.getTableLayoutMember(entityId, member.defaultName);
      if (tableItem) {
        tableItem.hideInDesigner = false;
        tableItem.visible = true;
        return;
      }  
    }
    const newMember = JSON.parse(JSON.stringify(this.newEntry));
    if (member) {
      newMember.memberName = member.defaultName;
      if (member.nameTranslationToken != null) {
        newMember.memberName = member.nameTranslationToken.tokenFullName;
      }
      newMember.memberId = member.id;
      newMember.alternateLookupTable = member.alternativeLookupTable; 
      newMember.memberType = member.memberType;
    } else {
      newMember.memberName = memberName;
      newMember.memberId = null;
    }    
    newMember.entityId = entityId;    
    
    // ControlTypeByType
    switch(newMember.memberType) {
      case 'DATETIME':
        newMember.controlType = 1;
        break;
    }

    // ControlDetailsByName
    switch(newMember.memberName) {
      case 'createdAt':
        newMember.controlType = 1;
      break;
      case 'createdBy':
        newMember.alternateLookupTable = 'projectUser';
      break;
      case 'calculatedLeadStateId':
        newMember.alternateLookupTable = 'projectleadstate';
      break;
      case 'leadStateId':
        newMember.alternateLookupTable = 'projectleadstate';
      break;
      case 'stepId':
        newMember.alternateLookupTable = 'steps';
      break;
      case 'resultId':
        newMember.alternateLookupTable = 'results';
      break;
      case 'campaignId':
        newMember.alternateLookupTable = 'campaigns';
      break;      
      case 'calculatedEditingState':
        newMember.alternateLookupTable = 'progresstates';
      break;
      case 'poolId':
        newMember.alternateLookupTable = 'pools';
      break;
      case 'custom':
        // Custom als Image vorkonfigurieren
        newMember.controlType = 3;
        newMember.ignoreForQuery = true;
        newMember.alternateQuickTextForValueControl = ' ';
      break;
      
    }
    
    // ControlDetailsByNamePart
    if (this.commonService.checkIfStringContainsString(newMember.alternateLookupTable, 'userid')) {
      newMember.alternateLookupTable = 'projectUser';
    }
    
    if (stealthMember) {
      newMember.visible = false;
      newMember.hideInDesigner = true;
    } else {
      newMember.visible = true;
    }
    this.tableLayout.push(newMember);    
    this.updateHeaderTranslation();
  }

  removeItem(tableLayoutItem) {
    this.commonService.removeItemFromArray(this.tableLayout, tableLayoutItem);
  }

  createArrayJason(array) {
    let arrayJSON = JSON.stringify(array);
    return arrayJSON;
  }

  addHiddenMember() {
    switch (this.dataTableItem.mainEntityId) {
      case 1:
        this.addHiddenMemberIfRequired(1, 'poolId');
        break;
      case 2:
        if (this.dataTableItem.mainEntityName == 'activity') {
          this.addHiddenMemberIfRequired(2, 'resultUserId');
        } else {
          this.addHiddenMemberIfRequired(2, 'followUpUserId');
        }
        this.addHiddenMemberIfRequired(2, 'campaignId');
        this.addHiddenMemberIfRequired(1, 'poolId');
      break;
      // case 9:
      //   this.addHiddenMemberIfRequired(2, 'resultUserId');
      //   this.addHiddenMemberIfRequired(2, 'campaignId');
      //   this.addHiddenMemberIfRequired(1, 'poolId');
      break;
      case 4:
        this.addHiddenMemberIfRequired(1, 'poolId');
        this.addHiddenMemberIfRequired(4, 'accountId');
        this.addHiddenMemberIfRequired(4, 'isAiContact');
        this.addHiddenMemberIfRequired(4, 'isAiSuggestion');
        break;
      case this.entitytableService.optinEntity: 
        this.addHiddenMemberIfRequired(this.entitytableService.optinEntity, 'accountId');
        this.addHiddenMemberIfRequired(this.entitytableService.optinEntity, 'contactId');
        break;
      case 462:
        this.addHiddenMemberIfRequired(1, 'poolId');
        this.addHiddenMemberIfRequired(462, 'contactId');
        this.addHiddenMemberIfRequired(462, 'url');
        this.addHiddenMemberIfRequired(462, 'assetType');
        break;
      case 917:
        this.addHiddenMemberIfRequired(917, 'accountId');
        this.addHiddenMemberIfRequired(917, 'poolId'); 
        this.addHiddenMemberIfRequired(917, 'isBlacklisted');
        this.addHiddenMemberIfRequired(1, 'street');
        this.addHiddenMemberIfRequired(1, 'zipcode');
        this.addHiddenMemberIfRequired(1, 'city');
        this.addHiddenMemberIfRequired(1, 'name1');
        break;
      case this.entitytableService.vipEntity:
        break;
      case this.entitytableService.aiContactEntity:
        this.addHiddenMemberIfRequired(4, 'isAiContact');
        this.addHiddenMemberIfRequired(4, 'isAiSuggestion');
        this.addHiddenMemberIfRequired(this.entitytableService.aiContactEntity, 'pool_id');
        this.addHiddenMemberIfRequired(this.entitytableService.aiContactEntity, 'leadscore');
        break;
      case this.entitytableService.eventsEntity:
        this.addHiddenMemberIfRequired(this.entitytableService.eventsEntity, 'prospectId');
        break;
      case this.entitytableService.opportunityEntity:
        this.addHiddenMemberIfRequired(this.entitytableService.opportunityEntity, 'accountId');
        this.addHiddenMemberIfRequired(this.entitytableService.opportunityEntity, 'contactId');
        this.addHiddenMemberIfRequired(this.entitytableService.opportunityEntity, 'value');
        this.addHiddenMemberIfRequired(this.entitytableService.opportunityEntity, 'comment');
        this.addHiddenMemberIfRequired(this.entitytableService.opportunityEntity, 'statusId');
        this.addHiddenMemberIfRequired(this.entitytableService.opportunityEntity, 'createdBy');
        this.addHiddenMemberIfRequired(1, 'poolId');
        break;
    }    
  }

  setDetaultSort(tableLayoutItem) {
    this.tableLayout.forEach(layout => {
      layout.sortColumn = false;
    });
    tableLayoutItem.sortColumn = true;
    tableLayoutItem.defaultSort = 'DESC_NULLS_LAST'
  }

  changeSortDirection(tableLayoutItem) {
    if (tableLayoutItem.defaultSort == 'ASC_NULLS_LAST') {
      tableLayoutItem.defaultSort = 'DESC_NULLS_LAST';
    } else {
      tableLayoutItem.defaultSort = 'ASC_NULLS_LAST';
    }
  }

  updateTableLayout(saveAsDefault = false) {
    this.applicationInfoService.miscSettings[
      'entityTableFilter'.concat(this.applicationInfoService.miscSettings['entityTableLogicalControlId'])
    ] = null;   
    
    // Required hidden member
    this.addHiddenMember();

    // Rest
    this.updateHeaderTranslation();
    this.dataTableItem.jsonLayout = this.createArrayJason(this.tableLayout);
    this.dataTableItem.jsonSettings = this.createArrayJason(this.tableSettingsJson);

    if (saveAsDefault) {
      // Dieses Layout als neues Standardlayout speichern
      this.entitytableService.createOrUpdateUniversalTemplateFromThisLayout(
        this.dataTableItem, 
        this.applicationInfoService.miscSettings['entityTableLogicalControlId']
      );
      return;
    }
    this.loaderService.display(true);

    if (this.dataTableItem.projectId == null) {
      this.dataTableItem.projectId = this.applicationInfoService.projectID;
      this.entitytableService.createNewProjectSpecificEntityTableDefinition(this.dataTableItem)
      .then(createNewProjectSpecificEntityTableDefinitionResult => {
        this.dataTableItem = createNewProjectSpecificEntityTableDefinitionResult;
        this.loaderService.display(false);
        this.updateTableEvent();
      }).catch(() => { this.loaderService.display(false); });  
    } else {
      this.externaldatasourceService.executeExternalDataSource(764, [
        this.commonService.getModifyArrayBody(this.dataTableItem, [])
      ]).then(() => {
        this.applicationInfoService.tableLayoutDefinitions.forEach(definition => {
          if (definition.id == this.dataTableItem) {
            definition.jsonLayout = this.createArrayJason(this.tableLayout);
          }        
        });
        this.loaderService.display(false);
        this.updateTableEvent();
      }).catch(() => { this.loaderService.display(false); });  
    }
  }

  createTableLayout() {
    this.addHiddenMember();
    this.updateHeaderTranslation();    
    this.dataTableItem.jsonLayout = this.createArrayJason(this.tableLayout);
    this.dataTableItem.jsonSettings = this.createArrayJason(this.tableSettingsJson);    
    this.externaldatasourceService.executeExternalDataSource(766, [this.commonService.getModifyArrayBody(this.dataTableItem, ['id'])
    ]).then(createTableLayoutResult => {
      this.applicationInfoService.tableLayoutDefinitions.push(this.dataTableItem);
      this.dataTableItem.id = createTableLayoutResult.id;
      this.updateTableEvent();
    });
  }

  updateTableEvent() {    
    this.eventService.customEvent.emit({
      eventType: 'updateEntityTableView',
      dataTableItem: this.dataTableItem,
      tableLayout: JSON.parse(JSON.stringify(this.tableLayout))
    });
  }

  createDataTableItem(mainEntity, defaultValues = false, mainEntityName = null) {
    // Sonderfall Activity
    this.tableLayout = [];
    this.entityId = mainEntity.logicalUiEntityId;
    this.dataTableItem = {
      id: null,
      projectId: this.applicationInfoService.projectID,
      logicalControlId: this.applicationInfoService.miscSettings['entityTableLogicalControlId'],
      mainEntityId: mainEntity.uiEntityInstance,  
      jsonLayout: '',
      jsonSettings: '',
      filterId: null,
      mainEntityName: mainEntity.entityName,
      defaultName: mainEntity.entityName
    }

    if (mainEntityName != null) { 
      this.dataTableItem.mainEntityName = mainEntityName;
    }

    this.tableSettingsJson = JSON.parse(`{ 
      "showOnlyDataForCurrentUser": false,
      "onlyForCurrentPool": true,
      "onlyForCurrentCampaign": true
    }`);

    if (defaultValues) {
      console.warn(mainEntity.id);
      switch (mainEntity.id) {
        case 1:
          this.addMember(null, 1, 'id');
          this.addMember(this.getEntityMember(1, 'name1'), 1, '');
          this.addMember(this.getEntityMember(1, 'street'), 1, '');
          this.addMember(this.getEntityMember(1, 'zipcode'), 1, '');
          this.addMember(this.getEntityMember(1, 'city'), 1, '');
          this.addMember(this.getEntityMember(1, 'phone'), 1, '');
          this.addMember(this.getEntityMember(1, 'email'), 1, '');
          break;
        case 2:
          if (this.dataTableItem.mainEntityName == 'activity') {
            this.addMember(null, 2, 'id');
            this.addMember(this.getEntityMember(1, 'name1'), 1, '');
            this.addMember(this.getEntityMember(4, 'salutationId'), 4, '');
            this.addMember(this.getEntityMember(4, 'firstName'), 4, '');
            this.addMember(this.getEntityMember(4, 'lastName'), 4, '');
            this.addMember(this.getEntityMember(2, 'resultDate'), 2, '');
            this.addMember(this.getEntityMember(2, 'resultUserId'), 2, '');
            this.addMember(this.getEntityMember(2, 'stepId'), 2, '');
            this.addMember(this.getEntityMember(2, 'resultId'), 2, '');
            this.addMember(this.getEntityMember(2, 'notes'), 2, ''); 
          } else {
            this.addMember(null, 2, 'id');
            this.addMember(this.getEntityMember(1, 'name1'), 1, '');
            this.addMember(this.getEntityMember(4, 'salutationId'), 4, '');
            this.addMember(this.getEntityMember(4, 'firstName'), 4, '');
            this.addMember(this.getEntityMember(4, 'lastName'), 4, '');
            this.addMember(this.getEntityMember(2, 'followUpDate'), 2, '');
            this.addMember(this.getEntityMember(2, 'followUpUserId'), 2, '');
            this.addMember(this.getEntityMember(2, 'stepId'), 2, '');
            this.addMember(this.getEntityMember(2, 'notes'), 2, '');  
          }
          break;
        // case 9: // Activity
        //   this.addMember(null, 2, 'id');
        //   this.addMember(this.getEntityMember(1, 'name1'), 1, '');
        //   this.addMember(this.getEntityMember(4, 'firstName'), 4, '');
        //   this.addMember(this.getEntityMember(4, 'lastName'), 4, '');
        //   this.addMember(this.getEntityMember(2, 'resultDate'), 2, '');
        //   this.addMember(this.getEntityMember(2, 'resultUserId'), 2, '');
        //   this.addMember(this.getEntityMember(2, 'stepId'), 2, '');
        //   this.addMember(this.getEntityMember(2, 'resultId'), 2, '');
        //   this.addMember(this.getEntityMember(2, 'notes'), 2, '');
        //   break;
        case 4:
          this.addMember(null, 4, 'id');
          this.addMember(this.getEntityMember(1, 'name1'), 1, '');
          this.addMember(this.getEntityMember(4, 'firstName'), 4, '');
          this.addMember(this.getEntityMember(4, 'lastName'), 4, '');
          this.addMember(this.getEntityMember(4, 'extension'), 4, '');          
          this.addMember(this.getEntityMember(4, 'email'), 4, '');
          break;
        case 462:          
          this.addMember(this.getEntityMember(462, 'assetName'), 462, '');
          this.addMember(this.getEntityMember(462, 'assetType'), 462, '');
          this.addMember(this.getEntityMember(462, 'assetId'), 462, '');
          this.addMember(this.getEntityMember(462, 'date'), 462, '');
          this.addMember(this.getEntityMember(462, 'url'), 462, '');
          this.addMember(this.getEntityMember(462, 'calculatedPotential'), 462, '');
          break;
        case 917:
          this.addMember(this.getEntityMember(917, 'logourl'), 917, '');
          this.addMember(this.getEntityMember(917, 'companyName'), 917, '');
          this.addMember(this.getEntityMember(917, 'country'), 917, '');
          this.addMember(this.getEntityMember(917, 'address'), 917, '');
          this.addMember(this.getEntityMember(917, 'url'), 917, '');
          this.addMember(this.getEntityMember(917, 'lastVisited'), 917, '');
          this.addMember(this.getEntityMember(917, 'activity'), 917, '');
          this.addMember(null, 917, 'leadScore');
          break;
        case 934:
          this.addMember(this.getEntityMember(1, 'name1'), 1, '');
          this.addMember(this.getEntityMember(934, 'recordingLookupId'), 934, '');
          this.addMember(this.getEntityMember(934, 'startTime'), 934, '');
          this.addMember(this.getEntityMember(934, 'duration'), 934, '');
          this.addMember(this.getEntityMember(934, 'notes'), 934, '');
          break;
        case this.entitytableService.vipEntity:          
          this.addMember(this.getEntityMember(this.entitytableService.vipEntity, 'companyName'), this.entitytableService.vipEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.vipEntity, 'address'), this.entitytableService.vipEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.vipEntity, 'city'), this.entitytableService.vipEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.vipEntity, 'lastVisited'), this.entitytableService.vipEntity, '');          
          this.addMember(this.getEntityMember(this.entitytableService.vipEntity, 'numactivities'), this.entitytableService.vipEntity, '');          
          this.addMember(this.getEntityMember(this.entitytableService.vipEntity, 'email'), this.entitytableService.vipEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.vipEntity, 'ip'), this.entitytableService.vipEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.vipEntity, 'vipSource'), this.entitytableService.vipEntity, '');
          break;     
        case this.entitytableService.aiContactEntity:
          this.addMember(null, 4, 'id');
          this.addMember(this.getEntityMember(1, 'name1'), 1, '');
          this.addMember(this.getEntityMember(4, 'firstName'), 4, '');
          this.addMember(this.getEntityMember(4, 'lastName'), 4, '');
          this.addMember(this.getEntityMember(4, 'extension'), 4, '');          
          this.addMember(this.getEntityMember(4, 'email'), 4, '');
          break;  
        case this.entitytableService.vipSettingEntity:
          this.addMember(this.getEntityMember(this.entitytableService.vipSettingEntity, 'typeRefLookupId'), this.entitytableService.vipSettingEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.vipSettingEntity, 'userId'), this.entitytableService.vipSettingEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.vipSettingEntity, 'value'), this.entitytableService.vipSettingEntity, '');
          break;   
        case this.entitytableService.optinEntity:
          this.addMember(this.getEntityMember(this.entitytableService.optinEntity, 'comment'), this.entitytableService.optinEntity, '');
          break;         
        case 156:
          this.addMember(this.getEntityMember(1256, 'defaultName'), 1256, '');
          this.addMember(this.getEntityMember(4, 'lastName'), 4, '');
          this.addMember(this.getEntityMember(this.entitytableService.opportunityEntity, 'value'), this.entitytableService.opportunityEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.opportunityEntity, 'expectedValue'), this.entitytableService.opportunityEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.opportunityEntity, 'comment'), this.entitytableService.opportunityEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.opportunityEntity, 'createdAt'), this.entitytableService.opportunityEntity, '');
          this.addMember(this.getEntityMember(this.entitytableService.opportunityEntity, 'createdBy'), this.entitytableService.opportunityEntity, '');
          break;
      }
    }

    if (mainEntity.id == 9) {
      this.entityId = 2;
      this.dataTableItem.mainEntityName = 'task';
      this.dataTableItem.mainEntityName = 'activity';
    }
    this.getAvailableEntities();
  }

  getEntityMember(entityId, memberName) {
    return this.applicationInfoService.entities.Item(entityId.toString()).entityMembers.find(member => member.defaultName == memberName);
  }

  toggleItem(tableLayoutItem) {
    const toggleString = tableLayoutItem.entityId + tableLayoutItem.memberName;
    if (this.toggledItems[toggleString] == true)  {
      this.toggledItems[toggleString] = false;
    } else {
      this.toggledItems[toggleString] = true;
    }
  }

  updateHeaderTranslation() {
    this.tableLayout.forEach(tableLayoutItem => {
      tableLayoutItem.displayText = '';
      if (tableLayoutItem.quickText != '' && !this.commonService.isNullOrUndefined(tableLayoutItem.quickText)) {
        tableLayoutItem.displayText = tableLayoutItem.quickText;
        return;
      }
      const member = this.getEntityMember(tableLayoutItem.entityId, tableLayoutItem.memberName);
      if (member) {
        if (member.nameTranslationTokenId != null) {
           tableLayoutItem.displayText = this.axivasTranslateService.getTranslationTextForId(member.nameTranslationTokenId);
        } else {
          tableLayoutItem.displayText = member.defaultName;
        }
      }
    });
  }

  checkIfMemberNeedsToBeHidden(member) {
    const item = this.getTableLayoutMember(this.entityId, member.defaultName);
    if (item) {
      if (item.hideInDesigner==true) {
        return false;
      } else {
        return true;
      }      
    } else {
      return false;
    }
  }

  getTableLayoutMember(entityId, memberName) {
    return this.tableLayout.find(tableLayoutItem => (tableLayoutItem.entityId == entityId && tableLayoutItem.memberName == memberName));
  }
  
  addHiddenMemberIfRequired(entityId, memberName) {
    if (!this.getTableLayoutMember(entityId, memberName)) {
      this.addMember(this.getEntityMember(entityId, memberName), entityId, '', true);
    }    
  }
}
