<div class="campaignDashboard__listcontainer">
    <div class="campaignDashboard__listcontainer__header">
        <div class="campaignDashboard__listcontainer__header__name">{{ 'CampaignDashboard.Label.Name' | texttransform }}</div>
        <div class="campaignDashboard__listcontainer__header__progress">{{ 'CampaignDashboard.Label.Progress' | texttransform }}</div> 
    </div>


    <div class="campaignDashboard__listcontainer__listcontent" *ngIf="page === 0">
        <div *ngFor="let campaignOverviewItem of mailCampaignOverview" class="campaignDashboard__listrow"
        [ngClass]="{
            hideInDom: isFiltered(campaignOverviewItem.batchName, mailCampaignSearchString),
            'campaignDashboard__listrow__selected': campaignOverviewItem == selectedMailCampaign
        }">
            <div class="campaignDashboard__listrow__marker">
                <app-row-marker [blue]="campaignOverviewItem == selectedMailCampaign"></app-row-marker>
            </div>
            <div class="campaignDashboard__listrow__name">
                <div>{{ campaignOverviewItem.batchName | texttransform }}</div>
            </div>
            <div class="campaignDashboard__listrow__progress">
                <app-progress-bar [progress]="getCampaignProgressPercentage(campaignOverviewItem)"></app-progress-bar>
            </div>
            <div class="campaignDashboard__listrow__actions">
                <div class="campaignDashboard__listrow__actions__button"
                    (click)="sendMailDatatoParent(campaignOverviewItem)"
                    [id]="getCampaignIconId(campaignOverviewItem)">
                    <app-jj-icons [type]="campaignOverviewItem == selectedMailCampaign ? 'arrow-circle-right-fill' : 'arrow-circle-right'"></app-jj-icons>
                </div>
            </div>
        </div>
    </div>


    <div class="campaignDashboard__listcontainer__listcontent" *ngIf="page === 1">
        <div *ngFor="let campaignOverviewItem of microCampaignOverview" class="campaignDashboard__listrow"
        [ngClass]="{
            hideInDom: isFiltered(campaignOverviewItem.campaignName, microCampaignSearchString),
            'campaignDashboard__listrow__selected': campaignOverviewItem == selectedMicroCampaign
        }">
         <div class="campaignDashboard__listrow__marker">
            <app-row-marker [blue]="campaignOverviewItem == selectedMicroCampaign"></app-row-marker>
        </div>
        <div class="campaignDashboard__listrow__name">
            <div *ngIf="!campaignOverviewItem.nameTranslationTokenId">{{
                campaignOverviewItem.campaignName | texttransform }}</div>
            <div *ngIf="campaignOverviewItem.nameTranslationTokenId">{{
                axivasTranslateService.getTranslationTextForId(campaignOverviewItem.nameTranslationTokenId)
                | texttransform }}</div>
        </div>
        <div class="campaignDashboard__listrow__progress">
            <div class="campaignDashboard__listrow__progress__items">
                <div class="campaignDashboard__listrow__progress__items__item"
                [tippy]="'UserDashboard.Label.YourCampaignsTasks' | texttransform">
                <app-jj-icons [pointer]="false" type="pulse" [width]="20" [height]="20"></app-jj-icons>
                    {{ campaignOverviewItem.taskCount }}</div>
                <div class="campaignDashboard__listrow__progress__items__item"
                    [tippy]="'UserDashboard.Label.YourCampaignsContacts' | texttransform">
                    <app-jj-icons [pointer]="false" type="address-book-tabs" [width]="20" [height]="20"></app-jj-icons>
                        {{ campaignOverviewItem.contactCount }}</div>
                <div class="campaignDashboard__listrow__progress__items__item"
                    [tippy]="'UserDashboard.Label.YourCampaignsWins' | texttransform">
                    <app-jj-icons [pointer]="false" type="ranking" [width]="20" [height]="20"></app-jj-icons>
                    {{ campaignOverviewItem.positiveResultCount }}
                </div>
            </div>
         
        </div>
        <div class="campaignDashboard__listrow__actions">
                <div class="campaignDashboard__listrow__actions__button"
                (click)="sendMicroCampaignToParent(campaignOverviewItem)">
                <app-jj-icons [type]="campaignOverviewItem == selectedMicroCampaign ? 'arrow-circle-right-fill':'caret-circle-right'" [width]="30" [height]="30"></app-jj-icons>
            </div>
        </div>
    </div>
    </div>

</div>