<span id="navigationTopLevelSpan"></span>
<div *ngIf="applicationInfoService.projectSelected && !commonService.isNullOrUndefined(this.axivasTranslateService.translationTextArray)"
    class="navigationWrapper navigationMain navigationSizing" [ngClass]="{ 
        hideInDom: !applicationInfoService.navigationExpandedMobile &&  applicationInfoService.isMobile() && axivasTranslateService,
        navigationExpanded: applicationInfoService.navigationExpanded
    }">

    <!-- project related navigation -->
    <div class="topLevelAlexGross" *ngIf="!applicationInfoService.isMobile()">
        <div class="topLevelAlexGross__inner">
            <img src="assets/NEW_EVERLEAD_ICONS/navigation/everlead_navigation_logo_wide.png" 
                *ngIf="applicationInfoService.navigationExpanded" (click)="designLayout()">
            <img src="assets/NEW_EVERLEAD_ICONS/navigation/everlead_navigation_logo_small.png" 
                *ngIf="!applicationInfoService.navigationExpanded" (click)="designLayout()">
        </div>
    </div>
    
    <div class="navigationToggleBar navigationToggleBarCloseMenu" *ngIf="isSublevel" [ngClass]="{
        navigationToggleBarBorder: applicationInfoService.navigationExpanded
    }">    
        <div class="backIcon"  (click)="returnToMainLayer()">
            <app-jj-icons type="arrow-left" [width]="32" [height]="32" color="primaryLight"></app-jj-icons>
        </div>

        <div class="navigationToggleBar__text fingerCursor" *ngIf="isSublevel" (click)="returnToMainLayer()">
            <div *ngIf="applicationInfoService.navigationExpanded">
                {{'General.Menu.Back' | texttransform }}
            </div>            
        </div>
    </div>
    <div class="navigationToggleBar navigationToggleBarCloseDiv" *ngIf="!isSublevel" [ngClass]="{
        navigationToggleBarBorder: applicationInfoService.navigationExpanded
    }">
        <div class="navigationToggleBar__text fingerCursor" *ngIf="!isSublevel" 
            (click)="applicationInfoService.navigationExpanded = !applicationInfoService.navigationExpanded">
            <span *ngIf="applicationInfoService.navigationExpanded">{{'Navigation.Label.Close' | texttransform }}</span>
            <span *ngIf="!applicationInfoService.navigationExpanded" class="uppercase">
                {{'Navigation.Label.Menu' | texttransform }}
            </span>
        </div>            
    </div>

    <div class="scrollableInner" [ngClass]="{ navigationContainer: !isSublevel }" id="jsonMenuList">       
        <!-- JSON Menü -->
        <div class="navigationJson" [ngClass]="{ 
            maxHeight: isSublevel,
            navigationJson__loading: jsonLoading
        }">
            <div class="scrollableInner" [ngClass]="{ 
                scrollableInnerHeader: isSublevel,
                isSelected: isSublevel
            }">
                <div *ngFor="let menuItem of navigationLevel0" [id]="menuItem.guid">
                    <app-navigationitem [menuItem]="menuItem" [navigationExpanded]=applicationInfoService.navigationExpanded 
                        [isSublevel]="isSublevel" *ngIf="menuItem.defaultName != 'Supervisor.Menu.MainItem'">
                    </app-navigationitem>
                </div>       
            </div>
            <div *ngIf="isSublevel" class="scrollableInner">
                <div *ngFor="let child of subItems">
                    <app-navigationitem [menuItem]="child" [navigationExpanded]=applicationInfoService.navigationExpanded 
                        *ngIf="showJsonMenuItem(child)"></app-navigationitem>
                </div>        
            </div>
        </div>  

        <div class="shortCutMenu" *ngIf="!isSublevel && !isSettingNavigation">
            <div class="shortCutHeader">
                <div class="shortCutHeader__text">
                    <div class="navigationSmallText" *ngIf="applicationInfoService.navigationExpanded">
                        {{'Navigation.Label.MyShortcuts' | texttransform }}
                    </div>
                </div>  
                <div class="shortCutHeader__edit">
                    <div class="navigationToggleBar__shortCutEdit" *ngIf="applicationInfoService.navigationExpanded">
                        <svg-icon src="assets/NEW_EVERLEAD_ICONS/navigation/everlead_navigation_edit.svg" 
                            [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="editShortCuts()"></svg-icon>                        
                    </div>
                </div>              
            </div>            
            <div class="navigationSmallTextCollapsed" *ngIf="!applicationInfoService.navigationExpanded">{{'Navigation.Label.Shorts' | texttransform }}</div>
            <div cdkDropList [cdkDropListData]="applicationInfoService.projectShortCuts" (cdkDropListDropped)="moveInList($event, '0')">
                <div cdkDrag [cdkDragData]="item" data-html="true" *ngFor="let projectShortCut of applicationInfoService.projectShortCuts; let shortCutIndex = index">
                    <div *ngIf="projectShortCut != null" class="shortcutField">
                        <app-navigationitem [menuItem]="projectShortCut" [navigationExpanded]=applicationInfoService.navigationExpanded *ngIf="showJsonMenuItem(settingsItem)"></app-navigationitem>
                        <i class="fas fa-grip-lines shortcutFieldGrip fingerCursor" cdkDragHandle *ngIf="applicationInfoService.navigationExpanded"></i>                
                    </div>
                    <div *ngIf="projectShortCut == null"  (click)="addShortCut(shortCutIndex)">
                        <div class="createShortCut" [ngClass]="{ createShortCut__small: !applicationInfoService.navigationExpanded }">
                            <app-jj-icons type="plus" [width]="24" [height]="24" color="secondaryShade2" class="projectShortCut__icon"></app-jj-icons>
                            <div *ngIf="applicationInfoService.navigationExpanded" class="createShortCut__text">
                                {{'Navigation.Label.AddShortCut' | texttransform }}
                            </div>                        
                        </div>
                    </div>
                </div>    
            </div>
        </div>    
    </div>           
    
    <span *ngIf="getBreadCrumbText()"></span>

    <div class="navigationFooter">
        <div class="navigationToggleBar navigationToggleBarCloseMenu" [ngClass]="{
            navigationToggleBarBorder: applicationInfoService.navigationExpanded
        }" *ngIf="applicationInfoService.navigationExpanded">
            <div class="backIcon" (click)="applicationInfoService.navigationExpanded = false">
                <app-jj-icons type="x" [width]="32" [height]="32" color="primaryLight"></app-jj-icons>
            </div>
            <div class="navigationToggleBar__text fingerCursor" (click)="applicationInfoService.navigationExpanded = false;
                applicationInfoService.navigationExpandedMobile = false">
                <div *ngIf="applicationInfoService.navigationExpanded">
                    {{'Navigation.Label.CloseMenu' | texttransform }}
                </div>            
            </div>    
        </div>
        <app-navigationitem *ngIf="!isSettingNavigation" [menuItem]="settingsItem" 
            [navigationExpanded]=applicationInfoService.navigationExpanded (click)="setSettingNavigation(true)"></app-navigationitem>
        <app-navigationitem *ngIf="isSettingNavigation" [menuItem]="mainMenuItem" 
            [navigationExpanded]=applicationInfoService.navigationExpanded (click)="setSettingNavigation(false)"></app-navigationitem>
    </div>
</div>