
<span id="accountMainContainer"></span>
<div class="accountWrapper">  
  <div *ngIf="!applicationInfoService.isMobile()"
    [ngClass]="{
      scrollInfo__active: !checkIfScrolled()
      && showAccountInformation() == true
      && !applicationInfoService.isMobile()
      && applicationInfoService.currentAccount != null,
      scrollInfo__inactive: checkIfScrolled(),
      scrollInfo__stickyNav: applicationInfoService.navigationExpandedFixed,
      hideInDom: applicationInfoService.currentAccount == null && checkIfScrolled()
    }" class="scrollInfo">

  </div>

  <div class="appCardRow">
    <div class="appCardRowItem appCardRowItemContact" *ngIf="applicationInfoService.currentAccount">
      <app-card type="account" [data]="applicationInfoService.currentAccount"></app-card>
    </div>

    <div class="appCardRowItem appCardRowItemContact" *ngIf="applicationInfoService.currentContact">
      <app-card type="contact" [data]="applicationInfoService.currentContact"></app-card>
    </div>
  </div>

  <ng-container *ngIf="applicationInfoService.projectLoaded">    
    <div class="accountMainContainer" class="accountFormContainer">
      <div [innerHTML]="innerHTMLFooter"></div>
    </div>

  </ng-container>
</div>