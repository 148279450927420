import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-campaigndashboardlist',
  templateUrl: './campaigndashboardlist.component.html',
  styleUrl: './campaigndashboardlist.component.scss'
})
export class CampaigndashboardlistComponent implements OnInit, OnChanges{
  constructor(
    public commonService: CommonService, 
    public axivasTranslateService: AxivasTranslateService){}

  @Input() page: number | null = 0;
  @Input() mailCampaignOverview: any | null;
  @Input() microCampaignOverview: any | null;
  @Output() itemClick = new EventEmitter<string>();

  selectedMailCampaign = null;
  selectedMicroCampaign= null;
  mailCampaignDetails= [];

  mailCampaignSearchString = '';
  microCampaignSearchString = '';



  ngOnInit(): void {
    console.log('page', this.page);
    console.log('mail-data', this.mailCampaignOverview);
    console.log('micro-data', this.microCampaignOverview);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes-list', changes)
  }


  isFiltered(baseString, searchString) {
    return !this.commonService.checkIfStringContainsString(baseString, searchString);
  }

  sendMailDatatoParent(campaignOverviewItem){
    this.selectedMailCampaign = campaignOverviewItem;
    this.selectedMicroCampaign = null;
    this.itemClick.emit(campaignOverviewItem)
  }

  sendMicroCampaignToParent(campaignOverviewItem){
    this.selectedMailCampaign = null;
    this.selectedMicroCampaign = campaignOverviewItem;
    this.itemClick.emit(campaignOverviewItem);
  }

  getCampaignProgressPercentage(campaignOverviewItem) {
    const sumMax = campaignOverviewItem.countOpen + campaignOverviewItem.countSent + campaignOverviewItem.countCanceled + campaignOverviewItem.countBounced;
    let percentage = 0;
    if (sumMax != 0) {
      percentage = Math.floor(((sumMax - campaignOverviewItem.countOpen) * 100) / sumMax);
    }
    if (percentage > 100) { percentage = 100; }
    if (percentage < 0) { percentage = 0; }
    return percentage;
  }


  getCampaignIconId(campaignOverviewItem) {
    return 'campaignIcon' + campaignOverviewItem.id;
  }

}
