<button [id]="id" *ngIf="!type" class="everleadMainButton" [disabled]="disabled" (click)="buttonClick()"
    [class.disabled]="disabled">
    <app-jj-icons class="everleadMainButton__icon" [type]="svg ? svg : 'rocket'" [width]="20" [height]="20"
        [color]="'primaryLight'"></app-jj-icons>
    <span class="label" *ngIf="label">{{label | texttransform }}</span>
    <ng-content class="label"></ng-content>
</button>


<button [id]="id" *ngIf="type === 'small'" class="everleadMainButtonSmall" [disabled]="disabled" (click)="buttonClick()"
    [class.disabled]="disabled">
    <app-jj-icons class="everleadMainButtonSmall__icon" [type]="svg ? svg : 'rocket'" [width]="16" [height]="16"
        [color]="'primaryLight'"></app-jj-icons>
    <span class="label" *ngIf="label">{{ label | texttransform }}</span>
    <ng-content class="label"></ng-content>
</button>


<button [id]="id" *ngIf="type === 'border'" class="everleadMainButtonBorder" [disabled]="disabled" (click)="buttonClick()"
    [class.disabled]="disabled">
    <app-jj-icons class="everleadMainButtonBorder__icon" [type]="svg ? svg : 'rocket'" [width]="20" [height]="20"
        [color]="disabled ? 'primaryLight' : 'secondary'"></app-jj-icons>
    <span class="label" *ngIf="label">{{ label | texttransform}}</span>
    <ng-content class="label"></ng-content>
</button>

<button [id]="id" *ngIf="type === 'borderSmall'" class="everleadMainButtonBorderSmall" [disabled]="disabled"
    (click)="buttonClick()" [class.disabled]="disabled">
    <app-jj-icons class="everleadMainButtonBorderSmall__icon" [type]="svg ? svg : 'rocket'" [width]="16" [height]="16"
        [color]=" disabled ? 'primaryLight' : 'secondary'"></app-jj-icons>
    <span class="label" *ngIf="label">{{label | texttransform }}</span>
    <ng-content class="label"></ng-content>
</button>