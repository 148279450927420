
<div class="startpageDashboard">
  <div class="startPageDashboardContent">    
    <div class="startpageDashboard__userRow">
      <app-user-avatar [component]="'logo'" message="Hier erhälst du eine Übersicht über dein Projekt"></app-user-avatar>
    </div>
    <div class="startPageDashboardDashboards">
      <div class="startPageDashboardDashboardsItem">
        <app-dashbdashboardcomponent-myday></app-dashbdashboardcomponent-myday>
      </div>
      <div class="startPageDashboardDashboardsItem">
        <app-dashdashboardcomponent-updates></app-dashdashboardcomponent-updates>
      </div>
      <div class="startPageDashboardDashboardsItem">
        <app-dashbdashboardcomponent-mytopcontacts></app-dashbdashboardcomponent-mytopcontacts>
      </div>  
    </div>
  </div>
</div>
