<div *ngIf="isValid()" class="customTimelineItem">
    <div class="customTimelineItemLabel">
        <div class="dot"></div>
        {{ itemLabel | texttransform }}
        <app-jj-icons class="fingerCursor" *ngIf="isNotes" [type]="'copy'" (click)="copyToClipBoard()" [width]="20" [height]="20"></app-jj-icons>
    </div>
    <div class="customTimelineItemValue">
        <div *ngIf="!isJson && !isLink && !isNotes">{{ itemValue }}</div>
        <div *ngIf="isJson && !isLink && !isNotes">{{ itemValue | json }}</div>
        <div *ngIf="isLink && !isNotes">
            <a [href]="itemValue" target="_new">{{ itemValue }}</a>
        </div>
        <div *ngIf="isNotes">
            <div [innerHTML]="getCleanNotes()"></div>
        </div>
    </div>
</div>