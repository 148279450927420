import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProcessvisualService {
  stepArray = [];

  constructor() { 
    
  }
}
