<div *ngIf="userService.hasPermission('CanReadLookupLeadStatus')" class="agEverleadBorder">
    <div class="agComponentHeader__wrapper">        
        <app-component-header class="agComponentHeader__header" [header]="'Supervisor.Label.ManageLeadstates' | texttransform"></app-component-header>
        <div class="agComponentHeader__menu">

            <div class="agComponentHeader__item">
                <app-checkbox
                label="CampaignDesign.Label.ShowOnlyActive"
                [(ngModel)]="showOnlyActive"
                ></app-checkbox>      
            </div>

            <app-main-button
            class="agComponentHeader__item" 
            type="small"
            svg="magic-wand"
            (onClick)="createNewWizardLeadState()"
            label="Leadstate.Label.NewLeadState"
            [tippy]="'General.Label.CreateNewWithWizard' | texttransform"></app-main-button>
            <app-filter class="agComponentHeader__item component__filter" (keyUp)="filterString = $event"></app-filter>        
        </div>
    </div>  

<div class="agEverleadBorderInner">
<div class="nonExpertHeader" *ngIf="applicationInfoService.expertMode==true && !applicationInfoService.isMobile()">
    <div class="nonExpertHeader__active">{{ 'Leadstate.Label.IsActive' | texttransform }}</div>
    <label *ngIf="applicationInfoService.isDeveloper" class="leadState__id">ID</label>
    <div class="nonExpertHeader__name">{{ 'Leadstate.Label.DefaultName' | texttransform}}</div>
    <div class="nonExpertHeader__edit">{{ 'Leadstate.Label.Order' | texttransform}}</div>    
    <div class="nonExpertHeader__netto">{{ 'Leadstate.Label.IsNet' | texttransform }}</div>
    <div class="nonExpertHeader__edit">{{ 'General._.Edit' | texttransform}}</div>        
</div>

<span *ngIf="loadingData==true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
</span>

<span id="projectSettingsDesignSpan"></span>

<div class="projectSettings__wrapper" [style.height]="getTableHeight()" *ngIf="applicationInfoService.expertMode==false && loadingData == false">    
    <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
        <ng-container matColumnDef="defaultName" [sticky]="!applicationInfoService.isMobile()">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.DefaultName' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">  
                <div class="projectSettings__name">
                    <label *ngIf="applicationInfoService.isDeveloper" class="everleadCoral leadState__id">{{ element.lookupTable.id }} </label>
                    <app-text-input 
                        [(ngModel)]="element.lookupTable.defaultName"
                        (valueChange)="saveLeadState(element)"
                        class="projectSettings__inputMin maxWidth"
                    ></app-text-input>     
                    <app-jj-icons type="editor" [width]="20" [height]="20" [tippy]="'General.Label.ShowWizard' | texttransform" (click)="showWizard(element)" [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                    <app-wizard-translations [svgW]="20" [svgH]="20" [array]="element.lookupTable" updateBaseArrayExternaldatasourceId=215 mode="3"></app-wizard-translations>    
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.Order' | texttransform }}</th>
            <td mat-cell *matCellDef="let element"> 
                <app-number-input [(ngModel)]="element.lookupTable.order" (valueChange)="saveLeadState(element)"></app-number-input>           
<!--                 <input [(ngModel)]="element.lookupTable.order" type="number" (change)="saveLeadState(element)" class="projectSettings__inputFull orderInput">    -->                 
            </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsActive' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">
                <app-checkbox [(ngModel)]="element.lookupTable.isActive" (valueChange)="saveLeadState(element)" class="projectSettings__inputFull"></app-checkbox>            
<!--                 <input [(ngModel)]="element.lookupTable.isActive" type="checkbox" (change)="saveLeadState(element)" class="projectSettings__inputFull">        -->             
            </td>
        </ng-container>

    
        <ng-container matColumnDef="isNetto">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsNet' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">
                <app-checkbox [(ngModel)]="element.isNet" (valueChange)="saveLeadState(element)" class="projectSettings__inputFull"></app-checkbox>

<!--                 <input [(ngModel)]="element.isNet" type="checkbox" (change)="saveLeadState(element)" class="projectSettings__inputFull">      -->               
            </td>
        </ng-container>
        
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.Type' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">
             <app-select-input
                class="projectSettings__inputFull maxWidth"
                [(ngModel)]="element.statusLookupId"
                bind="id"
                display="defaultName"
                [options]="leadStateTypes"
                (valueChange)="saveLeadState(element)"></app-select-input>  
            </td>
        </ng-container>

        <ng-container matColumnDef="isLead">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsLead' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">
                <app-checkbox [(ngModel)]="element.isLead" (valueChange)="saveLeadState(element)" class="projectSettings__inputFull"></app-checkbox>                
            </td>
        </ng-container>

        <ng-container matColumnDef="isTableCreation">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsStatistic' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">
                <app-checkbox [(ngModel)]="element.isStatistic" (valueChange)="saveLeadState(element)"></app-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="isLeadQuote">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsLeadquote' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">
                <app-checkbox [(ngModel)]="element.isLeadquote" (valueChange)="saveLeadState(element)" class="projectSettings__inputFull"></app-checkbox>            
            </td>
        </ng-container>

        <ng-container matColumnDef="isAutomatic">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.IsRequiredLeadreport' | texttransform }}</th>
            <td mat-cell *matCellDef="let element">
                <app-checkbox [(ngModel)]="element.isRequiredLeadreport" (valueChange)="saveLeadState(element)"class="projectSettings__inputFull"></app-checkbox>            
            </td>
        </ng-container>

        <ng-container matColumnDef="isAutomaticStart">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.RequiredLeadreportStart' | texttransform }}</th>
            <td mat-cell *matCellDef="let element"> 
                <app-date-input 
                [(ngModel)]="element.requiredLeadreportStart" 
                (dateChange)="saveLeadState(element)" 
                class="projectSettings__inputFull maxWidth"></app-date-input>
            </td>
        </ng-container>

        <ng-container matColumnDef="validXDays">
            <th mat-header-cell *matHeaderCellDef>{{ 'Leadstate.Label.ValidXDays' | texttransform }}</th>
            <td mat-cell *matCellDef="let element"> 
                <app-number-input
                class="projectSettings__inputFull maxWidth"
                [(ngModel)]="element.validXDays"
                (valueChange)="saveLeadState(element)"
                ></app-number-input> 
                <!-- <input [(ngModel)]=element.validXDays class="projectSettings__inputFull maxWidth" type="number" (change)="saveLeadState(element)">  -->
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{             
            hideInDom: (row.lookupTable.isActive == false && showOnlyActive)  || itemIsFiltered(row) == true,
            projectSettings__isInactive: row.isActive == false
        }"></tr>
    </table>
</div>

 <div class="nonExpertView__wrapper" [style.height]="getTableHeight()" *ngIf="applicationInfoService.expertMode==true" >
    <div *ngFor="let leadstate of leadstates" class="nonExpertView__main everleadTableRow" [ngClass]="{             
        hideInDom: (leadstate.isActive == false && showOnlyActive)  || itemIsFiltered(leadstate) == true,
        projectSettings__isInactive: leadstate.isActive == false
    }">
        <div class="nonExpertHeader__active" *ngIf="!applicationInfoService.isMobile()">
            <app-checkbox [(ngModel)]="leadstate.lookupTable.isActive" (valueChange)="saveLeadState(leadstate)" [tippy]="'CampaignDesign.Label.IsActive' | texttransform" [tippyOptions]="{placement:'bottom'}"></app-checkbox>
           <!--  <input [(ngModel)]="leadstate.lookupTable.isActive" type="checkbox" (change)="saveLeadState(leadstate)" [tippy]="'CampaignDesign.Label.IsActive' | texttransform" [tippyOptions]="{placement:'bottom'}"> -->
        </div>
        <label *ngIf="applicationInfoService.isDeveloper" class="everleadCoral leadState__id">{{ leadstate.lookupTable.id }} </label>
        <div class="nonExpertView__name">            
            <!-- <input [(ngModel)]="leadstate.lookupTable.defaultName" (change)="saveLeadState(leadstate)"> -->            
            <app-text-input [(ngModel)]="leadstate.lookupTable.defaultName" (valueChange)="saveLeadState(leadstate)"></app-text-input>
            <app-wizard-translations [svgW]="20" [svgH]="20" [array]="leadstate.lookupTable" updateBaseArrayExternaldatasourceId=215 mode="3"></app-wizard-translations>
            <app-jj-icons *ngIf="applicationInfoService.isMobile()" type="editor" (click)="showWizard(leadstate)" [width]="20" [height]="20"></app-jj-icons>    
            <!-- <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button" *ngIf="applicationInfoService.isMobile()" (click)="showWizard(leadstate)"></i> -->
        </div>
        <div class="nonExpertView__bonus" *ngIf="!applicationInfoService.isMobile()">
            <app-number-input [(ngModel)]="leadstate.lookupTable.order" (valueChange)="saveLeadState(leadstate)" [tippy]="'Leadstate.Label.Order' | texttransform" [tippyOptions]="{placement:'bottom'}"></app-number-input>
<!--             <input [(ngModel)]="leadstate.lookupTable.order" (change)="saveLeadState(leadstate)" type="number" style="width: 50px" [tippy]="'Leadstate.Label.Order' | texttransform" [tippyOptions]="{placement:'bottom'}"> -->
        </div>
        <div class="nonExpertView__bonus" *ngIf="!applicationInfoService.isMobile()">
            <app-checkbox [(ngModel)]="leadstate.isNet" (valueChange)="saveLeadState(leadstate)" [tippy]="'CampaignDesign.Label.IsNet' | texttransform" [tippyOptions]="{placement:'bottom'}"></app-checkbox>
          <!--   <input [(ngModel)]="leadstate.isNet" type="checkbox" (change)="saveLeadState(leadstate); handle($event)" [tippy]="'CampaignDesign.Label.IsNet' | texttransform" [tippyOptions]="{placement:'bottom'}"> -->
        </div>
        <div class="nonExpertView__bonus" (click)="showWizard(leadstate)" *ngIf="!applicationInfoService.isMobile()">
            <app-jj-icons type="editor" [tippy]="'General._.Edit' | texttransform" [tippyOptions]="{ placement:'bottom'}" [width]="20" [height]="20"></app-jj-icons>
            
<!--             <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button" [tippy]="'General._.Edit' | texttransform" [tippyOptions]="{ theme:'everleadRed', placement:'bottom'}" ></i> -->
        </div>
    </div>
</div>

</div>
</div>