<div class="everleadFilter"[class.disabled]="disabled">
    <div class="everleadFilter__wrapper">
        <input 
        (change)="handleChange($event.target.value)"
        [placeholder]="placeholder | texttransform"
        (keyup)="handleKeyUp($event.target.value)"
        [disabled]="disabled">
        <app-jj-icons type="magnifying-glass"  [width]="20" [height]="20" [color]="disabled ? 'gray0' : 'secondary'"></app-jj-icons>
    </div>
</div>
