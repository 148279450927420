import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-defaultdashboard-leadstatedevelopment',
  templateUrl: './defaultdashboard-leadstatedevelopment.component.html',
  styleUrl: './defaultdashboard-leadstatedevelopment.component.scss'
})

export class DefaultdashboardLeadstatedevelopmentComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription()
  dashboardResultData = [];
  dashboardId = 18;

  pools = [];
  poolId = 840;
  campaigns = [];
  campaignId = 783;
  userId = null;
  users = [];
  languageId = 2;
  loadingData = true;

  tableRows = null;
  tableCols = null;

  startDate = new Date(2024, 8, 1, 0, 0, 0 , 0);
  endDate = new Date(Date.now());
  
  developmentDetails = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private dashboardcoreService: DashboardcoreService,
    private commonService: CommonService,
    private methodService: MethodService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getCampaigns();
        this.getUsers();
      }
    });

    this.getDashboardDataArray(this.dashboardId);
    this.getCampaigns();
    this.getPools();
    this.getUsers();
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getUsers() {
    this.users = this.applicationInfoService.projectUsers.toArray();
  }

  getPools() {
    this.pools = this.applicationInfoService.pools.toArray().filter(pool => pool.isActive == true);
  }

  getCampaigns() {
    this.campaigns = this.applicationInfoService.campaigns.toArray().filter(campaigns => campaigns.isActive == true);
  }

  getDashboardDataArray(dashboardId) {
    let params = this.fillParams();
    this.loadingData = true;
    this.dashboardResultData[dashboardId] = null;
    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {      
      this.loadingData = false;
      this.dashboardResultData[dashboardId] = null;
      if (getDashboardDataArrayResult) {
        if (getDashboardDataArrayResult[0]) {
          this.dashboardResultData[dashboardId] = JSON.parse(JSON.stringify(getDashboardDataArrayResult[0]));      
          this.tableRows = [...new Set(this.dashboardResultData[dashboardId].map(item => item.display_data.lead_to))];
          this.tableCols = [...new Set(this.dashboardResultData[dashboardId].map(item => item.display_data.lead_to))];
        }
      }
    }).catch((error)=>{
      this.loadingData = false;
    });
  }

  fillParams() {
    const params = [];
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@pool_id', value: this.poolId });
    params.push({ key: '@campaign_id', value: this.campaignId });
    params.push({ key: '@language_id', value: this.languageId });
    params.push({ key: '@user_id', value: this.userId });
    params.push({ key: '@start_date', value:this.commonService.getDateString(this.startDate) });
    params.push({ key: '@end_date', value: this.commonService.getDateString(this.endDate) });
    // params.push({ key: '@start_date', value: '2024-08-04' });
    // params.push({ key: '@end_date', value: '2024-09-17' });
    return params;
  }

  getAmount (tableRow, tableCol) {
    let returnValue = 0;
    const valueItem = this.dashboardResultData[this.dashboardId].find(arrayItem => 
      arrayItem.display_data.lead_from == tableRow &&
      arrayItem.display_data.lead_to == tableCol 
    );
    if (valueItem) {
      returnValue = valueItem.display_data.transition_count;
    }
    return returnValue;
  }

  showDevelopmentDetails(tableRow, tableCol) {
    if (this.getAmount(tableRow, tableCol) == 0) {
      return;
    }
    const valueItem = this.dashboardResultData[this.dashboardId].find(arrayItem => 
      arrayItem.display_data.lead_from == tableRow &&
      arrayItem.display_data.lead_to == tableCol 
    );
    if (valueItem) {
      this.developmentDetails = JSON.parse(JSON.stringify(valueItem.display_data));
      this.developmentDetails.data = JSON.parse(this.developmentDetails.data);
    }
  }

  getDevelopmentDetailsHeader() {
    return this.developmentDetails.lead_from.concat(' -> ', this.developmentDetails.lead_to);
  }

  checkSameData(tableRow, tableCol) {    
    if (tableCol == tableRow) {
      return true;
    } else {
      return false;
    }
  }

  loadContact(dataItem) {
    if (dataItem.account_id) {
      this.methodService.launchInternalMethod(InternalMethodType.ShowAccountDataPopup, [dataItem.account_id]);
      if (dataItem.contact_id) {
        this.methodService.launchInternalMethod(InternalMethodType.LoadContact, [dataItem.contact_id]);
      };
    }   
  }
}
