import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ContactProtectionService } from 'app/jollyjupiter/service/contact-protection.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService, ExternalDataSource_QueryType } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-generalsettings',
  templateUrl: './generalsettings.component.html',
  styleUrls: ['./generalsettings.component.scss']
})

export class GeneralsettingsComponent implements OnInit {
  maskTypes = null;
  project = null;
  customers = 0;
  mailConfigTypes = [];
  mailConfigSesRefIds = [];
  mailConfigSmtpRefIds = [];
  aiKey = {
    id: null,
    value: '',
    comment: '',
    setting: 'openAIKey',
    projectId: null,
    lookupSettingType: null
  }
  reportTypeLookups = [];
  ringCentralConnectionId = '';
  ringCentralConnectionSecret = '';
  projectTargets = [];
  contactIntervalDefinition = [];
  contactMediumDefinition= [];
  accountContactprotection = [];
  projectId=  null;
  section = 1;
  innerHTMLContent = ` <jj-container controluiid="257186"> </jj-container>`;
  innerHTML = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private eventService: EventService,
    private contactProtectionService : ContactProtectionService,
    private domSanitizer: DomSanitizer,
    private sharedAPI: SharedAPI
  ) { }



  handle(event){
    console.log('handle event', event)
    //console.log('typeof variable', typeof event)
    const variable = this.project.projectStart
    //console.log('variable', variable);
    //console.log('typeof variable', typeof variable)
  }

  handleDate(event){
    //console.log('event', event);

  }

  ngOnInit(): void {
    this.aiKey.projectId = this.applicationInfoService.projectID;
    const openAIKey = this.applicationInfoService.applicationSettings.find(setting => setting.setting == 'openAIKey');
    if (openAIKey) {
      this.aiKey = openAIKey;
    }

    this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getReportTypeLookups();
      }      
      if (event.id == 'removeProjectTarget') {
        this.commonService.removeItemFromArray(this.projectTargets, event.value);
      }
    });
    this.getProjectInfo();
    this.getMaskedTypes();
    this.getCustomers();
    this.getMailConfigTypes();
    this.getSMTPRefIds();
    this.getSesRefIds();
    this.getApplicationSettings();
    this.getReportTypeLookups();
    this.getProjectTargets();
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
  }

  getReportTypeLookups() {    
    this.reportTypeLookups = this.commonService.getProjectLookupTableValues(84, 'central');
    console.log('this.reportTypeLookups', this.reportTypeLookups);
  }

  getProjectTargets() {
    this.externaldatasourceService.executeExternalDataSource(805, [])
    .then(getProjectTargetsResult => { 
      this.projectTargets = getProjectTargetsResult;
    });  
  }

  getApplicationSettings() {
    this.externaldatasourceService.executeExternalDataSource(659, [])
    .then(getEMailSettingsResult => { 
      
      this.mailConfigSmtpRefIds = getEMailSettingsResult;
      console.log('getEmailSettingsResult',  this.mailConfigSmtpRefIds)
    });    
  }

  getSesRefIds() {
    this.externaldatasourceService.executeExternalDataSource(135)
    .then(getCampaignsResult => {
      getCampaignsResult.forEach(campaign => {
        if (campaign.isActive == true && campaign.sesConfigSetId != null) {
          this.mailConfigSesRefIds.push(campaign);
          //console.log('mailConfigSesRefIds', this.mailConfigSesRefIds)
        }        
      });
    });
  }

  saveApplicationSetting(setting) {
    if (setting.id == null) {
      this.externaldatasourceService.executeExternalDataSource(674, [this.commonService.getModifyArrayBody(setting, ['id'])])
      .then(saveApplicationSettingResult => {
        setting.id = saveApplicationSettingResult.id;
      });
    } else {
      this.externaldatasourceService.executeExternalDataSource(673, [this.commonService.getModifyArrayBody(setting, [])]);
    }
  }

  getSMTPRefIds() {
    this.externaldatasourceService.executeExternalDataSource(659, [])
    .then(getEMailSettingsResult => { 
      this.mailConfigSmtpRefIds = getEMailSettingsResult;
      //console.log('this.mailConfigSmtpRefId', this.mailConfigSmtpRefIds)
    });
  }

  getMailConfigTypes() {
    this.externaldatasourceService.getLookupTableValues(78, 2).then(getMailConfigTypesResult => {
      this.mailConfigTypes = getMailConfigTypesResult;
      //console.log('mailConfigTypes', this.mailConfigTypes)
   });
  }

  getProjectInfo() {
   this.externaldatasourceService.executeExternalDataSource(767, [this.applicationInfoService.currentProject.id]).then(project => {
      this.project = project;
   });
  }

  getMaskedTypes() {
    this.externaldatasourceService.executeExternalDataSource(154, [])
    .then(getMaskedTypesResult => {
      this.maskTypes = getMaskedTypesResult;
    }); 
  }

  saveSettings(){
    if (this.project.agentMaskingType == 297) {
      if (!this.commonService.validatePhoneForE164(this.project.agentMaskingSpecialNumber)) {
        this.messagingService.showDefaultWarning('E164', 'GeneralSettings.Error.PhoneNumberNotValid');
        return;
      }
    }
       
    if (!this.commonService.isNullOrUndefined(this.project.countFteOperational)) {
      this.project.numCountFteOperational = Number(this.project.countFteOperational);
    }    
    if (!this.commonService.isNullOrUndefined(this.project.countProjectHours)) {
      this.project.numCountProjectHours = Number(this.project.countProjectHours);
    }    
    let dataSourceParam = this.commonService.getModifyArrayBody(this.project, ['countFteOperational', 'countProjectHours']);
    // this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(260, [dataSourceParam])
    .then(executeExternalDataSourceResult => {
      this.applicationInfoService.currentProject.dmcAccountId = this.project.dmcAccountId;
      // this.loaderService.display(false);
    })
    .catch(() => {
      // this.loaderService.display(false);
    });
  }

  getCustomers() {
    this.externaldatasourceService.executeExternalDataSource(8, [])
    .then(getCustomersResult => {
      this.customers = getCustomersResult;
    }); 
  }

  changeMaskingType() {
    if (this.project.maskingType != 297) {
      this.project.agentMaskingSpecialNumber = null;
    }
    this.saveSettings();
  }

  clearProjectEnd() {
    this.project.projectEnd = null;
    this.saveSettings();
  }

  clearData(dateValue) {
    this.project[dateValue] = null;
    this.saveSettings();
  }

  saveRingCentralSettings() {
    
  }

  createProjectTarget() {
    const target = {
      targetStart: new Date(Date.now()),
      targetEnd:null, 
      countProjectHours: null,
      countFteOperational: null,
      countOfNetcallsPerHour: null,
      projectId: this.applicationInfoService.projectID,
      projectName: this.applicationInfoService.projectInfo.projectName,
      comments: null
    };
    this.externaldatasourceService.executeExternalDataSource(802, [
      this.commonService.getModifyArrayBody(target, [])
    ]).then(createProjectTargetResult => {
      target['id'] = createProjectTargetResult.id;
      this.projectTargets.push(target);
    });    
  }

  changeValue(section, value) {
    this.project[section] = value;
    this.saveSettings();
  }

  activateProject() {
    this.sharedAPI.activateProject(this.applicationInfoService.projectID).subscribe();
  }
}

