import { Injectable } from '@angular/core';
import { Dictionary } from '../dictionary';
import { environment } from 'environments/environment';

export enum VariableType {
  String = 1,
  Number = 2,
  Boolean = 3,
  Date = 4,
}

export enum ProjectMode {
  Default = 1,
  SoftPhoneOnly = 2,
}

@Injectable({
  providedIn: 'root'
})


export class ApplicationInfoService {
  public versionNumber = '17.2024.10.11.001st';
  public applicationId = 6;
  public testEnvironment = false;

  // new design
  public useNewDesign = true;
  public navigationExpanded = false;
  public navigationExpandedFixed = false;
  public navigationExpandedMobile = false;
  public breadCrumbTextSection = '';
  public breadCrumbText = '';
  public showBanner = false;
  public showVideo = false;
  public navigationPinned = false;
  public paletteCollapsed = true;
  public showJJPopup = true;
  public projectSettingPage = null;
  public getCompleteProjectInformatonLoaded = false;

  // Customer & project
  public customerID = 0;
  public projectID = 0;
  public hasCampaignsWithOwnTimeRecordingProject = false;
  public timeRecordingProjectId = 0;
  public projectName = 'EVERLEAD';
  public projectSelected = false;
  public projectLoaded = false;
  public projectInfo = null;
  public poolId = 0;
  public selectedCategory = '';
  public taskEntityId = '2';
  public accountEntityId = '1';
  public contactEntityId = '4';
  public miscSettingsEntityId = 230;
  public cssInitialized = false;
  public projectMode = 1;
  public noAuthKilling = false;
  public loadingProjectPercentage = 0;

  // Application settings
  public applicationSettings = [];
  public splashScreenLoadingText = 'Application is loading...'
  public noProject = false;
  public blockSettingChange = false;
  public userSettingsPoolSelected = false;
  public userSettingsCampaignSelected = false;
  public expertMode = false;
  public tipps = [];
  public newVersionAvailable = false;
  public showApplicationFooter = true;
  public maxScrollWidth = 1400;

  // MyLeads
  public myLeadsDefinitions = null;
  public matTableCustomFiltersUsed = false;

  // Dashboards
  public currentDashboardId = null;
  public lastBoldBiSync = null;
  public userDashboardMode = 1;
  public customDashboards = [];

  // QuickSearch
  public quickSearchAllPools = false;
  public quickSearchValue = '';

  // User information
  public userID = 0;
  public userName = '';
  public user: any = null;
  public userRoleID = 0;
  public isDebugMode = false;
  public isDeveloper = false;
  public currentUserGroup = 0;
  public availableUserGroups = [];
  public availableRoles = [];
  public currentUserGroupsCampaignId = 0;
  public logLevel = 2;
  public isDirty = false;
  public isValid = true;
  public editMode = '';
  public dateFormat = 'dd.MM.yyyy';
  public ignoreFakeUserId = false;
  public currentUserRoleId = null;
  public campaingnModeId = null;
  public awsSilentRenewError = false;
  public tableLayoutDefinitions = [];
  public securityRoles = [];
  public userMessages = [];
  public userAvatar = 'none';
  public userSettings = {};
  public isAccountExpanded = true;
  public selectedCampaignOverview = null;
  public clientIP = '';

  // Misc
  public isFollowUpWorkflowId = null;
  public isFollowUpWorkflowStepId = null;
  public miscSettings = [];
  public errorOccured = false;
  public noProjectsForUser = false;
  public postGrahphileRestart = false;
  public languageId = 2;
  public lastLogicalItemFromMyFollowups = null;
  public projectSpecificDefinitionId = null;
  public subResultChanges = [];
  public selectedProcessVisualWorkflow = 0;
  public projectQuestionaires = null;
  public projectBattleCards = null;
  // ! ivo: templates for wizard
  public isTemplateRequired = null;
  public selectedTemplateWorkflow =null;
  public availableReportsToDownload = 0;

  // Editor
  public designerMode = false;
  public containerDesignerMode = false;
  public tempId = 0;
  public controlDesignerHoverId = null;

  // Styling, Mobile
  public userInteractionPanelHeight = 30;
  public desktopBreakPoint = 1024;
  public innerWidth = 0;
  public innerHeight = 0;
  public mainViewMaximixed = false;

  // Webphone & Activity
  public phone3cx = 2307;
  public phoneRingCentral = 2309;
  public phoneRingCentralWebRTC = 6753;
  public phoneConnection = 2309;
  public isTimeRecordingUser = false;
  public isWebPhoneUser = false;
  public showReconnectOption = false;
  public phoneUserSetting = null;
  public incomingCall = null;

  // public timeRecordingProjectID = 243;
  public currentTimeRecordingStatus: any = null;
  public webPhoneConnected = false;
  public timeRecordingConnected = false;
  public availableUserActivities: any = null;
  public availableFavoritActivities: any = null;
  public isTimeRecordingProjectChange = null;

  // Header & Menu
  public useNewHeader: any = null;
  public selectedMenuItem: any = null;
  public highlightedMenuItem: any = null;
  public menuInitId: string = null;
  menuItems: any[];
  routerHeaderLabelText = '';
  routedSection = 'task';

  // MyLeads
  myLeadsSelectedFilter = null;

  // Sticky uis
  taskDetailsContainerId = 1500;
  taskDetailsOverviewId = 6217;
  taskDetailsBookingViewId = 6143;

  // TranslationPopup
  translationPopupToken = null;
  translationPopupCallingItem = null;
  translationPopupTarget = null;
  translationPopupCallingSource = null;

  // DataFilter
  dataFilterFilteredItemArray = [];
  loadFilterIdAtPopup = null;
  dataFilterPreviewControl = null;
  dataFilterPreviewControQuery = null;
  baseLogicalEntityIdForFilter = null;
  dataFilterLookupFilterTypeId = null;
  // filterTypeId = '11';
  dataFilterBaseEntity = null;
  dataFilterCaller = '';
  dataFilterFilterTemplate = null;
  matTableTempFilter = null;

  matTableUserGlobalFilters = [];
  matTableUserCustomFilters = [];
  matTableUserColumnFilters = [];
  matTableUserPageSizeSettings = [];
  matTableUserCurrentPages = [];
  matTableSortStrings = [];

  // AdditionalMenuBar
  additionalMenuBarControlId = 0;

  // AccountPopup Array
  accountPopupQMMode = false;
  accountPopupDataId = null;
  accountPopupDataArray = [];
  accountPopupDataType = 'account';

  // Controls
  controlInteractionIconWidth = '25px';
  disabledEntityMemberIdentifier = 'uiDisabledEntityMember';
  lastClickedMatTable = null;

  // Dataarrays
  projectLookupTables = [];
  preloadedControlInfo = [];

  // AI
  myBestContactsOnlyAI = false;

  // Dicts
  // public dataSources: Dictionary<any> = new Dictionary<any>();
  public schemes: Dictionary<Dictionary<string>> = new Dictionary<Dictionary<string>>();
  public entities: Dictionary<any> = new Dictionary<any>();
  public entityMember: Dictionary<any> = new Dictionary<any>();
  public logicalEntities: Dictionary<any> = new Dictionary<any>();
  public subResults: Dictionary<any> = new Dictionary<any>();
  public entitiesorig: Dictionary<any> = new Dictionary<any>();
  public controls: Dictionary<any> = new Dictionary<any>();
  public controlsBackUp: Dictionary<any> = new Dictionary<any>();
  public methods: Dictionary<any> = new Dictionary<any>();
  public controlValues: Dictionary<any> = new Dictionary<any>();
  public containerData: Dictionary<any> = new Dictionary<any>();
  public availableActivities: Dictionary<any> = new Dictionary<any>();
  public availableFavoritActivitiesDic: Dictionary<any> = new Dictionary<any>();
  public projectUsers: Dictionary<any> = new Dictionary<any>();
  public projectUsersAll: any[] = [];
  public questionaires: Dictionary<any> = new Dictionary<any>();
  public endpoints: Dictionary<any> = new Dictionary<any>();
  public entitiesDynamicFields: Dictionary<any> = new Dictionary<any>();
  public workflows: Dictionary<any> = new Dictionary<any>();
  public steps: Dictionary<any> = new Dictionary<any>();
  public campaigns: Dictionary<any> = new Dictionary<any>();
  public stepworkflow: Dictionary<any> = new Dictionary<any>();
  public results: Dictionary<any> = new Dictionary<any>();
  public leadstates: Dictionary<any> = new Dictionary<any>();
  public workflowSteps: Dictionary<any> = new Dictionary<any>();
  public pools: Dictionary<any> = new Dictionary<any>();
  public externalDataSourceCache: Dictionary<any> = new Dictionary<any>();
  public externalDataSources: Dictionary<any> = new Dictionary<any>();
  public dataLookupTableDefinitions: Dictionary<any> = new Dictionary<any>();

  // Init
  externalDataSourceInitialized = false;
  applicationUserInitialized = false;
  languageSettingsInitialized = false;
  applicationInitialized = false;
  showLoadProjectSpinner = true;

  // Routing & Redirecting
  mainFrameName = 'content';
  redirectTo = '';
  redirectTaskId = null;

  // Account
  public currentProject: any = null;
  public currentCustomer: any = null;
  public currentTask: any = null;
  public currentAccount: any = null;
  public currentContact: any = null;
  lockUser = '';
  accountLocked = false;
  accountLockInformation: any;
  workflowHasNotRelevantResult: false;

  // OptIn OptOut
  optInOptOutWorkflow = null;
  optInOptOutBookingStayInAccount = null;

  // AWS
  showAWsLoginPage = false;
  awsUserName = '';
  awsPassword = '';
  userLoginByEmail = null;

  // Demo
  demoLandingPage = null;
  demoLandingPageVisited = true;
  demoLandingPageNotVisitedInfo = false;

  // API
  apiBackendUrl = null;

  // Navigation
  selectedNavigationItem = null;
  projectShortCuts = [];

  // files
  fileTypes=['xlsx','pptx','docx','doc','pdf','txt', 'csv'];

  constructor() { }

  changeUserGroup(): boolean {
    let timeRecordingProjectId = 0;

    if (this.availableUserGroups !== null && this.availableUserGroups !== undefined) {
      const currentUserGroup = this.availableUserGroups.filter(x => x.id === this.currentUserGroup)[0];
      if (currentUserGroup != undefined) {
        this.currentUserGroupsCampaignId = this.availableUserGroups.filter(x => x.id === this.currentUserGroup)[0].campaignId;

        if (this.campaigns !== null && !this.campaigns !== undefined) {
          if (this.hasCampaignsWithOwnTimeRecordingProject) {
            timeRecordingProjectId = this.campaigns.items[this.currentUserGroupsCampaignId].timeRecordingProjectId;
          }

          if (timeRecordingProjectId !== 0 && this.timeRecordingProjectId !== timeRecordingProjectId) {
            this.timeRecordingProjectId = timeRecordingProjectId;
            return true;
          }
        }
      }
    }

    return false;
  }

  getTimeRecordingProjectId (): number {
    if (this.hasCampaignsWithOwnTimeRecordingProject) {
      if (this.timeRecordingProjectId !== undefined && this.timeRecordingProjectId > 0) {
        return this.timeRecordingProjectId;
      } else {
        this.changeUserGroup();
        if (this.timeRecordingProjectId !== undefined && this.timeRecordingProjectId > 0) {
          return this.timeRecordingProjectId;
        }
      }
    }
    return this.projectInfo.timeRecordingProjectId;
  }


  isFileIncluded(fileType: string) {
    console.log('infoService', fileType);
    console.log('includes', this.fileTypes.includes(fileType));
    return this.fileTypes.includes(fileType);
  }

  stringMaxLength(string, maxValue): boolean {
    return string.length >= maxValue  ? string : undefined
  }

  isMobile(): boolean {
    // if (this.innerHeight < 800) {
    //   this.mainViewMaximixed = true;
    // } else {
    //   this.mainViewMaximixed = false;
    // }

    if (this.innerWidth <= this.desktopBreakPoint) {
      return true;
    } else {
      return false;
    }
  }

  resetAdditionalMenuBar() {
    this.additionalMenuBarControlId = 0;
  }

  getSelectedCategory() {
    if (this.selectedCategory !== null && this.selectedCategory !== undefined && this.selectedCategory !== '') {
      return ', category: "'.concat(this.selectedCategory, '"');
    } else {
      return '';
    }
  }

  changeDesignerMode() {

    console.log('this is Developer', this.isDeveloper)
    if (this.isDeveloper === true) {
      this.designerMode = !this.designerMode;
      this.isTimeRecordingUser = !this.isTimeRecordingUser;
      this.isDebugMode = !this.isDebugMode;
      this.isWebPhoneUser = !this.isWebPhoneUser;
      this.containerDesignerMode = !this.containerDesignerMode;
    } else {
      this.containerDesignerMode = !this.containerDesignerMode;
    }
  }

  getEntities() {
    const entities = [];
    this.entities.GetItemArray().forEach(item => {
      entities.push(this.entities.Item(item));
    });
    return entities;
  }

  getUiEntityInstanceIds() {
    const entities = [];
    this.entities.GetItemArray().forEach(item => {
      entities.push(this.entities.Item(item));
    });
    return entities;
  }

  checkCurrentUIVersion() {
    const applicationSettingVersion = this.applicationSettings['applicationVersion'.concat('_', environment.versionCheck)];
    if (applicationSettingVersion != null && applicationSettingVersion != undefined) {
      if (this.versionNumber != applicationSettingVersion) {
        this.newVersionAvailable = true;
      } else {
        this.newVersionAvailable = false;
      }
    }
  }
}
