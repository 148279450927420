
    <div [ngClass]=" type === 'row' ? 'everleadRadio__Row' : 'everleadRadio__Col'">
            <label *ngFor="let option of options" class="everleadRadio__label" [class.disabled]="disabled" [class.required]="required">
                <input type="radio" 
                [value]="option.value"
                [(ngModel)]="value"
                (change)="updateValue(option.value)"
                [disabled]="disabled" [attr.required]="required ? '' : null">
                <app-jj-icons [class.disabled]="disabled" *ngIf="value !== option.value" [type]="'circle'" [width]="20" [height]="20"></app-jj-icons>
                <app-jj-icons [class.disabled]="disabled" *ngIf="value === option.value" [type]="'radio-button-fill'" [width]="20" [height]="20"></app-jj-icons>
                <span>{{ option.label | texttransform }}</span>
            </label>

    </div>
 
