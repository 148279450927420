import { Component } from '@angular/core';

@Component({
  selector: 'app-defaultdashboard-questionaire',
  templateUrl: './defaultdashboard-questionaire.component.html',
  styleUrl: './defaultdashboard-questionaire.component.scss'
})
export class DefaultdashboardQuestionaireComponent {

}
