import { Component , Injectable, Input} from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {

  constructor(
    public applicationInfoService: ApplicationInfoService,
  ){}

  @Input() header: string = "";
  @Input() ellipsisHeader = false
  @Input() locked: boolean | null = false;

  tippyLockProps={
    placement:'bottom',
    trigger: 'mouseenter',
    theme:'light-border',
    touch:true,
    interactive:true,
    appendTo:'parent',
  }


}
