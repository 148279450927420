import { AfterContentInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
})
export class FilterComponent implements AfterContentInit{

  @Input() placeholder: string = 'General.Label.Search';
  @Input() disabled: boolean = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() keyUp = new EventEmitter<KeyboardEvent>();

  value: any;



  ngAfterContentInit(): void{
  }




  handleChange(value: any): void{
    if(!this.disabled){
      this.value = value;
      this.valueChange.emit(this.value)
    }
  }

  handleKeyUp(event: KeyboardEvent) {
    if(!this.disabled){
      this.keyUp.emit(event);
    }
  }


}


