import { AfterContentInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stacked-bar',
  templateUrl: './stacked-bar.component.html',
  styleUrl: './stacked-bar.component.scss'
})
export class StackedBarComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit{

  constructor(
    private axivasTranslateService: AxivasTranslateService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private commonService: CommonService,
  ) {}


  customEventSubscription: Subscription = new Subscription();
  @Input() controlid: string = "";
  @Input() options: EChartsOption;
  @Input() chartData: any = [];
  @Input() height: number = 200;
  @Input() colors = ['#5465FF', '#FF5050','#3FE163'];



  ngOnInit(): void {

    this.customEventSubscription = this.eventService.customEvent.subscribe((event) =>{
      if(event.id === 'defaultdashboard_overviewData'){  
        this.chartData = event.data
        this.createReportOverviewDataChart()
      }
    } )


    this.createReportOverviewDataChart()

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData'] && !changes['chartData'].firstChange) {
      console.log('chartData ->changes', changes)
      //console.log('here')
      if(this.controlid === 'defaultdashboard'){
        this.createReportOverviewDataChart()
      }
      }
    }  
  
  


  ngAfterContentInit(): void {
    //console.log('Stacked-bar Method not implemented.');
  }



  createReportOverviewDataChart(){
    this.options={
      tooltip:{
        trigger:'axis',
        axisPointer:{
          type:'shadow'
        },
      },
      grid:{
/*         left:'3%',
        top:'4%',
        right:'4%',
        bottom:'3%', */
        containLabel:true
      },
      xAxis:{
        type:'value'
      },
      yAxis:{
        type:'category',
        nameTextStyle:{
          color:'#808080',
        },
        axisLabel:{
          color:'#808080',
          fontSize:16,
        },
        data:['Overview Data'] //array of days
      },
      series:this.chartData?.map((item,i) =>{
        //console.log('item',item)
        return{
          name: item.name,
          type:'bar',
          stack:'total',
          label:{show: true, formatter:'{c} %'},
          emphasis:{focus:'series'},
          data:item.value,
          itemStyle:{
            color: item.color
          },
          barWidth:'40%',
          barMaxWidth: '40%',
        }
      })
     
    }
  }



  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }







}
