import { Component, OnInit } from '@angular/core';




@Component({
  selector: 'app-nav-tab',
  templateUrl: './nav-tab.component.html',
  styleUrl: './nav-tab.component.scss',
})
export class NavTabComponent implements OnInit{

 // TODO Implement with swiper 
  constructor(){}


  ngOnInit(): void {
      
  }

}
