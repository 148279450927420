<div  class="accordion" [ngClass]="{'accordion__border': !borderBottom, 'accordion__borderBottom': borderBottom}">
    
    <!--headers-->
    <div *ngIf="!everleadHeader" class="accordion__header" (click)="togglePanel()">
        <div class="accordion__header__info" *ngIf="!value">
            <span class="accordion__header__info__title">{{ title }}</span>
            <span class="accordion__header__info__date" *ngIf="date">{{ date | date:'medium'}}</span>
        </div>
        <div class="accordion__header__value" *ngIf="value">
            <span class="accordion__header__value__title">{{ title }}</span>
            <span class="accordion__header__value__value" *ngIf="!valueTwo">{{ value}}</span>
            <div class=" accordion__header__values" *ngIf="valueTwo">
                <span class="accordion__header__values__value">{{ value }}</span>
                <span class="accordion__header__values__value">{{ valueTwo }}</span>
            </div>
        </div>
    
        <app-jj-icons [type]=" isOpen ? 'caret-up' : 'caret-down'" [width]="20" [height]="20" [pointer]="true"></app-jj-icons>
    </div>

    <div *ngIf="everleadHeader" class="accordion__brandheader" (click)="togglePanel()">
        <app-component-header [header]="title"></app-component-header>
        <app-jj-icons [type]=" isOpen ? 'caret-up' : 'caret-down'" [width]="20" [height]="20" [pointer]="true"></app-jj-icons>
    </div>


    <!--Body-->
    <div *ngIf="isOpen" class="accordion__body">
        <ng-content></ng-content>
    </div>
</div>





