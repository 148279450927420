import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrl: './number-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true
    }
  ]
})
export class NumberInputComponent implements ControlValueAccessor{

  constructor(){}

  @Input() id : string | number | null = null;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() info: string | null = null;
  @Input() error: boolean | null = false;
  
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  value: number;

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle setting the disabled state if needed
  }

  handleInput(value: number) {

    if(!this.disabled){
      this.value = Number(value);
      this.onChange(this.value);
      this.valueChange.emit(this.value)
      this.onTouched();
    }

  }

}
