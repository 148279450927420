import { Injectable } from '@angular/core';
import { EventService } from './event.service';
import { ApplicationInfoService, ProjectMode } from '../../core/application/application-info.service';
import { Dictionary } from 'app/core/dictionary';
import { Router } from '@angular/router';
import { GraphQLService, ApolloMethod, JJApolloClient } from 'app/shared/service/graphql.service';
import { SessionStorageService } from 'ngx-webstorage';
import { EntityService } from './entity.service';
import { UiService, ControlType } from './ui.service';
import { CommonService } from './common.service';
import { GraphqlqueryService } from './graphqlquery.service';
import { MessagingService, MessagePosition, MessageSeverity } from './messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { environment } from 'environments/environment';
import { ExternaldatasourceService, ExternalDataSource_QueryType } from './externaldatasource.service';
import { ExternalContentService } from './external-content.service';
import { QuestionaireService } from './questionaire.service';
import { LanguageService } from 'app/shared/translation/language.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { RefreshValueService } from '../signalr/refresh-value.service';
import { TrackingService } from './tracking.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { WebpushService } from './webpush.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { ControlsDesignLookupTableComponent } from '../controls/designer/controls/controls-design-lookup-table/controls-design-lookup-table.component';


@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private eventService: EventService,
    private router: Router,
    private userService: UserService,
    private graphqlqueryService: GraphqlqueryService,
    private applicationInfoService: ApplicationInfoService,
    private graphQLService: GraphQLService,
    private entityService: EntityService,
    private sessionStorage: SessionStorageService,
    private uiService: UiService,
    private externalContentService: ExternalContentService,
    private commonService: CommonService,
    private questionaireService: QuestionaireService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private languageService: LanguageService,
    private externaldatasourceService: ExternaldatasourceService,
    private axivasTranslateService: AxivasTranslateService,
    private refreshValueService: RefreshValueService,
    private trackingService: TrackingService,
    private settingsService: SettingsService,
    private webpushService: WebpushService,
    private sharedAPI: SharedAPI
  ) { }

  showProjectSelection() {
    this.applicationInfoService.showLoadProjectSpinner = false;
    this.applicationInfoService.projectSelected = false;
    this.refreshValueService.refreshTranslations().then(() => {
      this.eventService.showJjPopup('StaticForm.ProjectSelection.Header.Header', 'projectselection', '700px;80');
    });
  }

  checkOpportunityRequirement(resultId: any): boolean {
    let returnValue = true;
    if (!this.commonService.isNullOrUndefined(resultId)) {
      // console.warn(
      //   'checkOpportunityRequirement',
      //   this.applicationInfoService.results.Item(resultId),
      //   this.applicationInfoService.entities.Item(this.applicationInfoService.taskEntityId).data
      // );
      if (this.applicationInfoService.results.Item(resultId).isOpportunityCreation === true) {
        if (this.commonService.isNullOrUndefined(
          this.applicationInfoService.entities.Item(this.applicationInfoService.taskEntityId).data.opportunityId)
        ) {
          returnValue = false;
        }
      }
      // returnValue = false;
    }
    return returnValue;
  }

  setApplicationProjectInfo(project: any): Promise<any> {
    return new Promise((resolve) => {
      this.applicationInfoService.currentUserGroup = 0;
      this.applicationInfoService.currentProject = project;
      this.applicationInfoService.projectID = Number(project.id);
      this.applicationInfoService.customerID = Number(project.customerId);
      this.applicationInfoService.projectName = project.projectName;
      this.applicationInfoService.quickSearchValue = '';
      resolve(null);
    });
  }

  checkVideoAndBanner() {
    if (this.applicationInfoService.isMobile()) {
      if (this.applicationInfoService.projectID == 164) {
        this.applicationInfoService.showBanner = true;
      }
    } else {
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['startupVideo'])) {
        this.applicationInfoService.showVideo = true;
        this.eventService.showStartupVideoEvent.emit();
      }
    }
  }

  initializeAdditionalEndpoints() {
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['endpoints'])) {
        const endPoints = this.applicationInfoService.applicationSettings['endpoints'].split(';');
        endPoints.forEach(endpoint => {
          const endPointData = endpoint.split('#');
          this.initializeAdditionalEndpoint(endPointData[0], endPointData[1]);
        });
      }
  }

  initializeAdditionalEndpoint(endPointName, endPointUrl) {
    this.graphQLService.inititalizeDataEndpoint(endPointUrl, endPointName);
  }

  initializeProjectDataEndpoint(project: any): Promise<any> {
    return new Promise((resolve, reject) => {      

      const customerFinalQuery = this.graphqlqueryService.projectServiceCustomer.replace('<customerId>', project.customerId);
      this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, customerFinalQuery)
      .then(customerInfo => {
        let dataEndpointFinalQuery = '';
        if (project.dataInstanceId != null) {
          dataEndpointFinalQuery = this.graphqlqueryService.projectServiceDataInstance
          .replace('<instanceId>', project.dataInstanceId);
        } else {
          dataEndpointFinalQuery = this.graphqlqueryService.projectServiceDataInstance
          .replace('<instanceId>', customerInfo.data.customer.defaultDataInstanceId);
        }        
        this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, dataEndpointFinalQuery)
        .then(result => {
          this.graphQLService.inititalizeDataEndpoint(
            result.data.dataInstance.urlRessource[environment.dataStage] +  result.data.dataInstance.postUrl,
            'dataEndPoint' + project.id
          )
          .then(() => {
            resolve(null);
          })
          .catch(error => {
            console.log('DataEndpoint already initialized');
            reject(error);
          });
        })
        .catch(error => { reject(error); });
      })
      .catch(error => { reject(error); });
    });
  }

  saveTaskInformation(uiEntityId: any): Promise<any> {
    return new Promise((saveTaskInformationResolve, saveTaskInformationReject) => {
      if (!this.commonService.isNullOrUndefined(this.entityService.getEntityValue(uiEntityId, 'id', 1))) {
        let entityData = this.applicationInfoService.entities.Item(uiEntityId).data;
        this.externaldatasourceService.executeExternalDataSource(418, [
          this.commonService.getModifyArrayBody(entityData, this.applicationInfoService.applicationSettings['saveTasksItemsToRemove'].split(';'))
        ]).then(() => { saveTaskInformationResolve(null); }).catch(error => { saveTaskInformationReject(error) });
      } else {
        saveTaskInformationResolve('nothing to save');
      }
    });
  }

  getOptStateForAccount(accountId: any, entityId: any) {

  }


  clearAccountForm() {
    this.unlockAccount();
    this.applicationInfoService.currentTask = null;
    this.applicationInfoService.currentAccount = null;
    this.applicationInfoService.currentContact = null;
    this.entityService.overwriteEntityData(this.applicationInfoService.accountEntityId, []);
    this.entityService.overwriteEntityData(this.applicationInfoService.taskEntityId, []);
    this.entityService.overwriteEntityData(this.applicationInfoService.contactEntityId, []);
    console.log('account form cleared');
  }

  saveCurrentTask(): Promise<any> {
    return new Promise((resolve, reject) => {    
      const promiseArray: Promise<any>[] = [];
      this.loaderService.display(true);      
      promiseArray.push(this.saveTaskInformation(this.applicationInfoService.taskEntityId));
      this.questionaireService.getModifiedQuestionaires().forEach(questionaireId => {
        promiseArray.push(this.questionaireService.saveQuestionaryData(questionaireId));
      });
      Promise.all(promiseArray)
      .then(() => {
        this.eventService.updateQuestionaireDataEvent.emit();
        this.loaderService.display(false);
        resolve(null);
      })
      .catch(error => {
        this.loaderService.display(false);
        reject(error);
      });
    });
  }

  reopenActivity(taskId) {
    return new Promise((reopenActivityResolve, reopenActivityReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.DataApi, ApolloMethod.Mutation,
        this.graphqlqueryService.getQuery('appointmentViewUndoActivity'), [taskId])
      .then(reopenActivityResult => {
        reopenActivityResolve(reopenActivityResult);
      })
      .catch(error => { reopenActivityReject(error); });
    });
  }

  qmTask(taskId: any, qmText: any, qmState: any, qmResultId): Promise<any> {
    if (!qmText) {
      qmText = '';
    }
    return new Promise((qmTaskResolve, qmTaskReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter(JJApolloClient.DataApi, ApolloMethod.Mutation,
        this.graphqlqueryService.getQuery('qmQmTask'), [taskId, qmState, qmText, qmResultId])
      .then(qmQmTaskResult => {
        qmTaskResolve(qmQmTaskResult);
      })
      .catch(error => { qmTaskReject(error); });
    });
  }

  clearTaskInfo() {
    this.applicationInfoService.currentAccount = null;
    this.applicationInfoService.currentTask = null;
    this.applicationInfoService.currentContact = null;
  }

  setContactInformation(contactInformation: any) {
    this.applicationInfoService.currentContact = contactInformation;
    if (contactInformation == null) {
      contactInformation = new Object();
    }
    this.entityService.overwriteEntityData(this.applicationInfoService.contactEntityId, contactInformation);
  }

  getTaskInformation(taskId: any): Promise<any> {
    return new Promise((getTaskInformationResolve, getTaskInformationReject) => {
        const externalDataSource = this.entityService.getExternalDataSourceByType(
          this.applicationInfoService.entities.Item(this.applicationInfoService.taskEntityId), ExternalDataSource_QueryType.Query);
        this.externaldatasourceService.executeQuery(externalDataSource, [
          this.applicationInfoService.projectID,
          taskId
        ], Number(this.applicationInfoService.taskEntityId))
        .then(result => {
          this.applicationInfoService.currentTask = result;
          this.entityService.overwriteEntityData(this.applicationInfoService.taskEntityId, result);
          this.externaldatasourceService.executeExternalDataSource(141)
          .then(queryResult => {
            this.applicationInfoService.workflowHasNotRelevantResult = queryResult;
          });
          if (result.contact == null) {
            this.applicationInfoService.currentContact = null;
          } else {
            this.applicationInfoService.currentContact = result.contact.id;
          }
          if (result.contact == null) {
            this.applicationInfoService.currentAccount = result.account;
          } else {
            this.applicationInfoService.currentContact = result.contact;
          }
          getTaskInformationResolve(result);
          })
        .catch(error => {
          getTaskInformationReject(error);
          });
    });
  }

  nextFollowUp(): Promise<any> {
    return new Promise((getCurrentTaskResolve, getCurrentTaskReject) => {
      let externalDatasourceId = 176;
      if (this.applicationInfoService.projectInfo.useSqlFollowupLogic == true) {
        externalDatasourceId = 887;
      }
      let functionDelay = 0;
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['nextFollowUpDelay'])) {
        functionDelay = Number(this.applicationInfoService.applicationSettings['nextFollowUpDelay']);
        console.warn('nextFollowUp delay', functionDelay);
      }

      if (this.applicationInfoService.currentUserGroup == null) {
        this.messagingService.showDefaultInfo('', 'NextFollowUp.Label.NoUserGroup');
        getCurrentTaskReject(null);
        return;
      }

      this.loaderService.showSpinner(true);
      setTimeout(() => {
        this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [
          this.applicationInfoService.currentUserGroup,
          this.entityService.getEntityControlMembers(this.applicationInfoService.taskEntityId),
          this.entityService.getEntityControlMembers(this.applicationInfoService.accountEntityId),
          this.entityService.getEntityControlMembers(this.applicationInfoService.contactEntityId),
          this.applicationInfoService.getSelectedCategory()
        ])
        .then(nextFollowUpResult => {
          let nextFollowUpObject = null;
          nextFollowUpObject = new Object();

          if (this.applicationInfoService.projectInfo.useSqlFollowupLogic) {            
            // NextFollowUp2
            if (nextFollowUpResult != null) {
              const someArray = JSON.parse(nextFollowUpResult);
              Object.keys(someArray).forEach(key => {
                nextFollowUpObject[this.commonService.deCapitalizeFirstLetter(key)] = someArray[key];
              });

              if (someArray.Contact != null) {
                nextFollowUpObject.contact = new Object();
                Object.keys(someArray.Contact).forEach(key => {
                  nextFollowUpObject.contact[this.commonService.deCapitalizeFirstLetter(key)] = someArray.Contact[key];
                });
              } else {
                nextFollowUpObject.contact = null;
              }

              if (someArray.Account != null) {
                nextFollowUpObject.account = new Object();
                Object.keys(someArray.Account).forEach(key => {
                  nextFollowUpObject.account[this.commonService.deCapitalizeFirstLetter(key)] = someArray.Account[key];
                });  
              }
            } else {
              this.applicationInfoService.currentTask = null;
              this.applicationInfoService.currentAccount = null;
              this.applicationInfoService.currentContact = null;  
            }  
          } else {            
            nextFollowUpObject = nextFollowUpResult;
          }

          // Immer
          if (!this.commonService.isNullOrUndefined(nextFollowUpObject)) {
            if (!this.commonService.isNullOrUndefined(nextFollowUpObject.account)) {
              this.entityService.overwriteEntityData(this.applicationInfoService.taskEntityId, nextFollowUpObject);
              this.entityService.overwriteEntityData(this.applicationInfoService.accountEntityId, nextFollowUpObject.account);
              this.setContactInformation(nextFollowUpObject.contact);
              this.applicationInfoService.currentTask = nextFollowUpObject;
              this.applicationInfoService.currentAccount = nextFollowUpObject.account;
              this.updateTaskRelevantInformation();
              this.eventService.updateControlAttributes();
              this.eventService.updatePowerButtons();
            } else {
              nextFollowUpObject = null;
            }
          } else {
            this.applicationInfoService.currentTask = null;
            this.applicationInfoService.currentAccount = null;
            this.applicationInfoService.currentContact = null;
          }

          this.loaderService.showSpinner(false);
          this.router.navigate(['/account']);
          getCurrentTaskResolve(nextFollowUpObject);
        })
        .catch(error => {
          this.loaderService.showSpinner(false);
          getCurrentTaskReject(error);
        });
      }, functionDelay);
    });
  }

  getCurrentTask(): Promise<any> {
    // console.warn('getCurrentTask', this.applicationInfoService.currentTask, this.applicationInfoService.taskEntityId);
    this.loaderService.display(true);
    return new Promise((resolve, reject) => {
      if (this.applicationInfoService.currentTask != null) {
        this.externaldatasourceService.executeExternalDataSource(20, [this.applicationInfoService.currentTask.id])
        .then(result => {
          // console.warn('getCurrentTask result', result);
          if (!result) {
            this.loaderService.display(false);
            reject(result);
            return;
          }
          this.applicationInfoService.currentTask = result;
          // console.warn('getCurrentTask currentTask', this.applicationInfoService.currentTask);
          this.entityService.overwriteEntityData(this.applicationInfoService.taskEntityId, result);
          this.eventService.updatePowerButtons();
          this.externaldatasourceService.executeExternalDataSource(141)
          .then(queryResult => {
            this.applicationInfoService.workflowHasNotRelevantResult = queryResult;
          })
          .catch(error => {
            console.warn('getCurrentTask', error);
          });
          const promiseArray: Promise<any>[] = [];
          if (result.contact == null) {
            promiseArray.push(this.getCurrentContact(null));
          } else {
            promiseArray.push(this.getCurrentContact(result.contact.id));
          }
          promiseArray.push(this.getCurrentAccount(result.account.id));
          Promise.all(promiseArray)
          .then(() => {
            this.loaderService.display(false);
            this.commonService.setSessionStorageItem('CurrentTask', this.applicationInfoService.currentTask.id);
            this.updateTaskRelevantInformation();
            resolve(result);
          })
          .catch(error => {
            this.loaderService.display(false);
            reject(error);
          });
        })
        .catch(error => {
          this.loaderService.display(false);
          reject(error);
        });
      } else {
        this.loaderService.display(false);
        resolve(null);
      }
    });
  }

  updateTaskRelevantInformation() {
    this.eventService.updateQuestionaireDataEvent.emit();
  }

  getCurrentContact(contactId: any): Promise<any> {
    // console.warn('getCurrentContact', contactId);
    return new Promise((resolve, reject) => {
      if (contactId <= 0 || contactId == null) {
        this.applicationInfoService.currentContact = null;
        resolve(null);
        this.eventService.updateControlAttributes();
        return;
      }
      this.entityService.loadEntity([this.applicationInfoService.contactEntityId, contactId])
      .then(result => {
        if (this.applicationInfoService.isDeveloper) { console.info('isDeveloper: Contact loaded'); }
        this.lockAccount();
        this.setContactInformation(result);
        this.commonService.setSessionStorageItem('CurrentContact', contactId);
        this.eventService.updateControlAttributes();
        this.eventService.updateContactQuestionaire();
        this.eventService.customEvent.emit({ id: 'contactLoaded' });
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  clearTaskData() {
    this.unlockAccount();
    this.entityService.clearEntityData(this.applicationInfoService.contactEntityId);
    this.entityService.clearEntityData(this.applicationInfoService.accountEntityId);
    this.entityService.clearEntityData(this.applicationInfoService.taskEntityId);
    this.applicationInfoService.currentTask = null;
    this.applicationInfoService.currentAccount = null;
    this.applicationInfoService.currentContact = null;
  }

  getCurrentAccount(accountId: any): Promise<any> {
    return new Promise((getCurrentAccountResolve, getCurrentAccountReject) => {
        this.loaderService.display(true, false, 'getCurrentAccount');
        this.unlockAccount();
        this.entityService.executeExternalDataSource(this.applicationInfoService.accountEntityId, 'query', [accountId])
        .then(result => {
          if (this.applicationInfoService.isDeveloper) { console.info('isDeveloper: Account loaded'); }
          this.applicationInfoService.currentAccount = result;
          this.entityService.overwriteEntityData(this.applicationInfoService.accountEntityId, result);
          this.eventService.updateControlContentByControlType(ControlType.CompactList);
          this.lockAccount();
          this.commonService.clearSessionStorageItem('CurrentTask');
          this.commonService.setSessionStorageItem('CurrentAccount', accountId);
          this.loaderService.display(false, false, 'getCurrentAccount');
          getCurrentAccountResolve(result);
        })
        .catch(error => {
          this.loaderService.display(false, false, 'getCurrentAccount');
           getCurrentAccountReject(error);
        });
    });
  }

  lockAccount(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.applicationInfoService.currentAccount) {
        console.log('lockAccount', 'no account to lock');
        return;
      }
      if (this.applicationInfoService.currentAccount.isDummyAccount == true) {
        if (this.applicationInfoService.currentContact) {
          console.log('lockAccount', 'dummyAccount', this.applicationInfoService.currentAccount.id, this.applicationInfoService.currentContact.accountId);
          if (this.applicationInfoService.currentAccount.id == this.applicationInfoService.currentContact.accountId) {
            this.externaldatasourceService.executeExternalDataSource(801);
          }
        }
        return;
      }
      const lockQuery: string = this.graphqlqueryService.accountTryLockAccountMutation
        .replace('<0>', this.applicationInfoService.projectID.toString())
        .replace('<1>', this.applicationInfoService.currentAccount.id)
        .replace('<2>', this.applicationInfoService.userID.toString())
        ;
      this.graphQLService.apolloGQLpromise('data', ApolloMethod.Mutation, lockQuery)
      .then(lockAccountResult => {
        if (lockAccountResult.data.tryLockAccountMutation) {
          const lockObject = lockAccountResult.data.tryLockAccountMutation;
          this.applicationInfoService.accountLockInformation = lockObject;
          if ((Number(lockObject.lockerUserId) !== Number(this.applicationInfoService.userID)) &&
              (Number(lockObject.lockerUserId) !== Number(this.applicationInfoService.user.fakeUserId))) {
            if (this.applicationInfoService.projectUsers.ContainsKey(lockObject.lockerUserId.toString())) {
              this.applicationInfoService.lockUser = this.applicationInfoService.projectUsers
                .Item(lockObject.lockerUserId.toString()).userName;
            } else {
              this.applicationInfoService.lockUser = this.axivasTranslateService.getTranslationTextForToken('Task.Lock.UnknownUser');
            }
            this.applicationInfoService.accountLocked = true;
          } else {
            this.applicationInfoService.accountLocked = false;
          }
          resolve(null);  
        }
      })
      .catch(error => {
        reject(error);
      });
    })
  }

  bookDefaultStepResult(task, resultId, notes) {
    this.externaldatasourceService.executeExternalDataSource(20, [task.taskId])
    .then(taskToCancel => {
      taskToCancel.stepId = 1123;
      this.externaldatasourceService.executeExternalDataSource(418, [
        this.commonService.getModifyArrayBody(taskToCancel, ['questionAnswers', 'contact', 'account'])
      ]).then(() => {
        taskToCancel.resultId = resultId;
        taskToCancel.notes = notes;
        this.externaldatasourceService.executeExternalDataSource(840, [
          this.commonService.getModifyArrayBody(taskToCancel, ['questionAnswers', 'contact', 'account'])
        ]).then(() => {
          this.eventService.closeJjPopup();
          this.eventService.updateMicroCampaignViewEvent.emit();
        });
      });
    });
  }

  bookNotRelevantCp(newCpId: any) {
    return new Promise((bookNotRelevantCpResolve, bookNotRelevantCpReject) => {
      if (newCpId === this.applicationInfoService.currentContact.id) {
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Info, '',
          this.axivasTranslateService.getTranslationTextForToken('Contact.Message.CPAlreadyActive'), false);
        bookNotRelevantCpReject('Contact not changed');
        return;
      }
      let query = this.graphqlqueryService.projectServiceBookNotRelevantCp;
      query = query.replace('<0>', this.applicationInfoService.currentTask.id);
      query = query.replace('<1>', newCpId);
      this.loaderService.display(true);
      this.graphQLService.apolloGQLpromise('data', ApolloMethod.Query, query)
      .then(result => {
        this.loaderService.display(false);
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, '',
          this.axivasTranslateService.getTranslationTextForToken('Contact.Message.CPNotRelevantChanged'), false);
        bookNotRelevantCpResolve(result.data.bookNotRelevantCp.id);
      })
      .catch(error => {
        this.loaderService.display(false);
        bookNotRelevantCpReject(error);
      });
    });
  }

  getProjectByProjectId(projectId: any): Promise<any> {
    return new Promise((getProjectByProjectIdResolve, getProjectByProjectIdReject) => {
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query,
        this.graphqlqueryService.projectServiceProjectById, [projectId])
      .then(projectServiceProjectByIdResult => { getProjectByProjectIdResolve(projectServiceProjectByIdResult); })
      .catch(error => { getProjectByProjectIdReject(error); });
    });
  }

  getLastProject() {
    let returnValue = null;
    returnValue = this.sessionStorage.retrieve('lastproject');
    if (returnValue == null) {
      returnValue = localStorage.getItem('lastProject');
    }
    return returnValue;
  }

  getProjectUsers(): Promise<any> {
    this.applicationInfoService.projectUsers = new Dictionary();
    return new Promise((resolve, reject) => {
      this.externaldatasourceService.executeExternalDataSource(139)
      .then(result => {
        result.forEach(element => {
          this.applicationInfoService.projectUsers.Add(element.id, element);
        });
        resolve(null);
      })
      .catch(error => { reject(error); });
    });
  }

  getNextTask(): Promise<any> {
    return new Promise((getNextTaskResolve, getNextTaskReject) => {
      if (this.applicationInfoService.currentUserGroup === 0) {
        this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Warning,
          this.axivasTranslateService.getTranslationTextForToken('GetNextTask.Message.MessageHeader'),
          this.axivasTranslateService.getTranslationTextForToken('GetNextTask.Message.MessageText'), false);
        getNextTaskReject();
        return;
      }
      this.loaderService.display(true);
      this.nextFollowUp()
      .then(nextFollowUpResult => {
        this.lockAccount();
        getNextTaskResolve(nextFollowUpResult);
        this.loaderService.display(false);
      })
      .catch(error => {
        this.loaderService.display(false);
        getNextTaskReject(error);
      });
    });
  }

  getSubResultQueryString(entityId: any): string {
    let subResults = '';
    if (this.applicationInfoService.entities.Item(entityId).data.subResults) {
      this.applicationInfoService.entities.Item(entityId).data.subResults.forEach(subResult => {
        if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(entityId).data.subResultsadditionalText)) {
          subResults = subResults.concat('{ answerId: <0> },'.replace('<0>', subResult));
        } else {
          if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(entityId).data.subResultsadditionalText[subResult])) {
            subResults = subResults.concat('{ answerId: <0> },'.replace('<0>', subResult));
          } else {
            subResults = subResults.concat(
              '{ answerId: <0>, answerText: "<1>"},'
              .replace('<0>', subResult)
              .replace('<1>', this.applicationInfoService.entities.Item(entityId).data.subResultsadditionalText[subResult])
            );
          }
        }
      });
      if (subResults.length > 0) {
        subResults = subResults.substring(0, subResults.length - 1);
      }
    }
    return subResults;
  }

  checkIfTaskResultIsOptInOptOut(taskResultId: any, stayInAccount = false): boolean {
    let returnValue = false;
    if (taskResultId) {
      const resultToCheck = this.applicationInfoService.results.Item(taskResultId.toString());
      if (resultToCheck.isConsentRequired) { returnValue = true; }
      if (resultToCheck.isOptIn) { returnValue = true; }
      if (resultToCheck.isOptOut) { returnValue = true; }
      this.applicationInfoService.optInOptOutBookingStayInAccount = stayInAccount;
      this.applicationInfoService.optInOptOutWorkflow = resultToCheck;
      // console.warn('checkIfTaskResultIsOptInOptOut', taskResultId, returnValue, resultToCheck);
    }
    return returnValue;
  }

  bookTask(taskEntityId = null): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.commonService.isNullOrUndefined(this.applicationInfoService.currentTask) && taskEntityId == null) {
        reject ('no task to book');
        return;
      }
      // Check if contact is required
      let resultInfo = this.applicationInfoService.results.Item(this.applicationInfoService.entities.Item(this.applicationInfoService.taskEntityId).data.resultId);
      if (taskEntityId != null) {
        resultInfo = this.applicationInfoService.results.Item(this.applicationInfoService.entities.Item(taskEntityId).data.resultId);
      } else {
        taskEntityId = this.applicationInfoService.taskEntityId;
      }
      if (resultInfo.isContactRequired &&
        this.commonService.isNullOrUndefined(this.applicationInfoService.currentContact &&
        this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(taskEntityId).data.contactId)
      )) {
        this.messagingService.showDefaultWarning('', this.axivasTranslateService.getTranslationTextForToken('Task.Message.BookingRequiresContact'), false);
        reject(null);
        return;
      }

      if (this.applicationInfoService.projectInfo.useSqlBookingLogic == true) {
        // V2
        this.loaderService.display(true);
        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(taskEntityId).data.uiNextFollowUpDate)) {
          this.applicationInfoService.entities.Item(taskEntityId).data.followUpDate = this.applicationInfoService.entities.Item(taskEntityId).data.uiNextFollowUpDate
        }
        this.externaldatasourceService.executeExternalDataSource(892, [
          this.commonService.getModifyArrayBody(this.applicationInfoService.entities.Item(taskEntityId).data, this.applicationInfoService.applicationSettings['itemsToRemoveBookingV2'].split(';')),
          this.getSubResultQueryString(taskEntityId)          
        ]).then(result => {
          this.loaderService.display(false);
          this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, '',
          this.axivasTranslateService.getTranslationTextForToken('Task.Booking.Successfull'), false);
          this.eventService.updateMicroCampaignViewEvent.emit();
          resolve(result);
        }).catch(() => { this.loaderService.display(false); });
      } else {

        // V1
        this.loaderService.showSpinner(true);
        let query = this.graphqlqueryService.projectServiceBookTask;
        query = query.replace('<0>', this.applicationInfoService.entities.Item(taskEntityId).data.id);
        query = query.replace('<2>', this.applicationInfoService.entities.Item(taskEntityId).data.resultId);
        query = query.replace('<3>', this.applicationInfoService.entities.Item(taskEntityId).data.followUpUserId);
        if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(taskEntityId).data.uiNextFollowUpDate)) {
            this.applicationInfoService.entities.Item(taskEntityId).data.uiNextFollowUpDate = Date.now();
        }
        query = query.replace('<4>', new Date(this.applicationInfoService.entities.Item(taskEntityId).data.uiNextFollowUpDate).toUTCString());
        query = query.replace('<5>', this.getSubResultQueryString(taskEntityId));
        query = query.replace('<6>',
          this.applicationInfoService.entities.Item(taskEntityId).data.isFollowUpAppointment);
        if (this.applicationInfoService.entities.Item(taskEntityId).data.opportunityId === undefined) {
          this.applicationInfoService.entities.Item(taskEntityId).data.opportunityId = null;
        }
        query = query.replace('<7>', this.applicationInfoService.entities.Item(taskEntityId).data.opportunityId);
  
        if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(taskEntityId).data.isAgentExclusive)) {
          this.applicationInfoService.entities.Item(taskEntityId).data.isAgentExclusive = false;
        }
        if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(taskEntityId).data.isNotifyFollowUpOwner)) {
          this.applicationInfoService.entities.Item(taskEntityId).data.isNotifyFollowUpOwner = false;
        }
  
        query = query.replace('<8>',
          this.applicationInfoService.entities.Item(taskEntityId).data.isAgentExclusive);
        query = query.replace('<9>',
          this.applicationInfoService.entities.Item(taskEntityId).data.isNotifyFollowUpOwner);
        // console.warn(this.applicationInfoService.entities.Item(taskEntityId).data);
        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(taskEntityId).data.uiTemplateContentOverride)) {
          const eMailArray = [];
          const uiTemplateContentOverride: Dictionary<string> = this.applicationInfoService.entities.Item(taskEntityId).data.uiTemplateContentOverride;
          uiTemplateContentOverride.impArray.items.forEach(item => {
            let content = uiTemplateContentOverride.Item(item);
            content = content.replace(/\r\n/g, '<br>');
            content = content.replace(/\n/g, '<br>');
            eMailArray.push({ key: item, content: content });
          })
          query = query.replace('<templateContentOverride>', 'templateContentOverride: <templateContentOverrideContent>');
          query = query.replace('<templateContentOverrideContent>', JSON.stringify(eMailArray));
          query = query.replace(/"key"/g, 'key');
          query = query.replace(/"content"/g, 'content');
        } else {
          query = query.replace('<templateContentOverride>', '');
        }
  
        this.graphQLService.apolloGQLpromise('data', ApolloMethod.Query, query)
        .then(result => {
          this.loaderService.showSpinner(false);
          this.messagingService.showNewMessage(MessagePosition.TopRight, MessageSeverity.Success, '',
          this.axivasTranslateService.getTranslationTextForToken('Task.Booking.Successfull'), false);
          this.eventService.updateMicroCampaignViewEvent.emit();
          resolve(result);
        })
        .catch(error => {
          console.error('bookTask', error);
          this.loaderService.showSpinner(false);
          reject(error);
        });
      }     
    });
  }

  clearRedirectValues() {
    this.sessionStorage.clear('redirectValuesCreated');
    this.sessionStorage.clear('redirectAccountId');
    this.sessionStorage.clear('redirectProjectId');
    this.sessionStorage.clear('redirectContactId');
    this.sessionStorage.clear('redirectTaskId');
  }

  unlockAccount(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.applicationInfoService.applicationSettings['noAutoUnlockOfAccounts']=='true') {
        resolve(null);
        return;
      }
      if (this.applicationInfoService.currentAccount) {
        this.graphQLService.apolloGQLpromise('data', ApolloMethod.Query, this.graphqlqueryService.projectServiceTryUnlockAccountMutation)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
      } else {
        resolve('no account to unlock');
      }
    });
  }

  checkIfQuestionsHaveRequiredAnswers(resultId: any, taskEntityId: any): boolean {
    let questionsHaveRequiredAnswers = true;
    let relevantQuestionHasAnAnswer = false;
    const entityData = this.applicationInfoService.entities.Item(taskEntityId).data;
    let selectedSubResults = entityData.subResults;
    if (this.commonService.isNullOrUndefined(selectedSubResults)) {
      selectedSubResults = [];
    }
    const result = this.applicationInfoService.results.Item(resultId);
    if (this.commonService.isNullOrUndefined(result)) {
      return true;
    }
    const questions = result.questions;
    // console.warn('checkIfQuestionsHaveRequiredAnswers', entityData, result, '-', resultId, taskEntityId);
    questions.forEach(question => {
      if (question.isMandatory) {
        if (question.parentAnswerId !== null && this.commonService.checkIfItemIsInArray(selectedSubResults, question.parentAnswerId)
          || (question.parentAnswerId === null)) {
            relevantQuestionHasAnAnswer = false;
            if (this.commonService.isNullOrUndefined(selectedSubResults)) {
              return false;
            }
            question.answers.forEach(answer => {
              if (this.commonService.checkIfItemIsInArray(selectedSubResults, answer.id)) {
                relevantQuestionHasAnAnswer = true;
              }
            });
            if (relevantQuestionHasAnAnswer === false) {
              questionsHaveRequiredAnswers = false;
            }
        }
      }
    });
    // console.warn('questionsHaveRequiredAnswers', questionsHaveRequiredAnswers);
    return questionsHaveRequiredAnswers;
  }

  preloadAccountControls() {
      this.uiService.getUIfromID('1300', 1300);
      this.uiService.getUIfromID('1400', 1400);
      this.uiService.getUIfromID('1500', 1500);
      this.uiService.getUIfromID('6217', 6217);
  }

  updateLeadStateOfProject(projectId: any) {
    this.loaderService.display(true, false, 'updateProject');
    this.externaldatasourceService.executeExternalDataSource(147, [projectId])
    .then(() => {
      this.loaderService.display(false, false, 'updateProject');
    })
    .catch(error => {
      console.error('updateProject', error);
      this.loaderService.display(false, false, 'updateProject');
    });
  }

  updateProjectOverview(projectId: any) {
    this.loaderService.display(true, false, 'updateProject');
    this.externaldatasourceService.executeExternalDataSource(150, [projectId])
    .then(() => {
      this.loaderService.display(false, false, 'updateProject');
    })
    .catch(error => {
      console.error('updateProject', error);
      this.loaderService.display(false, false, 'updateProject');
    });
  }

  setDeleteFlag(params): Promise<any> {
    return new Promise((setDeleteFlagResolve, setDeleteFlagReject) => {
      let externalDataSourceId = 0;
      switch (params[0].toLowerCase()) {
        case 'account':
          externalDataSourceId = 353;
          break;
        case 'contact':
          externalDataSourceId = 352;
          break;
        case 'task':
          externalDataSourceId = 351;
          if (Number(params[1]) === this.applicationInfoService.currentTask.id) {
            this.applicationInfoService.currentTask = null;
          }
          break;
      }
      // console.warn('setDeleteFlag', params, params[0].toLowerCase(), 'task', externalDataSourceId);
      if (externalDataSourceId !== 0) {
        this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [params[1]])
        .then(() => { setDeleteFlagResolve(null); })
        .catch(() => { setDeleteFlagReject(null); });
      } else {
        setDeleteFlagReject('setDeleteFlag - wrong first param');
      }
    });
  }

  getApplicationSettings(): Promise<any> {
    return new Promise((getApplicationSettingsResolve, getApplicationSettingsReject) => {
      this.externaldatasourceService.executeExternalDataSource(119)
      .then(getApplicationSettingsResult => { getApplicationSettingsResolve(getApplicationSettingsResult); })
      .catch(error => { getApplicationSettingsReject(error); })
    });
  }
}
