<div *ngIf="(page === 0 && !selectedMailCampaign) || (page === 1 && !selectedMicroCampaign)" class="campaignDashboard__container">
    <div class="campaignDashboard__container__headerChart">
        <div class="campaignDashboard__container__headerChart__select">
            Bitte wählen Sie eine Kampagne aus der linken Liste...
        </div>
    </div>

    <div class="campaignDashboard__container__chartContent">
        <div class="campaignDashboard__container__placeholder">
            <div class="campaignDashboard__container__placeholder__content">
                <app-jj-icons type="chart-bar-thin" [width]="250" [height]="250" color="gray0"></app-jj-icons>
                <h2>Kampagne auswählen</h2>
                <span class="campaignDashboard__container__placeholder__content__label">
                    Wählen Sie eine Kampagne aus die linke Spalte aus</span>
            </div>
        </div>
    </div>
</div>




<div class="campaignDashboard__container" *ngIf="page === 0 && selectedMailCampaign != null">
    <div class="campaignDashboard__container__headerChart">
        <div *ngIf="selectedMailCampaign" class="campaignDashboard__container__headerChart__selected">
            {{ selectedMailCampaign.batchName | texttransform }}
        </div>

        <div *ngIf="selectedMailCampaign">
            <div class="campaignDashboard__container__headerChart__info">
                <div>{{ 'CampaignDashboard.Label.CampaignStartedAt' | texttransform }}</div>
                <div>{{ selectedMailCampaign.startedAt | date: 'medium' }}</div>
            </div>

            <div *ngIf="selectedMailCampaign.startedAt != null && selectedMailCampaign.countSent == 0"
                class="campaignDashboard__container__headerChart__info">
                <div class="">{{ 'CampaignDashboard.Label.PlannedSendDate' | texttransform }}</div>
                <div>{{ getSendDate(selectedMailCampaign) | date: 'medium' }}</div>
            </div>
        </div>

    </div>

    <div class="campaignDashboard__container__subheaderChart" *ngIf="selectedMailCampaign">
        <app-main-button *ngIf="selectedMailCampaign && selectedMailCampaign.startedAt != null" type="borderSmall"
            svg="chart-line-up" label="CampaignDashboard.Label.ShowMailshotDetails"
            (onClick)="showMailshotDetails(selectedMailCampaign)"></app-main-button>

        <app-main-button *ngIf="selectedMailCampaign && selectedMailCampaign?.startedAt == null" type="borderSmall"
            svg="editor" (onClick)="editMailCampaign(selectedMailCampaign)"
            label="UserDashboard.Label.Edit"></app-main-button>
    </div>

    <div class="campaignDashboard__container__chartContent">
        <div class="campaignDashboard__container__nodata"
            *ngIf="selectedMailCampaign && selectedMailCampaign.startedAt == null">
            {{ 'Pie.Label.NoDataAvailable' | texttransform }}
        </div>
        <div *ngIf="selectedMailCampaign && selectedMailCampaign.startedAt != null">
            <app-bar-chart [chartData]="chartData" controlid="mailcampaign" component="campaignDashboard" [height]="280"></app-bar-chart>

            <div class="campaignDashboard__container__legend">
                <div *ngFor="let legend of chartData" class="campaignDashboard__container__legend__item">
                    <app-jj-icons [type]="getIconDefinition(legend.name)" [width]="26" [height]="26" [pointer]="false"></app-jj-icons>
                    <div class="campaignDashboard__container__legend__value">{{legend.value}}</div>
                    <div class="campaignDashboard__container__legend__name">{{legend.name | texttransform }}</div>
                </div>
            </div>

            <div class="campaignDashboard__container__buttons">
                <app-main-button type="small" (onClick)="mailShot()" label="Starten" svg="play"></app-main-button>
                <app-main-button type="small" (onClick)="mailShot()" label="Stoppen" svg="stop"></app-main-button>
                <app-main-button type="small" (onClick)="mailShot()" label="Abbrechen" svg="trash"></app-main-button>
                <app-main-button type="small" (onClick)="mailShot()" label="Duplizieren" svg="copy"></app-main-button>
            </div>
        </div>

    </div>
</div>


<div class="campaignDashboard__container" *ngIf="page === 1 && selectedMicroCampaign != null">
    <div class="campaignDashboard__container__headerChart">
        <div class="campaignDashboard__container__headerChart__selected">
            <span *ngIf="!selectedMicroCampaign.nameTranslationTokenId">{{ selectedMicroCampaign.campaignName | texttransform }}</span>
                <span *ngIf="selectedMicroCampaign.nameTranslationTokenId">
                    {{ axivasTranslateService.getTranslationTextForId(selectedMicroCampaign.nameTranslationTokenId) | texttransform }}
                </span>
        </div>
    </div>
    <div class="campaignDashboard__container__subheaderChart"></div>
    <div class="campaignDashboard__container__chartContent">
        <div class="campaignDashboard__container__chartContent__label">
            {{ 'CampaignDashboard.Label.BarChartHeader' | texttransform }}
        </div>
       <!--  <app-bar-chart [chartData]="chartData" component="campaignDashboard" controlid="microcampaign"></app-bar-chart> -->

       <app-echarts
       *ngIf="chartDataMicroCampaign.data.length > 0"
       [chart]="'bar'"
       [height]=400
       [controlid]="barChartId"
       [chartData]="chartDataMicroCampaign"
       [barWidth]="20"
       [barMaxWidth]="20"
       [darkmode]="true"
       [legend]="true"
       [legendPosition]="{'top': 10 }"
       [showXaxis]="true"
       [xAxisType]="'category'"
       [xAxisLabel]="{ 'interval': 0, 'rotate': 10, 'margin': 20 }"
       [yAxisType]="'value'"
       [barLabel]="{
         'show': true,
         'position': 'insideBottom',
         'distance': 15,
         'align':'left',
         'verticalAlign': 'middle',
         'rotate': 90
   }">
   </app-echarts>

        <div class="campaignDashboard__container__chartContent__legends">
            <div class="selectedMicroCampaignDetail__headerline">
                <div class="selectedMicroCampaignDetail__headerline__svg">
                    <app-jj-icons pointer=false type="pulse"></app-jj-icons>
                   <!--  <svg-icon src="assets/images/svg/dashboard_campaign_task.svg"  [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon> -->
                </div>
                <div class="selectedMicroCampaignDetail__headerline__value everleadCoral">{{ selectedMicroCampaign.taskCount }}</div>
                <div class="selectedMicroCampaignDetail__headerline__label">{{ 'UserDashboard.Label.YourCampaignsTasks' | texttransform }}</div>
            </div>
            <div class="selectedMicroCampaignDetail__headerline">
                <div class="selectedMicroCampaignDetail__headerline__svg" >
                    <app-jj-icons pointer=false type="address-book-tabs"></app-jj-icons>
                   <!--  <svg-icon src="assets/images/svg/dashboard_campaign_user.svg"  [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon> -->
                </div>
                <div class="selectedMicroCampaignDetail__headerline__value everleadCoral">{{ selectedMicroCampaign.contactCount }}</div>
                <div class="selectedMicroCampaignDetail__headerline__label">{{ 'UserDashboard.Label.YourCampaignsContacts' | texttransform }}</div>
                <div class="selectedMicroCampaignDetail__headerline__svg" >
                    <app-main-button 
                    svg="address-book-tabs"
                    (onClick)="showCampaignUser(selectedMicroCampaign)"
                    label="UserDashboard.Label.ShowYourCampaignsContacts"
                    ></app-main-button>
               <!--      <button class="everleadCustomButton" (click)="showCampaignUser(selectedMicroCampaign)">
                        {{ 'UserDashboard.Label.ShowYourCampaignsContacts' | texttransform }}
                    </button> -->
                </div>
            </div>
            <div class="selectedMicroCampaignDetail__headerline">
                <div class="selectedMicroCampaignDetail__headerline__svg">
                    <app-jj-icons [pointer]="false" type="ranking"></app-jj-icons>
                    <!-- <svg-icon src="assets/images/svg/dashboard_campaign_cup.svg"  [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon> -->
                </div>
                <div class="selectedMicroCampaignDetail__headerline__value everleadCoral">{{ selectedMicroCampaign.positiveResultCount }}</div>
                <div class="selectedMicroCampaignDetail__headerline__label">{{ 'UserDashboard.Label.YourCampaignsWins' | texttransform }}</div>
            </div>
        </div>
    </div>
</div>