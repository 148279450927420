<div class="cardContainer" [ngClass]="{ cardContainerAccount: type == 'account' }">
    <div class="card">
        <div class="cardMobile">
            <div class="cardMobile__name">
                <span *ngIf="type == 'account'" class="ellipsisText">{{data?.name1}}</span>
                <span *ngIf="type == 'contact'" class="ellipsisText">{{data?.firstName}} {{data?.lastName}}</span>
            </div>
            <div class="cardMobile__progresstate">
                {{ getCurrentProcessingState() | texttransform }}
            </div>
            <div class="cardMobile__leadstate">
                {{ getCurrentLeadState() | texttransform }}
            </div>
        </div>
        <!-- <h3>
            <span *ngIf="type == 'account'" >{{'Account.Label.Header' | texttransform }}</span>
            <span *ngIf="type == 'contact'" >{{'Contact.Label.Header' | texttransform }}</span>
        </h3> -->
        <div class="card__inner cardNonMobile">

            <div class="card__colSVG">
                <app-jj-icons [type]="type === 'contact' ? 'user' : 'building'" [width]="32" [height]="32"></app-jj-icons>
            </div>

            <div class="card__col">
                <p class="cardTitle" *ngIf="type == 'account'" >{{'Account.Label.Header' | texttransform }}</p>
                <p class="cardTitle" *ngIf="type == 'contact'">{{'Contact.Label.Header' | texttransform }}</p>
                <div *ngIf="type == 'account'" class="cardCompanyName">{{data?.name1}}</div>
                <div *ngIf="type == 'contact'" class="cardName">{{data?.firstName}} {{data?.lastName}}</div>
            </div>

            <div class="card__col " [ngClass]="{ cardColAccount: type == 'account' }">
                <p class="cardTitle">{{'Card.Label.Bearbeitungsstatus' | texttransform }}</p>
                <select *ngIf="type == 'contact' && showSelectionOfEditingState && showDropdown" (change)="changeEditingState()"
                    [(ngModel)]="processingStateId">
                    <option *ngFor="let processingState of processingStates" [ngValue]="processingState.id">
                        {{ commonService.getTranslationValueFromArray(processingState) | texttransform  }}
                    </option>
                </select>

                <app-status *ngIf="type == 'account' || !showSelectionOfEditingState"
                    noLabelColor="true" 
                    [blue]="processingStatus?.defaultName === 'closed'" 
                    [orange]="processingStatus?.defaultName === 'in process'" 
                    [green]="processingStatus?.defaultName === 'open'"
                    [width]="15" 
                    [height]="15"
                    [label]="getCurrentProcessingState() | texttransform">
                </app-status>
            </div>

            <div class="card__col">
                <p class="cardTitle">{{'Card.Label.Leadstatus' | texttransform }}</p>
                <app-status 
                    noLabelColor="true" 
                    [color]="leadStatus?.leadStatusReportColor" 
                    [width]="15" 
                    [height]="15"
                    [label]="getCurrentLeadState() | texttransform"
                    [ellipsis]="true"
                ></app-status>
            </div>

            <div class="card__col" *ngIf="data.leadScoringCalculated || data.leadScoringCalculated == 0">
                <p class="cardTitle">{{'Card.Label.Leadscoring' | texttransform }}</p>
                <app-bar-leadscore [accountHeader]="true" [value]="data.leadScoringCalculated" [popupAllowed]="true"></app-bar-leadscore>
            </div>

            <div class="card__col" *ngIf="type == 'contact'">
                <p class="cardTitle">{{'Card.Label.OptInOptOut' | texttransform }}</p>
                <div class="optInCards">
                    <app-jj-icons type="phone" [pointer]="false" [color]="getOptinColor(45)" [width]="20" [height]="20"></app-jj-icons>
                    <app-jj-icons type="envelope" [pointer]="false" [color]="getOptinColor(47)" [width]="20" [height]="20"></app-jj-icons>
                    <app-jj-icons type="mailbox" [pointer]="false" [color]="getOptinColor(6766)" [width]="20" [height]="20"></app-jj-icons>
                    <app-jj-icons type="chat-dots" [pointer]="false" [color]="getOptinColor(50)" [width]="20" [height]="20"></app-jj-icons>
                </div>
            </div>
        </div>
    </div>
</div>