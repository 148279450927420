<div [ngClass]="{
    timelineWrapper : type === 1,
    timelineWrapperTypeTwo: type == 2 
}">

       <!-- dropdown -->
    <div class="combinedTimeline__dropdown" *ngIf="type == 2 && element?.length > 0">
        <div class="combinedTimeline__dropdown__wrapper" (click)="toggleResultView()">
            <div class="combinedTimeline__dropdown__header">
                {{ 'Timeline.Label.AllActivities' | texttransform }}  
                <span *ngIf="element.length > 0">({{element?.length}})</span>
            </div>
            <app-jj-icons type="caret-up" [width]="20" [height]="20" *ngIf="isResultViewExpanded && type == 2" ></app-jj-icons>
            <app-jj-icons type="caret-down" [width]="20" [height]="20" *ngIf="!isResultViewExpanded && type == 2 && element?.length > 0"></app-jj-icons>            
        </div>
    </div> 



    <div class="timelineHeader">
        <app-component-header [header]="'AccountFooter.Label.Activities' | texttransform" *ngIf="type != 2"></app-component-header>
        <div class="timelineHeader__controls" *ngIf="type === 1">
            <app-main-button (onClick)="newActivity()" svg="plus" type="small" *ngIf="type != 2" [label]="'AccountFooter.Label.NewActivity' | texttransform"></app-main-button>
            <app-jj-icons [type]="'sliders'" [width]="24" [height]="24" [tippy]="getSelectedFilter()" [tippyOptions]="{placement:'bottom'}"
                (click)="timelineService.filterPanelOpen[timelineId] = !timelineService.filterPanelOpen[timelineId]"></app-jj-icons>

            <app-jj-icons [type]="'arrows-counter-clockwise'" [width]="24" [height]="24" (click)="getData()"></app-jj-icons>
            <app-jj-icons [type]="'caret-down'" [width]="24" [height]="24" *ngIf="type != 2 && !isGlobalResultViewExpanded"
                [tippy]="'ActivityList.Label.ExpandAll' | texttransform" [tippyOptions]="{ placement:'bottom' }"
                (click)="isGlobalResultViewExpanded = !isGlobalResultViewExpanded"></app-jj-icons>
            <app-jj-icons [type]="'caret-up'" [width]="24" [height]="24" *ngIf="type != 2 && isGlobalResultViewExpanded"
                [tippy]="'ActivityList.Label.ExpandAll' | texttransform" [tippyOptions]="{ placement:'bottom' }"
                (click)="isGlobalResultViewExpanded = !isGlobalResultViewExpanded"></app-jj-icons>
                <!-- <i class="fas fa-lg fa-cog fingerCursor"
                (click)="timelineService.filterPanelOpen[timelineId] = !timelineService.filterPanelOpen[timelineId]" 
                [tippy]="getSelectedFilter()"
                [tippyOptions]="{placement:'bottom'}"
            ></i> -->
            <!-- <i class="fas fa-lg fa-sync fingerCursor" (click)="getData()"></i>
            <i *ngIf="type != 2" class="fas fa-lg fingerCursor " [tippy]="'ActivityList.Label.ExpandAll' | texttransform" [tippyOptions]="{ placement:'bottom' }" (click)="isGlobalResultViewExpanded = !isGlobalResultViewExpanded" [ngClass]="{
                'fa-chevron-down': !isGlobalResultViewExpanded,
                'fa-chevron-up': isGlobalResultViewExpanded
            }"></i>    -->
        </div>

        <div class="timelineHeader__controls__typeTwo" *ngIf="type == 2 && isResultViewExpanded">
            <app-jj-icons [type]="'sliders'" [width]="20" [height]="20" [tippy]="getSelectedFilter()" [tippyOptions]="{placement:'bottom'}"
                (click)="timelineService.filterPanelOpen[timelineId] = !timelineService.filterPanelOpen[timelineId]"></app-jj-icons>
            <app-jj-icons [type]="'arrows-counter-clockwise'" [width]="20" [height]="20" (click)="getData()"></app-jj-icons>
        </div>
    </div>

<div  class="timelineWrap " [ngClass]="{ 
    customTimeLineDarkBackground: type != 2 && customLeadStateMode == false,
    customLeadStateMode: customLeadStateMode == true
}">  
    <div class="combinedTimelineFilterPanelItems__list combinedTimelineFilterPanelItems__targetSelect" *ngIf="type == 1 && timelineService.filterPanelOpen[timelineId] == true">
        <div class="combinedTimelineFilterPanelItems__item">
            {{ 'AccountFooter.Label.AllActivities' | texttransform }}
            <app-jj-icons [type]="'toggle-left'" [width]="24" [height]="24" class="toggleItem" *ngIf="activityTarget==1" (click)="toggleActivityTarget()"></app-jj-icons>
            <app-jj-icons [type]="'toggle-right-fill'" [width]="24" [height]="24" class="toggleItem" *ngIf="activityTarget==2" (click)="toggleActivityTarget()"></app-jj-icons>        
            {{ 'AccountFooter.Label.OnlyForContact' | texttransform }}
        </div>
    </div>


    <div *ngIf="type == 1 && timelineService.filterPanelOpen[timelineId] == true">
        <div class="combinedTimelineFilterPanelItems">
            <div class="combinedTimelineFilterPanelItems__list">
                <div class="combinedTimelineFilterPanelItems__item" *ngFor="let activityFilterOption of timelineService.activityFilterOptions"                     
                    (click)="toggleSearch(activityFilterOption.identifier)">
                    <app-jj-icons [type]="'toggle-left'" [width]="24" [height]="24" class="toggleItem"
                        *ngIf="!commonService.checkIfItemIsInArray(timelineService.activityFilterType, activityFilterOption.identifier)"></app-jj-icons>
                    <app-jj-icons [type]="'toggle-right-fill'" [width]="24" [height]="24" class="toggleItem"
                        *ngIf="commonService.checkIfItemIsInArray(timelineService.activityFilterType, activityFilterOption.identifier)"></app-jj-icons>
                    {{ activityFilterOption.defaultName | texttransform }}
                </div>
            </div>
        </div>
        <div class="combinedTimelineFilterButtons">
            <button class="everleadCustomButton" (click)="timelineService.clearActivityFilter()">{{ 'Timeline.Label.SelectNone' | texttransform }}</button>
            <button class="everleadCustomButton" (click)="timelineService.resetActivityFilter()">{{ 'Timeline.Label.SelectAll' | texttransform }}</button>
            <button class="everleadCustomButton" (click)="setFilter()">{{ 'Timeline.Label.SetFilter' | texttransform }}</button>
        </div>
    </div>

    <div *ngIf="type == 2 && timelineService.filterPanelOpen[timelineId] == true">
        <div class="combinedTimelineFilterPanelItems">
            <div class="combinedTimelineFilterPanelItems__list">
                <div class="combinedTimelineFilterPanelItems__item" *ngFor="let activityFilterOption of timelineService.microcampaignFilterOptions" 
                    (click)="toggleSearch(activityFilterOption.identifier)">
                    <app-jj-icons [type]="'toggle-left'" [width]="12" [height]="12" (click)="getData()"
                        *ngIf="!commonService.checkIfItemIsInArray(timelineService.microcampaignFilterType, activityFilterOption.identifier)"></app-jj-icons>
                    <app-jj-icons [type]="'toggle-right-fill'" [width]="12" [height]="12" (click)="getData()" 
                        *ngIf="commonService.checkIfItemIsInArray(timelineService.microcampaignFilterType, activityFilterOption.identifier)"></app-jj-icons>
                    <!-- <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 40 }" class="combinedTimeline__header__filter__item__icon" 
                        *ngIf="commonService.checkIfItemIsInArray(timelineService.microcampaignFilterType, activityFilterOption.identifier)"></svg-icon>
                    <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 40 }" class="combinedTimeline__header__filter__item__icon" 
                        *ngIf="!commonService.checkIfItemIsInArray(timelineService.microcampaignFilterType, activityFilterOption.identifier)"></svg-icon> -->
                    {{ activityFilterOption.defaultName | texttransform }}
                </div>
            </div>   
            <div class="combinedTimelineFilterButtons">
                <button class="everleadCustomButton" (click)="timelineService.clearMicrocampaignFilter()">{{ 'Timeline.Label.SelectNone' | texttransform }}</button>
                <button class="everleadCustomButton" (click)="timelineService.resetMicrocampaignFilter()">{{ 'Timeline.Label.SelectAll' | texttransform }}</button>
                <button class="everleadCustomButton" (click)="setFilter()">{{ 'Timeline.Label.SetFilter' | texttransform }}</button>
            </div>     
        </div>
    </div>

    <div class="combinedTimeline__header__wrapper" *ngIf="(type == 1 || isResultViewExpanded && element.length > 0) && customLeadStateMode == false">
        <div class="combinedTimeline__header__agent">
            <div class="combinedTimeline__header__agent__name">
                {{ 'Timeline.Label.EverleadUser' | texttransform }}
            </div>
        </div>
        <div class="combinedTimeline__header__contact">
            <div class="combinedTimeline__header__contact__name">
                {{ 'Timeline.Label.Contact' | texttransform }}
            </div>
        </div>
    </div>
    
    <span *ngIf="loadingData==true" class="combinedTimeline__spinner">
        <br><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData' | texttransform}}<br>
    </span>



    <span id="timelineDesignSpan"></span>
    <div class="combinedTimeline__wrapper" *ngIf="loadingData==false && (isResultViewExpanded || type == 1)" (scroll)="checkBottom($event)"
        [style.height.px]="uiService.getDesignSpanPosition('timelineDesignSpan', 40)">
        <div class="smallSpacer">
            <div class="smallSpacer__left"></div>
            <div class="smallSpacer__right"></div>
        </div>
        <div #scrollContainer *ngFor="let item of element; let i = index" class="combinedTimeline__item" [ngClass]="{ 
            combinedTimeline__item__reverse: checkIfContactActivity(item),
            combinedTimeline__item__nonreverse: !checkIfContactActivity(item)            
        }">
            <div class="lastBookingForLeadstate" *ngIf="customLeadStateModeTarget == 'contact' && i == 0"><i class="fas fa-star"></i></div>
            <div class="combinedTimeline__item__data">
                <div class="timelineItemHeader__wrapper" [ngClass]="{ timelinePadding: i > 0 }">
                    <div class="timelineItemHeader">
                        <div class="timelineItemHeader__icon" *ngIf="checkIfContactActivity(item)">
                            <i [class]="getItemIcon(item, 'fas  ')"></i>
                        </div>
                        <div class="timelineItemHeader__text ellipsisText">
                            <div class="timelineItemHeader__text__step"> 
                                {{ item.taskStep | texttransform  }} 
                                {{ item.taskStepName | texttransform  }}                            
                                {{ item.conversionAssetName | texttransform  }}
                                <ng-container *ngIf="item.eventType == 'pageView'"><a [href]=item.eventUrl
                                        [tippy]=item.eventUrl [tippyOptions]="{maxWidth: '80rem'}" target="_new">{{ getModifiedDemoUrl(item.eventUrl) }}</a></ng-container>
                            </div>
                            |
                            <div class="timelineItemHeader__text__result">
                                <div class="ellipsisText" *ngIf="item.taskTempResultId != null">
                                    {{ commonService.getTranslationValueFromArray(getQMResultName(item.taskTempResultId)) | texttransform }}
                                </div>
                                <div class="ellipsisText" *ngIf="item.taskTempResultId == null">                            
        
                                    <span [tippy]="item.taskResult">{{ item.taskResult | texttransform }}</span>
                                    {{ item.resultName | texttransform  }}                            
                                    <span *ngIf="isValid(item.eventType) && !isValid(item.maileventType)">{{ item.eventType }}</span>
                                    <span *ngIf="isValid(item.maileventType)">{{getEventTranslation(item.maileventType)}}</span>                                                        
                                    <span *ngIf="item.mailDirection == 'Outgoing'">{{ 'Timeline.Label.OutgoingEMail' | texttransform }}</span>
                                    <span *ngIf="item.mailDirection == 'Incoming'">{{ 'Timeline.Label.IncomingEMail' | texttransform }}</span>                            
                                    {{ item.conversionAssetType | texttransform  }}
                                </div>
                            </div>
                        </div>
                        <div class="timelineItemHeader__icon" *ngIf="!checkIfContactActivity(item)">
                            <i [class]="getItemIcon(item, 'fas  ')"></i>
                        </div>
                    </div>
                </div>

                <div class="timelineItemDate">
                    <div class="timelineItemDate__date">
                        {{ item.date | date: 'mediumDate'}}{{ item.followUpDate | date: 'mediumDate'}} | {{ item.date | date: 'shortTime'}} {{ item.followUpDate | date: 'shortTime'}}
                    </div>                    
                    <div class="timelineItemDate__history">
                        (<span *ngIf="type == 1">{{getTimeDifferenceString(item.date)}}</span><span *ngIf="type == 2">{{getTimeDifferenceString(item.followUpDate)}}</span>)
                    </div>
                </div>

                <!-- Direkt sichtbare Werte -->
                <div class="timelineItemInfoValues">
                    <div class="timelineItemInfoValues__values">
                        <div *ngIf="customLeadStateModeTarget == 'contact' && i == 0" class="infoLine">
                            <div class="ellipsisText">{{ 'Task.Label.ThisBookingDefinedLeadstate' | texttransform }}</div>                            
                        </div>  
    
                        <div *ngIf="item.taskTempResultId != null" class="combinedTimeline__item__data__content__infoline combinedTimelineNeedQM">
                            {{ 'Timeline.Label.RequiresQM' | texttransform }}
                        </div>
                        <div *ngIf="applicationInfoService.isDeveloper">
                            <app-custom-timeline-item itemLabel="id" [itemValue]="item.id" [tippy]="item | json"></app-custom-timeline-item>
                            <app-custom-timeline-item [itemLabel]="'ActivityList.Label.TaskChainGuid'" [itemValue]="item.taskChainGuid"></app-custom-timeline-item>
                            <app-custom-timeline-item [itemLabel]="'ActivityList.Label.TaskFollowUpTaskId'" [itemValue]="item.taskFollowUpTaskId"></app-custom-timeline-item>    
                        </div>
                        <app-custom-timeline-item [itemLabel]="'ActivityList.Label.LeadState'" [itemValue]="item.taskLeadStatus"></app-custom-timeline-item>    
                        <app-custom-timeline-item [itemLabel]="'ActivityList.Label.Contact'" [itemValue]="item.taskContact"></app-custom-timeline-item>    
                        <app-custom-timeline-item [itemLabel]="'Timeline.Label.TaskResultUser'" [itemValue]="item.taskResultUser"></app-custom-timeline-item>
                        <app-custom-timeline-item [itemLabel]="'Timeline.Label.ReferringURL'" [itemValue]="item.eventReferringUrl"></app-custom-timeline-item>    
                        <app-custom-timeline-item [itemLabel]="'Timeline.Label.MailEventData'" [itemValue]="item.mailEventData" [isJson]="true"></app-custom-timeline-item>                           
                        <app-custom-timeline-item [itemLabel]="'ActivityList.Label.ConversionUrl'" [itemValue]="item.conversionUrl"></app-custom-timeline-item>   
                        <app-custom-timeline-item [itemLabel]="'ActivityList.Label.Url'" [itemValue]="item.url" [isLink]="true"></app-custom-timeline-item>                           
                    </div>

                    <div class="timelineItemInfoValues__edit">
                        <app-jj-icons [type]="'pencil-simple-line'" [width]="24" [height]="24" (click)="editActivity(item)" *ngIf="canEditTask(item) && item.taskTempResultId == null"></app-jj-icons>
                        <app-jj-icons [type]="'pencil-simple-line'" [width]="24" [height]="24" (click)="editConversion(item)" *ngIf="item.type=='conversion'"></app-jj-icons>
                    </div>                                         
                </div>

                <!-- Weitere Infos -->
                <div class="timelineItemMoreInfoValues" *ngIf="commonService.checkIfItemIsInArray(expandedItems, item.id) || isGlobalResultViewExpanded">
                    <app-custom-timeline-item [itemLabel]="'ActivityList.Label.Process'" [itemValue]="item.taskWorkflow"></app-custom-timeline-item>    
                    <app-custom-timeline-item [itemLabel]="'ActivityList.Label.Process'" [itemValue]="item.workflowName"></app-custom-timeline-item>    
                    <app-custom-timeline-item [itemLabel]="'ActivityList.Label.Campaign'" [itemValue]="item.taskCampaign"></app-custom-timeline-item>    
                    <app-custom-timeline-item [itemLabel]="'ActivityList.Label.Campaign'" [itemValue]="item.campaignName"></app-custom-timeline-item>    
                    <app-custom-timeline-item [itemLabel]="'General.Label.Objections'" [itemValue]="cleanTaskAnswers(item.taskAnswers)"></app-custom-timeline-item>    
                    <app-custom-timeline-item [itemLabel]="'ActivityList.Label.Reason'" [itemValue]="item.taskAnswerText"></app-custom-timeline-item>    
                    <app-custom-timeline-item [itemLabel]="'Task.Label.Subject'" [itemValue]="item.taskSubject"></app-custom-timeline-item>    
                    <app-custom-timeline-item [itemLabel]="'Task.Label.Notes'" [itemValue]="item.taskNotes" [isNotes]="true"></app-custom-timeline-item>                        
                    <app-custom-timeline-item [itemLabel]="'ActivityList.Label.Data'" [itemValue]="item.data" [isItemData]="true" [item]="item"></app-custom-timeline-item>    
                </div>
                
                <!-- Aufklappbar? -->
                <div class="timelineItemMore" *ngIf=" item.type=='task' || 
                    item.type=='conversion' || 
                    item.resultSource == 'agent' || 
                    dataArrayItem(item) == true"
                    (click)="commonService.toggleArrayItem(expandedItems, item.id)">                    
                    <div class="timelineItemMore__text" *ngIf="commonService.checkIfItemIsInArray(expandedItems, item.id) || isGlobalResultViewExpanded">{{ 'Timeline.Label.LessInfos' | texttransform }}</div>
                    <div class="timelineItemMore__text" *ngIf="!commonService.checkIfItemIsInArray(expandedItems, item.id) && !isGlobalResultViewExpanded">{{'Timeline.Label.MoreInfos' | texttransform }}</div>
                    <div class="timelineItemMore__icon">
                        <app-jj-icons [type]="'caret-down'" [width]="24" [height]="24" *ngIf="!commonService.checkIfItemIsInArray(expandedItems, item.id) && !isGlobalResultViewExpanded"></app-jj-icons>
                        <app-jj-icons [type]="'caret-up'" [width]="24" [height]="24" *ngIf="commonService.checkIfItemIsInArray(expandedItems, item.id) || isGlobalResultViewExpanded"></app-jj-icons>
                    </div>                    
                </div>                
            </div>
            <div class="combinedTimeline__item__empty"></div>
        </div>
        <div class="lazyLoadingDataSpinner">
            <span *ngIf="lazyLoadingData==true" class="combinedTimeline__spinner loading-lazy-results">
                <br><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData' | texttransform}}<br>
            </span>    
        </div>
    </div>

    <hr *ngIf="element?.length === 0 && type !== 2">
</div>

</div>