import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-echarts',
  templateUrl: './echarts.component.html',
  styleUrls: ['./echarts.component.scss']
})
export class EchartsComponent implements OnInit , OnDestroy{
  constructor(
    private axivasTranslateService: AxivasTranslateService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private commonService: CommonService,
  ) { }
  customEventSubscription: Subscription = new Subscription();
  @Input() chartData: any;
  @Input() options: EChartsOption;
  @Input() controlid: string = "";
  @Input() chart: string;
  @Input() showAsCurrency = false;

  //  * general  component config
  @Input() header: string = '';
  @Input() title: string = '';
  @Input() titlePosition: {};
  @Input() legend: boolean;
  @Input() legendPosition: {};
  @Input() tooltip: boolean;
  @Input() tooltipPosition: 'inside';
  @Input() tooltipConfine: boolean = true;
 // * colors and Heigh for Chart component
  @Input() height;
  @Input() darkmode: boolean;
  @Input() headercolor = '#FBFBFB';
  @Input() fontColor: string = '#333333';
  @Input() backgroundColor:string ='#FFFFFF'
/*   @Input() colors = [
    '#ED5C73', '#F07E26','#80B800', '#59AA8E', '#AEA7B0', '#3EA4C3', '#57518E', '#346188', '#1B3A55', '#1D1645',
    '#ED5C73', '#F07E26','#80B800', '#59AA8E', '#AEA7B0', '#3EA4C3', '#57518E', '#346188', '#1B3A55',
  ];
 */
  @Input() colors = [
    '#5465FF', '#788BFF','#9BB1FF', '#BFD7FF', '#E2FDFF', '#3EA4C3', '#57518E', '#346188', '#1B3A55', '#1D1645',   
  ];
  @Input() noFunnelBackground = false;

  // * Pie Chart basic config
  @Input() pieSize: number;
  @Input() piePosition: string[];
  @Input() startAngle: number;
  @Input() clockwise: boolean = false

  // * Bars Chart basic config
  @Input() barWidth: number
  @Input() barMaxWidth: number;
  @Input() barGap: number = 0;
  @Input() barLabel={};
  @Input() barColors=[];

  // * X - Axis
  @Input() showXaxis:boolean = true;
  @Input() xAxisType;
  @Input() xAxisLabel = {};

  // * Y - Axis
  @Input() showYaxis:boolean = true;
  @Input() yAxisType;
  @Input() yAxisLabel= {};


  // * FUNNEL CHART BASIC CONFIG

  @Input() sort: string;
  @Input() orient: string;
  @Input() funnelAlign;
  @Input() gap: number;
  @Input() funnelWidth: string = '100%';
  @Input() funnelHeight: string;
  @Input() minSize:string;
  @Input() maxSize: string;
  @Input() min:number;
  @Input() max:number;
  @Input() funnelLabelPosition;
  @Input() funnelBorderColor: string = 'transparent';

  eventId = ['updatePieChart', 'updateBarChart', 'updateFunnelChart']
  dataAvailable: boolean = false
  customConfig: any;


  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (this.eventId.includes(event.id) && event.value == this.controlid) {
        this.chartData = event.chartData;
        this.chartData.data = this.adjustData()
        //console.log('this chart Data custom event', this.chartData.data);
        this.createChart();
      }
    });

    if (this.controlid == '') {
      this.controlid = `Chart_`.concat(this.commonService.createGuid());
    }

    //console.log('this chart from element', this.chartData);

    this.chartData.data = this.adjustData()
    this.createChart();

    //console.log('this chart Data outside', this.chartData.data);
  }

  getHeaderLabelId() {
    return this.controlid + 'headerLabel';
  }

  adjustData() {
    let data = this.chartData.data;
    switch (this.chartData.type) {
      case 'bar':
        return data.map((item, index) => {
          if (item.stepId) {
            return {
              ...item,
              stepId: this.getStepName(item.stepId),
            }
          }
          if(item.name){
            return {
              ...item,
              name: this.axivasTranslateService.getTranslationTextForToken(item.name),
            }
          }
        })
      case 'pie':
        let totalAmount= 0;
        let dataArray;
        for (let i = 0; i < data.length; i++){
          totalAmount += data[i].value;
          if (data[i].value > 0) {
            dataArray = data.map((item): any => {
                  return {
                    name: this.axivasTranslateService.getTranslationTextForToken(item.name).concat(` (${item.value}) : ${this.getPercentage(item.value, totalAmount)}%`),
                    value: this.getPercentage(item.value, totalAmount)
                  }

            })
          }
        }
        return dataArray

      case 'funnel' :
        return data.map((item)=>{
          return{
            value:  item.value,
            name: this.axivasTranslateService.getTranslationTextForToken(item.name),
          }
        })
      default:
        return data;
    }

  }

  createChart() {
    if (this.commonService.isNullOrUndefined(this.chartData)) {
      return;
    }

    if (this.chartData.data.length > 0) {
      this.dataAvailable = true
    } else {
      this.dataAvailable = false
      return;
    }

    if (this.darkmode) {
      this.fontColor = '#333333'
      this.backgroundColor = '#FFFFFF'
      // this.backgroundColor = 'transparent';
    }

    switch (this.chartData.type) {
      case 'bar':
        this.customConfig = this.createBar()
        break;
      case 'pie':
        this.customConfig = this.createPie()
        break;
      case 'funnel':
        this.customConfig = this.createFunnel()
    }

    //console.log('this.customConfig', this.customConfig);

    this.options = {
      ...this.customConfig,
      backgroundColor: this.backgroundColor,
      color: this.colors,
      textStyle: {
        fontFamily:'Poppins',
        color: this.fontColor,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
    }

    if (this.title) {
      this.options.title = {
        left: 'center',
        text: this.axivasTranslateService.getTranslationTextForToken(this.title),
        ...this.titlePosition,
        textStyle: {
          fontFamily: 'Poppins',
          fontSize: 17,
          color: this.fontColor
        }
      }
    }

    if (this.legend) {
      this.options.legend={
          ...this.legendPosition,
          textStyle: {
            fontFamily: 'Poppins',
            fontSize: 12,
            color: this.fontColor,
          },
          itemStyle:{
            borderColor:'transparent',
          }
        }
    }

    if (this.tooltip && this.chartData.type !== 'pie' && this.chartData.type !== 'funnel') {
      this.options.tooltip={}
    }

    if (this.chartData.type === 'bar') {
      this.options.xAxis = {
        type: this.xAxisType ? this.xAxisType : 'category',
        show: this.showXaxis,
        axisLabel: {
          ...this.xAxisLabel,
          color: this.fontColor,
        },
        axisLine: {
          ...this.yAxisLabel,
          lineStyle: { color: this.fontColor}
        },
      }
    }

    if (this.chartData.type === 'bar') {
      this.options.yAxis = {
        type: this.yAxisType ? this.yAxisType : 'value',
        show: this.showYaxis,
        axisLabel: {
          ...this.yAxisLabel,
          color: this.darkmode ? '#FFFFFF' : ''
        },
      }
    }

    //console.log('OPTIONS', this.options)
  }


  // ! CREATE BAR ->
  createBar() {
    if (this.chartData.categories) {
      return this.createBarDataSetSeries()
    } else {
      return this.createBarSeries()
    }
  }


  createBarDataSetSeries() {
    let source = [['product']]
    this.chartData.data.map((item) => {
      source.push(Object.values(item))
     })


    let series = this.chartData.categories.map((item, i) => {
      source[0].push(this.axivasTranslateService.getTranslationTextForToken(item))
      let colorIndex = i % this.barColors.length;

      //console.log(item);

      if(this.barLabel){
        return {
          name: this.axivasTranslateService.getTranslationTextForToken(item),
          type: 'bar',
          itemStyle:{
            color:this.barColors ? JSON.stringify(this.barColors[colorIndex]) : undefined,
          },
          emphasis: {
            focus: 'series'
          },
          barWidth: this.barWidth ? `${this.barWidth}%` : '10%',
          barMaxWidth: this.barMaxWidth ? `${this.barMaxWidth}%` : `${this.barWidth}%`,
          label: {
            show: true,
            position: 'insideBottom',
            distance: 15,
            align: 'left',
            verticalAlign: 'middle',
            rotate: 90,
            fontSize: 15,
            color: this.fontColor,
            ...this.barLabel,
            rich: {
              name: {}
            },
            ...this.barLabel
          }
        }
      } else {
      return {
        name: this.axivasTranslateService.getTranslationTextForToken(item),
        type: 'bar',
        itemStyle:{
          color:this.barColors ? JSON.stringify(this.barColors[colorIndex]) : undefined,
        },
        emphasis: {
          focus: 'series'
        },
        barWidth: this.barWidth ? `${this.barWidth}%` : '10%',
        barMaxWidth: this.barMaxWidth ? `${this.barMaxWidth}%` : `${this.barWidth}%`,

      }
    }
    })

    //console.log('source', source);

    return { series: series, dataset: { source: source } }
  }


  createBarSeries() {
    let series = this.chartData.data.map((item, i) => {

      if(this.barLabel){
        return {
          name: item.name,
          data: [item.value],
          type: 'bar',
          barWidth: this.barWidth ? `${this.barWidth}%` : '5%',
          barMaxWidth: this.barMaxWidth ? `${this.barMaxWidth}%` : `${this.barWidth}%`,
          barGap: this.barGap,
          emphasis: {
            focus: 'series'
          },
          itemStyle:{
            color: item.color ? item.color : undefined,
          },
          label: {
            show: true,
            position: 'top',
            distance: 15,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 0,
            fontSize: 15,
            color: this.fontColor,
            ...this.barLabel,
            rich: {
              name: {}
            },
            ...this.barLabel,
          }
        }
      } else {
        return {
          name: item.name,
          data: [item.value],
          type: 'bar',
          barWidth: this.barWidth ? `${this.barWidth}%` : '5%',
          barMaxWidth: this.barMaxWidth ? `${this.barMaxWidth}%` : `${this.barWidth}%`,
          barGap: this.barGap,
          emphasis: {
            focus: 'series'
          },
        }
      }
    })

  /*   let seriesBarLabel
    if (this.barLabel) {
     seriesBarLabel = series.map((item) => {
        return {
          ...item,
          label: {
            show: true,
            position: 'top',
            distance: 15,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 0,
            fontSize: 15,
            color: this.fontColor,
            ...this.barLabel,
            rich: {
              name: {}
            }
          }
        }
      })
    } */

    return { series: series }
  }


  // ! CREATE PIE

  createPie() {
    if (this.chartData.categories) {
      return this.createPieDataSetSeries()
    } else {
      return this.createPieSeries()
    }
  }

  createPieSeries() {
    let series = [
      {
        name: '',
        label: { color: this.fontColor },
        type: 'pie',
        radius: this.pieSize ? `${this.pieSize}%` :'50%',
        center: this.piePosition ? this.piePosition : ['50%','55%'],
        data: this.chartData.data,
        startAngle: this.startAngle ? this.startAngle : 90,
        clockwise: this.clockwise
      }]

    let label ={ color: this.fontColor }

    let tooltip = {
      trigger: 'item',
      formatter: '{b}',
      position: this.tooltipPosition,
      textStyle: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
      },
      confine: this.tooltipConfine,
      axisPointer: {
        crossStyle: {
          textStyle: {
            fontFamily: 'Poppins',
          }
        }
      }
    }
    return { series: series , tooltip: tooltip, label: label}
  }

  createPieDataSetSeries() {
  }



  // ! CREATE FUNNEL
  createFunnel() {

    let tooltip={
      show: false,
      trigger: 'item',
      formatter: '{b} : {c}',
      confine: this.tooltipConfine,
    };

    let series = [];
    this.max = 0;
    this.chartData.data.forEach(dataItem => {
      if (dataItem.value > this.max) {
        this.max = dataItem.value;
      }
    });
    const mainSeries = {
        type: "funnel",
        name: 'forefrontChart',
        data: this.chartData.data,
        min: this.min ? this.min : 0,
        max: this.max ? this.max : 100,
        minSize: this.minSize ? this.minSize : '0%',
        maxSize: this.maxSize ? this.maxSize : '100%',
        sort: this.sort ? this.sort :  'descending',
        orient: this.orient ? this.orient : 'vertical',
        gap: this.gap ? this.gap : 4,
        width: this.funnelWidth ? this.funnelWidth : null,
        height:  this.funnelHeight ? this.funnelHeight :  null,
        legendHoverLink: true,
        top: 10,
        left: 2,
        z: 100,
        bottom: 10,
        funnelAlign: this.funnelAlign ?  this.funnelAlign :"center",
        label:{
          ...this.funnelLabelPosition,
          formatter: this.showAsCurrency == true ? 
            function (params) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0
              });
              return params.name.concat(' ', formatter.format(params.value));
            }
            : '{b} ({c})',
          show: true,
           textBorderWidth:0,
          textBorderColor:  'transparent'
        },
        itemStyle:{
          borderColor: this.funnelBorderColor ? this.funnelBorderColor :'transparent',
        }
      }
    series.push(mainSeries);

    const backGroundData = JSON.parse(JSON.stringify(this.chartData.data));
    let count = 0;
    backGroundData.forEach(dataItem => {
      dataItem.value = (((this.max / backGroundData.length) * (backGroundData.length - count)));
      if (backGroundData.length > 3 && count == backGroundData.length - 2) {
        dataItem.value = (dataItem.value / 10) * 6;
      }
      count ++;
    });
    //console.warn(this.min)
    const backgroundSeries = {
        type: "funnel",
        name: 'backgroundChart',
        data: backGroundData,
        min: this.min ? this.min : 0,
        max: this.max ? this.max : 100,
        minSize: this.minSize ? this.minSize : '0%',
        maxSize: this.maxSize ? this.maxSize : '100%',
        sort: this.sort ? this.sort :  'descending',
        orient: this.orient ? this.orient : 'vertical',
        gap: this.gap ? this.gap : 4,
        width: this.funnelWidth ? this.funnelWidth : null,
        height:  this.funnelHeight ? this.funnelHeight :  null,
        legendHoverLink: false,
        top: 10,
        left: 2,
        bottom: 10,
        funnelAlign: this.funnelAlign ?  this.funnelAlign :"center",
        label:{
          ...this.funnelLabelPosition,
          formatter: '',
          show: false,
          textBorderWidth:0,
          textBorderColor:  'transparent',
        },
        itemStyle:{
          borderColor: this.funnelBorderColor ?
          this.funnelBorderColor :'transparent',
          opacity: 0.3
        },
    }
    if (this.noFunnelBackground = false) {
      series.push(backgroundSeries);
    }    

    return { series: series.reverse(), tooltip: tooltip}
  }




  // ! GENERAL UTILS FOR CHARTS

  getStepName(stepId) {
    const step = this.applicationInfoService.steps.toArray().find(step => step.id == stepId);
    if (step) {
      return this.axivasTranslateService.getTranslationTextForToken(this.commonService.getTranslationValueFromArray(step));
    } else {
      return '';
    }
  }

  getPercentage(value: number, totalAmount: number) {
    let returnValue = null;
    if (value != 0) {
      returnValue = ((value * 100) / totalAmount);
      returnValue = (Math.round(returnValue * 100) / 100).toFixed(1)
    } else {
      returnValue = 0;
    }
    return returnValue;
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getHeight() {
    if (isNaN(this.height)) {
      return this.height;
    } else {
      return this.height.toString().concat('px');
    }
  }
}
