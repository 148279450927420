<span id="newAccountDesignSpan"></span>
<div class="newAccount__wrapper" [style.max-height.px]="getMaximumHeightOfSubContainer('newAccountDesignSpan')">
    <div class="newAccount__controls" [innerHTML]="newAccountContainerInnerHtml"></div>    
    
    <div class="newAccount__search" [ngClass]="{ hideInDom: accountsSearched == false }">
        <div [innerHTML]="newAccountContainerListInnerHtml"></div>
    </div>    

    <div class="newAccount__buttons" id="newAccount__buttons">
        <app-main-button
        *ngIf="accountsSearched == false && searchOption == true" 
        (onClick)="createSearchOrNewAccount();$event.stopPropagation()" 
        [disabled]="poolId == null"
        title="{{ 'NewAccount.Label.CreateNewAccount' | texttransform }}"
        [label]="newAccountSearchText | texttransform"></app-main-button>

         
        <app-main-button 
        *ngIf="accountsSearched == true || searchOption == false" (onClick)="createNewAccount();$event.stopPropagation()" 
        [disabled]="poolId == null"
        title="{{ 'NewAccount.Label.CreateNewAccount' | texttransform }}"
        [label]="newAccountText | texttransform "></app-main-button>


        <app-main-button (onClick)="createNewAccount(true);$event.stopPropagation()"
            *ngIf="(assignContactAfterCreation == false && accountsSearched == true) || searchOption == false" 
            [disabled]="poolId == null" 
            title="{{ 'NewAccount.Label.CreateNewAccountAndEnter' | texttransform }}"
            [label]="newAccountEnterText | texttransform"></app-main-button>

        
        

<!-- 
        <!-- <button  *ngIf="accountsSearched == false && searchOption == true" (click)="createSearchOrNewAccount();$event.stopPropagation()" 
        class="everleadCustomButton" [disabled]="poolId == null"
            title="{{ 'NewAccount.Label.CreateNewAccount' | texttransform }}">{{ newAccountSearchText | texttransform }}</button> -->
        
       <!--  
            <button *ngIf="accountsSearched == true || searchOption == false" (click)="createNewAccount();$event.stopPropagation()" 
            class="everleadCustomButton" [disabled]="poolId == null"
            title="{{ 'NewAccount.Label.CreateNewAccount' | texttransform }}">{{ newAccountText | texttransform }}</button>
        <button (click)="createNewAccount(true);$event.stopPropagation()" class="everleadCustomButton" 
            [disabled]="poolId == null" 
            *ngIf="(assignContactAfterCreation == false && accountsSearched == true) || searchOption == false"
            title="{{ 'NewAccount.Label.CreateNewAccountAndEnter' | texttransform }}">{{ newAccountEnterText | texttransform }}</button> -->
    </div>
</div>