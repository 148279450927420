<div style="width: 100%" class="controlInlineBlock">
  <div *ngIf="getElementInfo()"></div>
  <span *ngIf="controlType==1">
    <!-- Label -->
  </span>

  <span *ngIf="controlType==2">
    <!-- Checkbox -->
<!--     <input [(ngModel)]="filterParameter" type="checkbox" (change)="changeFilter()" class="jjDesignBorder"
      [disabled]="disabled" style="width: 20px"> -->
     <app-checkbox [(ngModel)]="filterParameter" (valueChange)="changeFilter()" [disabled]="disabled"></app-checkbox>
  </span>

  <span *ngIf="controlType==3">
    <!-- Dropdown -->
    <app-extended-dropdown stylye="width=100%; height=25px;" [data]="data" (onchanged)="changeFilter($event)"
      [disabled]="disabled" [controlValue]="filterParameter" [dataKey]="memberDetails.externalDataSource?.dataKey"
      [dataValue]="memberDetails.externalDataSource?.dataValue"></app-extended-dropdown>
  </span>

  <span *ngIf="controlType==4">
    <!-- Text -->
     <app-text-input [(ngModel)]="filterParameter" (valueChange)="changeFilter()" [disabled]="disabled"></app-text-input>
<!--     <input [(ngModel)]="filterParameter" (change)="changeFilter()" class="jjDesignBorder myLeadsModeInlineEditElement"
      [disabled]="disabled" style="width: 100%"> -->
      <app-text-input [(ngModel)]="filterParameter2" *ngIf="showParameter2" (valueChange)="changeFilter()" [disabled]="showParameter2"></app-text-input>
 <!--    <input [(ngModel)]="filterParameter2" (change)="changeFilter()" *ngIf="showParameter2"
      class="jjDesignBorder myLeadsModeInlineEditElement" [disabled]="disabled" style="width: 100%"> -->
  </span>

  <span *ngIf="controlType==5">
    <!-- Date -->
    <input [(ngModel)]="filterParameter" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" readonly style="width: 25%"
      appendTo="body" aria-modal="true" class="inline jjDesignBorder" (dateTimeChange)="changeFilter()">
    <owl-date-time #dt7 class="inline"></owl-date-time>
    <label style="width: 10%; text-align: center;" class="inline" *ngIf="showParameter2">-</label>
    <input [(ngModel)]="filterParameter2" [owlDateTimeTrigger]="dt8" [owlDateTime]="dt8" readonly style="width: 25%"
      *ngIf="showParameter2" appendTo="body" aria-modal="true" class="inline jjDesignBorder"
      (dateTimeChange)="changeFilter()">
    <owl-date-time #dt8 class="inline floatRight"></owl-date-time>
  </span>

  <span *ngIf="controlType==6">
    <!-- Number -->
     <app-number-input [(ngModel)]="filterParameter" (valueChange)="changeFilter()" [disabled]="disabled"></app-number-input>
<!--     <input [(ngModel)]="filterParameter" type="number" class="jjDesignBorder" style="width: 45%" [disabled]="disabled"
      (change)="changeFilter()"> -->
    <app-number-input *ngIf="showParameter2" [(ngModel)]="filterParameter2" [disabled]="disabled" (valueChange)="changeFilter()" label="-"></app-number-input>
   <!--  <label style="width: 10%; text-align: center;" class="inline" *ngIf="showParameter2">-</label>
    <input [(ngModel)]="filterParameter2" type="number" class="jjDesignBorder" style="width: 45%" [disabled]="disabled"
      *ngIf="showParameter2" (change)="changeFilter()"> -->
  </span>

  <span *ngIf="controlType==99">
    <label style="width: 100%">{{ 'DataFilter.Label.CurrentlyNotSupported' | texttransform }}</label>
  </span>
</div>