import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { MessagePosition, MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-toolbar-phone',
  templateUrl: './toolbar-phone.component.html',
  styleUrls: ['./toolbar-phone.component.scss']
})
export class ToolbarPhoneComponent implements OnInit {
  phoneItems = [];
  mode = 1;
  numberToCall = '';
  adressBook = null;
  callHistory = null;
  filterString = '';

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public ringcentralwebphoneService: RingcentralwebphoneService,
    public commonService: CommonService,
    private uiService: UiService,
    private messagingService: MessagingService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getPhoneItems();
    this.getRingCentralData();

    if (this.commonService.checkApplicationRoute('account') != true) {
      this.mode = 2;
    }

    //console.log('phone nav' , this.mode);
  }  

  getRingCentralData() {
    this.ringcentralwebphoneService.getAdressBook().then(getAdressbookResult => {        
      getAdressbookResult.records.forEach(getAdressbookResultItem => {
        getAdressbookResultItem['extension'] = getAdressbookResultItem.extensionNumber;
        if (getAdressbookResultItem.phoneNumbers) {
          if (getAdressbookResultItem.phoneNumbers.length > 0) {
            getAdressbookResultItem['phoneNumber'] = getAdressbookResultItem.phoneNumbers[0].formattedPhoneNumber;
          }            
        }
      })

      this.adressBook = getAdressbookResult.records.sort((a:any, b:any) => a.lastName < b.lastName ? -1 : 1);
    });    
    this.ringcentralwebphoneService.getRecentCalls().then(getRecentCallsResult => {
      getRecentCallsResult.records.forEach(getRecentCallsResultItem => {
        if (getRecentCallsResultItem.direction == 'Outbound') {
          getRecentCallsResultItem['phoneNumber'] = getRecentCallsResultItem.to.phoneNumber;
        } else {
          getRecentCallsResultItem['phoneNumber'] = getRecentCallsResultItem.from.phoneNumber;
        }
        getRecentCallsResultItem['fromNumber'] = getRecentCallsResultItem.from.phoneNumber;
        getRecentCallsResultItem['toNumber'] = getRecentCallsResultItem.to.phoneNumber;
        getRecentCallsResultItem['start'] = getRecentCallsResultItem.startTime;
        getRecentCallsResultItem['durationFormatted'] = this.commonService.getDurationString(getRecentCallsResultItem.duration);          
      });
      this.callHistory = getRecentCallsResult.records;
    });

  }

  getPhoneItems() {
    this.phoneItems = [];
    const dialItems = this.applicationInfoService.controls.toArray().filter(control => 
      control.controlType == 9 && 
      (control.uiEntityInstanceId == 1 || control.uiEntityInstanceId == 4) &&
      control.isActive == true
    );
    dialItems.sort((a, b) => a.uiEntityInstanceId < b.uiEntityInstanceId ? -1 : 1);
    dialItems.forEach(dialItem => {
      const foundItem = this.phoneItems.find(phoneItem => phoneItem.value == dialItem.value);
      if (!foundItem) {
        this.phoneItems.push({
          displayText: dialItem.displayText,
          entityId: dialItem.uiEntityInstanceId,
          value: dialItem.value
        })
      }
    });   
  }

  makeCall(number) {
    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true' && !this.applicationInfoService.isDeveloper) {
      this.messagingService.showDefaultWarning('', 'Toolbar.Label.NotLoggedInDemo');
      return;
    }
    this.ringcentralwebphoneService.makeCall(number);
    this.eventService.customEvent.emit({ id: 'closeToolbarPopup' });
  }
  
  getPhoneValue(phoneItem) {
    let phoneNumber = this.applicationInfoService.entities.Item(phoneItem.entityId).data[phoneItem.value];
    if (this.commonService.isNullOrUndefined(phoneNumber)) {
      phoneNumber = '';
    }
    return phoneNumber;
  }

  getHeight(elementId, substractValue) {
    if (this.applicationInfoService.isMobile()) {
      return Number(this.uiService.getDesignSpanPositionMobile(elementId, substractValue - 10));
    } else {
      return Number(this.uiService.getDesignSpanPosition(elementId, substractValue));          
    }                
  }

  isItemFiltered(adressBookItem) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(adressBookItem.firstName, this.filterString)) {
      returnValue = false;
    }
    if (this.commonService.checkIfStringContainsString(adressBookItem.lastName, this.filterString)) {
      returnValue = false;
    }
    if (this.commonService.checkIfStringContainsString(adressBookItem.extension, this.filterString)) {
      returnValue = false;
    }
    if (this.commonService.checkIfStringContainsString(adressBookItem.phoneNumber, this.filterString)) {
      returnValue = false;
    }

    return returnValue;
  }
}
