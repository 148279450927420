<div class="componentLayout">
  <app-component-header *ngIf="header && !locked" [header]="header" [ellipsisHeader]="ellipsisHeader"></app-component-header>
  
  <div class="componentLayout__header" *ngIf="header && locked">
    <app-component-header *ngIf="header" [header]="header" [ellipsisHeader]="ellipsisHeader"></app-component-header>
    <app-jj-icons type="lock-simple-fill" color="red" [width]="35" [height]="35" [ngxTippy]="tooltipLockAccount" [tippyProps]="tippyLockProps"></app-jj-icons>
  </div>
  
  <div class="componentLayout__wrapper">
    <div class="componentLayoutContent">
      <ng-content class="maxHeight"></ng-content>
    </div>
  </div>
</div>


<div #tooltipLockAccount >
  <div class="accountLocked" *ngIf="locked">
    <div class="accountLocked__header">
      <app-jj-icons type="lock-simple-fill" color="red" [width]="30" [height]="30"></app-jj-icons>
      {{ 'Account.Locked.MessageHeader' | texttransform }}
    </div>
    <div class="accountLocked__body">
      {{ 'Account.Locked.Message' | texttransform }}
    </div>
    <div class="accountLocked__info">
      <div class="accountLocked__info__line">
        <div class="accountLocked__info__line__label">{{ 'Account.Locked.LockUser' | texttransform }}</div>
       <div class="accountLocked__info__line__value">{{ applicationInfoService.lockUser }}</div>
      </div>
      <div class="accountLocked__info__line">
        <div class="accountLocked__info__line__label">{{ 'Account.Locked.LockUntil' | texttransform }}</div>
       <div class="accountLocked__info__line__value">{{ applicationInfoService.accountLockInformation?.lockTill |
          date: 'medium' }}</div>
      </div>
    </div>
 </div>
</div>


