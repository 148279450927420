import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent implements OnInit{


  @Input() progress: number | null = null;

  barprogress=0;


  ngOnInit(): void {
    console.log('progress', this.progress);

    if(this.progress){
      this.startProgress()
    }


  }

  startProgress(){
    const interval = setInterval(() => {
      if(this.barprogress < this.progress){
        this.barprogress +=1;
      } else {
        clearInterval(interval)
      }
    }, 10);
  }

}
