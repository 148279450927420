import { Component, Input, OnInit } from '@angular/core';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { SettingsService } from 'app/shared/service/settings.service';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrl: './user-avatar.component.scss'
})
export class UserAvatarComponent implements OnInit{

  constructor(
    public commonService:CommonService,
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService,
    public userService: UserService,
    private packagefeatureService: PackagefeatureService,
    private methodService: MethodService,
    private awsCognitoService: AwsCognitoService,
    private settingsService:SettingsService,
  ){}

  @Input() component: string = "";
  @Input() message: string = "";
  @Input() role: boolean = false;
  @Input() mobile: boolean = false;
  canUseDesigner: boolean = false;
  pools = [];
  tippyUserProps = {
    placement:'bottom',
    trigger: 'mousenter click',
    theme:'light-border',
    touch:true,
    interactive:true,
    appendTo:'parent'

  }



  ngOnInit(): void {
      this.canUseDesigner = this.packagefeatureService.selectedPackageHasFeature(this.applicationInfoService.applicationSettings['featureId_uidesigner']);
      if (this.applicationInfoService.isDeveloper) {
        this.canUseDesigner = true;
      }
      this.canUseDesigner = true;
  }
  
  getPools() {
    return this.applicationInfoService.pools.toArray().filter(pool => pool.isActive == true);
  }

  changeExpertMode() {
    if (this.applicationInfoService.expertMode == true) {
      this.applicationInfoService.expertMode = false;
      this.settingsService.userSettings['ExpertMode'].value = 'false'
    } else {
      this.applicationInfoService.expertMode = true;
      this.settingsService.userSettings['ExpertMode'].value = 'true'
    }
    this.settingsService.setUserSettingEx(this.settingsService.userSettings['ExpertMode'])
  }


  showMailbox() {
    this.eventService.showJjPopup('UserSettings.Label.Mailbox', 'mailbox', 90);
  }

  closePopup() {
    throw new Error('Method not implemented.');
  }

  showUserSettings() {
    this.applicationInfoService.routerHeaderLabelText = 'General.Menu.Settings';
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'usersettings']);
    //this.closePopup();
    if (this.applicationInfoService.isMobile()) {
      this.applicationInfoService.navigationExpandedMobile = false;
    }
  }


  changeDesignerMode() {
    this.applicationInfoService.changeDesignerMode();
  }

  getModeText(){
    return (this.applicationInfoService.designerMode ? "Designermodus deaktivieren" : "Designermodus aktivieren" )
  }


  getUserNameShortCut(user) {
    let userShortCut = '';
    if (user == null) { return ''; }
    if (user.firstName != null) {
      userShortCut = userShortCut.concat(user.firstName.substring(0,1))
    }
    if (user.lastName != null) {
      userShortCut = userShortCut.concat(user.lastName.substring(0,1))
    }    
    return userShortCut;
  }

  getNewUserMessages() {
    try {
      const unreadMessages = this.applicationInfoService.userMessages.filter(message => message.userMessages[0].readDateAt == null);
      if (this.commonService.isNullOrUndefined(unreadMessages)) {
        return 0;
      } else {
        return unreadMessages.length;
      }    
    }
    catch {
      console.error('getNewUserMessages error');
    }
  }


  logout() {
    this.awsCognitoService.logoutUserFromCognito();
  }


  changeUserRole() {
    this.userService.getUserPermissions().then(() => {
      this.eventService.customEvent.emit({ id: 'userRoleChanged' });
      this.eventService.customEvent.emit({ id: 'loadJsonNavigationAfterRoleChange' });  
    });    
  }

  changePool() {
    this.eventService.customEvent.emit({ id: 'selectedPoolChanged' });
    this.applicationInfoService.userSettings['lastSelectedPool'.concat(this.applicationInfoService.projectID.toString())] = this.applicationInfoService.poolId;
  }

  showProjectSelection() {
    this.eventService.showJjPopup('StaticForm.ProjectSelection.Header.Header', 'projectselection', '700px;80');
    this.applicationInfoService.campaingnModeId
  }
}
