<div class="toolbarNav" *ngIf="type !== 'phone'">
    <div class="toolbarNav__wrapper">
        <div *ngIf="type === 'plus'" class="toolbarNav__container">
            <button #toolbarItem class="toolbarNav__item" (click)="newItem(2)">
                <app-jj-icons [type]="'plus'" [width]="16" [height]="16"></app-jj-icons>
                <label>{{ 'Contact.Label.NewContact' | texttransform }}</label>
            </button>
            <button #toolbarItem class="toolbarNav__item" (click)="newItem(1)">
                <app-jj-icons [type]="'plus'" [width]="16" [height]="16"></app-jj-icons>
                <label>{{ 'Supervisor.Menu.NewAccount' | texttransform }}</label>
            </button>
            <button #toolbarItem class="toolbarNav__item" (click)="newItem(4)"
                *ngIf="commonService.checkApplicationRoute('account') == true"
                [disabled]="applicationInfoService.currentAccount == null">
                <app-jj-icons [type]="'plus'" [width]="16" [height]="16"></app-jj-icons>
                <label>{{ 'AccountFooter.Label.NewActivity' | texttransform }}</label>
            </button>

            <button #toolbarItem *ngIf="commonService.checkApplicationRoute('processvisual') == true"
                class="toolbarNav__item" (click)="createWorkflow()">
                <app-jj-icons [type]="'plus'" [width]="16" [height]="16"></app-jj-icons>
                <label>{{ 'ProcessDesign.Label.NewWorkflow' | texttransform }}</label>
            </button>

            <button #toolbarItem *ngIf="commonService.checkApplicationRoute('processvisual') == true && applicationInfoService.selectedProcessVisualWorkflow != 0 &&
                        userService.hasPermission('canCreateWorkflow')" class="toolbarNav__item"
                (click)="createStarterStep()">
                <app-jj-icons [type]="'plus'" [width]="16" [height]="16"></app-jj-icons>
                <label>{{ 'ProcessDesign.Label.NewStarterStep' | texttransform }}</label>
            </button>
        </div>

        <div *ngIf="type === 'research'" class="toolbarNav__containerResearch">
            <div class="toolbarNav__item" id="researchAccount"
                *ngIf="applicationInfoService?.currentAccount != null && applicationInfoService?.currentAccount?.isDummyAccount != true">
                <div class="toolbarNav__research">
                    <div class="toolbarNav__research__name">
                        <app-jj-icons [type]="'building'" [width]="25" [height]="25"></app-jj-icons>
                        <div class="toolbarNav__ellipsis"
                            [tippy]="applicationInfoService.stringMaxLength(applicationInfoService.currentAccount.name1, 25)">
                            {{ applicationInfoService.currentAccount.name1 | texttransform }}</div>
                    </div>
                    <div class="toolbarNav__research__divider"></div>
                    <div class="toolbarNav__research__search">
                        <div class="toolbarNav__research__search__label">
                            {{ 'Toolbar.Label.SearchIn' | texttransform }}
                        </div>
                        <div class="toolbarNav__research__search__icons">
                            <!--         <svg-icon src="assets/images/svg/iconset/icon-google.svg" class="fingerCursor"
                                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                (click)="launchGoogleSearch(1);$event.stopPropagation()"
                                [tippy]="'Research.Label.Google' | texttransform"></svg-icon> -->

                            <svg-icon src="assets/images/svg/iconset/icon-google.svg" class="fingerCursor"
                                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                (click)="launchGoogleSearch(1);$event.stopPropagation()"></svg-icon>

                            <!--                             <svg-icon src="assets/images/svg/iconset/icon-xing.svg" class="fingerCursor"
                                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                (click)="openXing(1);$event.stopPropagation()"
                                [tippy]="'Research.Label.Xing' | texttransform"
                                [tippyOptions]="{placement:'bottom'}"></svg-icon> -->

                            <svg-icon src="assets/images/svg/iconset/icon-xing.svg" class="fingerCursor"
                                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                (click)="openXing(1);$event.stopPropagation()"></svg-icon>

                            <!--       <svg-icon src="assets/images/svg/icon-linkedin.svg" class="fingerCursor"
                                [svgStyle]="{ 'width.px': 13, 'height.px': 13 }"
                                (click)="openLinkedIn(1);$event.stopPropagation()"
                                [tippy]="'Research.Label.LinkedIn' | texttransform"
                                [tippyOptions]="{placement:'bottom'}"></svg-icon> -->

                            <svg-icon src="assets/images/svg/icon-linkedin.svg" class="fingerCursor"
                                [svgStyle]="{ 'width.px': 13, 'height.px': 13 }"
                                (click)="openLinkedIn(1);$event.stopPropagation()"></svg-icon>

                            <app-jj-icons *ngIf="getAccountValue('url') != ''" [type]="'globe'" [width]="20"
                                [height]="20" [tippy]="getAccountValue('url')"
                                (click)="openURL(getAccountValue('url'));$event.stopPropagation()"
                                [tippyOptions]="{placement:'bottom'}"></app-jj-icons>

                        </div>
                    </div>
                </div>
            </div>

            <div class="toolbarNav__item" id="researchAccount" *ngIf="applicationInfoService?.currentContact != null">
                <div class="toolbarNav__research">
                    <div class="toolbarNav__research__name">
                        <app-jj-icons [type]="'user'" [width]="25" [height]="25"></app-jj-icons>
                        <div class="toolbarNav__ellipsis"
                            [tippy]="applicationInfoService.stringMaxLength((applicationInfoService.currentContact.firstName + applicationInfoService.currentContact.lastName), 25)">
                            {{ applicationInfoService.currentContact.firstName | texttransform }} {{
                            applicationInfoService.currentContact.lastName | texttransform }}</div>
                    </div>
                    <div class="toolbarNav__research__divider"></div>
                    <div class="toolbarNav__research__search">
                        <div class="toolbarNav__research__search__label">
                            {{ 'Toolbar.Label.SearchIn' | texttransform }}
                        </div>
                        <div class="toolbarNav__research__search__icons">
                            <!--    <svg-icon src="assets/images/svg/iconset/icon-google.svg" class="fingerCursor"
                            [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                            (click)="launchGoogleSearch(4);$event.stopPropagation()"
                            [tippy]="'Research.Label.Google' | texttransform"
                            [tippyOptions]="{placement:'bottom'}"></svg-icon> -->

                            <svg-icon src="assets/images/svg/iconset/icon-google.svg" class="fingerCursor"
                                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                (click)="launchGoogleSearch(4);$event.stopPropagation()"></svg-icon>

                            <!--       <svg-icon src="assets/images/svg/iconset/icon-xing.svg" class="fingerCursor"
                            [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                            (click)="openXing(4);$event.stopPropagation()"
                            [tippy]="'Research.Label.Xing' | texttransform"
                            [tippyOptions]="{placement:'bottom'}"></svg-icon> -->

                            <svg-icon src="assets/images/svg/iconset/icon-xing.svg" class="fingerCursor"
                                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                (click)="openXing(4);$event.stopPropagation()"></svg-icon>

                            <!--  <svg-icon src="assets/images/svg/icon-linkedin.svg" class="fingerCursor"
                            [svgStyle]="{ 'width.px': 13, 'height.px': 13 }"
                            (click)="openLinkedIn(4);$event.stopPropagation()"
                            [tippy]="'Research.Label.LinkedIn' | texttransform"
                            [tippyOptions]="{placement:'bottom'}"></svg-icon> -->


                            <svg-icon src="assets/images/svg/icon-linkedin.svg" class="fingerCursor"
                                [svgStyle]="{ 'width.px': 13, 'height.px': 13 }"
                                (click)="openLinkedIn(4);$event.stopPropagation()"></svg-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="phoneNav" *ngIf="type === 'phone'">
    <div *ngIf="ringcentralwebphoneService.extension == null" class="phoneNav__message">
        <div *ngIf="applicationInfoService.applicationSettings['isDemoproject']!='truex'">
            {{ 'Toolbar.Label.NotLoggedIn' | texttransform }}
        </div>
        <div *ngIf="applicationInfoService.applicationSettings['isDemoproject']=='truex' && mode!=1">
            {{ 'Toolbar.Label.NotLoggedInDemo' | texttransform }}
        </div>
    </div>

    <div class="phoneNav__item" *ngIf="mode==1 && ringcentralwebphoneService.extension != null">
        <div class="phoneNav__item__wrapper" *ngFor="let phoneItem of phoneItems">
            <div class="phoneNav__item__inner" *ngIf="getPhoneValue(phoneItem) != ''">
                <div class="phoneNav__item__container">
                    <div class="phoneNav__item__icon">
                        <app-jj-icons type="building" [width]="32" [height]="32"
                            *ngIf="phoneItem.entityId == 1"></app-jj-icons>
                        <app-jj-icons type="user" [width]="32" [height]="32"
                            *ngIf="phoneItem.entityId == 4"></app-jj-icons>
                    </div>
                    <div class="phoneNav__item__data">
                        <div class="phoneNav__item__data__label">{{ phoneItem.displayText | texttransform }}</div>
                        <div class="phoneNav__item__data__value">{{ getPhoneValue(phoneItem) }}</div>
                    </div>
                </div>

                <div class="phoneNav__item__call" (click)="makeCall(getPhoneValue(phoneItem))">
                    <app-jj-icons type="phone" [width]="25" [height]="25" color="primaryLight"></app-jj-icons>
                </div>
            </div>
        </div>
    </div>
</div>