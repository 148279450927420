

<div class="phoneNav">
    <div *ngIf="ringcentralwebphoneService.extension == null" class="phoneNav__message">
        <div *ngIf="applicationInfoService.applicationSettings['isDemoproject']!='truex'">
            {{ 'Toolbar.Label.NotLoggedIn' | texttransform }}
        </div>        
        <div *ngIf="applicationInfoService.applicationSettings['isDemoproject']=='truex' && mode!=1">
            {{ 'Toolbar.Label.NotLoggedInDemo' | texttransform }}
        </div>
    </div>

    <div class="phoneNav__item" *ngIf="mode==1 && ringcentralwebphoneService.extension != null">
        <div class="phoneNav__item__wrapper" *ngFor="let phoneItem of phoneItems">
            <div class="phoneNav__item__inner" *ngIf="getPhoneValue(phoneItem) != ''">
                <div class="phoneNav__item__container">
                    <div class="phoneNav__item__icon">
                        <app-jj-icons type="building" [width]="32" [height]="32" *ngIf="phoneItem.entityId == 1"></app-jj-icons>
                        <app-jj-icons type="user"  [width]="32" [height]="32" *ngIf="phoneItem.entityId == 4"></app-jj-icons>
                    </div>
                    <div class="phoneNav__item__data">
                        <div class="phoneNav__item__data__label">{{ phoneItem.displayText | texttransform }}</div>
                        <div class="phoneNav__item__data__value">{{ getPhoneValue(phoneItem) }}</div>    
                    </div>
                </div>

                <div class="phoneNav__item__call" (click)="makeCall(getPhoneValue(phoneItem))">
                    <app-jj-icons type="phone" [width]="25" [height]="25" color="primaryLight"></app-jj-icons>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- <span id="phoneDesignSpan"></span> -->
<!-- <div class="phone" [style.max-height.px]="getHeight('phoneDesignSpan', 0)" (click)="$event.stopPropagation()"> -->
<!--     <div class="phone__header" *ngIf="commonService.checkApplicationRoute('account') != true">
        <div class="phone__header__item fingerCursor" (click)="mode=2; $event.stopPropagation()">
            <div class="phone__header__item__svg">
                <svg-icon src="assets/images/svg/toolbar/toolbar_direct.svg" [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
            </div>
            <div class="phone__header__item__text">
                {{ 'Toolbar.Label.PhoneDirect' | texttransform }}
            </div>
        </div>
        <div class="phone__header__item fingerCursor" (click)="mode=3;$event.stopPropagation()">
            <div class="phone__header__item__svg">
                <svg-icon src="assets/images/svg/toolbar/toolbar_callhistory.svg" [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
            </div>
            <div class="phone__header__item__text">
                {{ 'Toolbar.Label.PhoneCallHistory' | texttransform }}
            </div>
        </div>
        <div class="phone__header__item fingerCursor" (click)="mode=4;$event.stopPropagation()">
            <div class="phone__header__item__svg">
                <svg-icon src="assets/images/svg/toolbar/toolbar_phonebook.svg" [svgStyle]="{ 'width.px': 48, 'height.px': 48 }"></svg-icon>
            </div>
            <div class="phone__header__item__text">
                {{ 'Toolbar.Label.Phonebook' | texttransform }}
            </div>
        </div>
    </div> -->

   <!--  <div *ngIf="ringcentralwebphoneService.extension == null" class="toolbarLoginInfo">
        <div *ngIf="applicationInfoService.applicationSettings['isDemoproject']!='truex'">
            {{ 'Toolbar.Label.NotLoggedIn' | texttransform }}
        </div>        
        <div *ngIf="applicationInfoService.applicationSettings['isDemoproject']=='truex' && mode!=1">
            {{ 'Toolbar.Label.NotLoggedInDemo' | texttransform }}
        </div>
    </div> -->

  <!--   <div class="phone__content" *ngIf="mode==1">
        <div class="phone__content__item" *ngFor="let phoneItem of phoneItems">
            <div class="phone__content__item__inner" *ngIf="getPhoneValue(phoneItem) != ''">
                <div class="phone__content__item__icon">
                    <div class="phone__iconWrapper" *ngIf="phoneItem.entityId == 1">
                        <svg-icon src="assets/images/svg/iconset/05icon-adressen.svg" 
                            [svgStyle]="{ 'width.px': 32, 'height.px': 32 }" style="margin-top: -4px"></svg-icon>
                    </div>
                    <div class="phone__iconWrapper" *ngIf="phoneItem.entityId == 4">
                        <i class="fas fa-user"></i>
                    </div>
                </div>
                <div class="phone__content__item__data">
                    <div class="phone__content__item__data__text">{{ phoneItem.displayText | texttransform }}</div>
                    <div class="phone__content__item__data__value">{{ getPhoneValue(phoneItem) }}</div>    
                </div>
                <div class="phone__content__item__call everleadGreen fingerCursor" (click)="makeCall(getPhoneValue(phoneItem))">
                    <svg-icon src="assets/images/svg/toolbar/toolbar_telefon_green.svg" [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"></svg-icon>
                </div>
            </div>
        </div>
    </div> -->


<!--     <div class="phone__content" *ngIf="mode==2 && ringcentralwebphoneService.extension!=null">
        <div>
            <label class="ellipsisText">{{ 'Toolbar.Label.NumberToCall' | texttransform }}</label>
            <input class="maxWidth" [(ngModel)]="numberToCall">
        </div>
        <div class="phone__content__direct__icon">
            <svg-icon (click)="makeCall(numberToCall)" class="fingerCursor" src="assets/images/svg/toolbar/toolbar_telefon_green.svg" [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"></svg-icon>
        </div>
    </div> -->

<!--     <div class="phone__content" *ngIf="mode==3">       
        <div *ngFor="let callHistoryItem of callHistory" class="callHistoryItem">
            <div class="ellipsisText everleadCoral">
                {{ callHistoryItem.phoneNumber }}
            </div>
            <div class="callHistoryItem__date">
                {{ callHistoryItem.start | date: 'medium' }}
            </div>
            <div class="callHistoryItem__duration">
                {{ callHistoryItem.durationFormatted }}
            </div>
            <div class="callHistoryItem__number">
                <svg-icon src="assets/images/svg/toolbar/toolbar_telefon_green.svg" class="fingerCursor" (click)="makeCall( callHistoryItem.phoneNumber)" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>    
            </div>
        </div>
    </div> -->
    
  <!--   <div *ngIf="mode==4 && adressBook?.length > 0">
        <div class="phone__content__filter">
            <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
            <input [(ngModel)]="filterString" class="maxWidth">
        </div>
    </div> -->

<!--     <div class="phone__content" *ngIf="mode==4">
        <div *ngFor="let adressBookItem of adressBook" class="phonebookItem" [ngClass]="{ hideInDom: isItemFiltered(adressBookItem) }">
            <div class="ellipsisText everleadCoral">
                {{ adressBookItem.firstName }} {{ adressBookItem.lastName }}
            </div>
            <div class="phonebookItem__number">
                <div class="phonebookItem__number__phone" *ngIf="adressBookItem.phoneNumber != ''">
                    <div class="ellipsisText">{{ adressBookItem.phoneNumber }}</div>
                    <svg-icon src="assets/images/svg/toolbar/toolbar_telefon_green.svg" class="fingerCursor" (click)="makeCall( adressBookItem.phoneNumber)" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>    
                </div>
                <div class="phonebookItem__number__phone" *ngIf="adressBookItem.extension != ''">
                    <div class="ellipsisText">{{ adressBookItem.extension }}</div>
                    <svg-icon src="assets/images/svg/toolbar/toolbar_telefon_green.svg" class="fingerCursor" (click)="makeCall( adressBookItem.extension)" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>    
                </div>
            </div>
        </div>
    </div> -->
<!-- </div> -->