<app-layout header="Settings" class="userSettings">
    <div class="userSettings__inner">
        <div class="userSettings__tabs">
            <app-tab svg="gear" label="UserSettings.Label.GeneralSettings" [active]="tab === 1"
                (onClick)="tab=1"></app-tab>


           <!--  <app-tab svg="user-circle-dashed" label="User Faken" *ngIf="userService.hasPermission('CanFakeUser')"
                [active]="tab === 1" (onClick)="tab=1"></app-tab> -->

<!--             <app-tab svg="toggle-left" label="UserSettings.Label.ExpertMode"
                *ngIf="userService.hasPermission('CanUpdateExpertMode')" [active]="tab===2" (onClick)="tab=2"></app-tab> -->

                <app-tab svg="rocket" label="DEV" *ngIf="applicationInfoService.isDeveloper" [active]="tab === 2"
                (onClick)="tab=2"></app-tab>

            <app-tab svg="qr-code" label="Multi-factor authentication" *ngIf="!isMfaRequired" [active]="tab === 3"
                (onClick)="tab=3"></app-tab>

            <app-tab svg="eye" label="Supervisor.Label.ManageVip" *ngIf="applicationInfoService.applicationSettings['showVipSettings'] == 'true'" [active]="tab === 4" (onClick)="tab=4"></app-tab>

 
        </div>


        <div class="userSettings__content">

            <div *ngIf="tab === 1">
                <app-layout header="UserSettings.Label.Avatar" class="userSettings__containerMargin">
                    <div class="userSettings__componentInner">
                        <div class="userSettings__item maxHeight">
                            <div class="userSettings__item__label">
                                <img [src]="applicationInfoService.userAvatar" class="userAvatar"
                                    *ngIf="applicationInfoService.userAvatar != 'none'">
                                <div class="userAvatar__notFound" *ngIf="applicationInfoService.userAvatar == 'none'">
                                    <app-jj-icons type="user" [width]="100" [height]="100"></app-jj-icons>
                                    <div>
                                        {{ 'UserSettings.Label.NotUploadedYet' | texttransform }}
                                    </div>
                                </div>
                            </div>
                            <div class="userSettings__item__interaction maxHeight">
                                <input type="file" #file accept="image/*" placeholder="Choose file"
                                    (change)="uploadAvatar($event)" style="display:none;">

                                <div class="userAvatar__buttonWrapper">
                                    <app-main-button type="small" *ngIf="this.applicationInfoService.userAvatar != 'none'"
                                        label="UserSettings.Label.RemoveAvatar" svg="trash"
                                        (onClick)="removeAvatar()"></app-main-button>

                                    <app-main-button type="small" svg="user-plus" label="UserSettings.Label.UploadAvatar"
                                        (onClick)="file.click()"></app-main-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-layout>

                <app-layout header="User faken" *ngIf="userService.hasPermission('CanFakeUser')" class="userSettings__containerMargin">
                    <div class="userSettings__componentInner">
                    <app-select-input label="Zu fakender User:" [(ngModel)]="applicationInfoService.user.fakeUserId"
                        (valueChange)="fakeUser(applicationInfoService.user.fakeUserId)" bind="id" display="userName"
                        [options]="userList"></app-select-input>
                    </div>
                </app-layout>

                <app-layout header="General._.Language" class="userSettings__containerMargin">
                    <div class="userSettings__componentInner">
                        <app-select-input label="General._.Language"
                            [(ngModel)]="axivasTranslateService.currentLanguage" (valueChange)="changeLanguage()"
                            bind="value" display="label"
                            [options]="[{value:'de', label:'Deutsch'},{value:'en', label:'English'}]"></app-select-input>
                    </div>
                </app-layout>

                <app-layout header="UserSettings.Label.ExpertMode" *ngIf="userService.hasPermission('CanUpdateExpertMode')" class="userSettings__containerMargin">
                    <div class="userSettings__componentInner">
                        <div class="userSettings__info">{{ 'UserSettings.Label.ExpertModeDescription' | texttransform }}
                        </div>

                        <!-- todo compare the value with older version its  string -->
                        <app-select-input label="UserSettings.Label.ExpertModeActive"
                            [(ngModel)]="settingsService.userSettings['ExpertMode'].value"
                            (valueChange)="changeExpertMode()" bind="value" display="label"
                            [options]="[{value:'true', label:'UserSettings.Label.ExpertModeOn'},{value:'false', label: 'UserSettings.Label.ExpertModeOff'}]"></app-select-input>

                <!--           <select [(ngModel)]="settingsService.userSettings['ExpertMode'].value"
                    (change)="changeExpertMode()">
                    <option value="true">{{ 'UserSettings.Label.ExpertModeOn' | texttransform }}</option>
                    <option value="false">{{ 'UserSettings.Label.ExpertModeOff' | texttransform }}</option>
                </select> -->
                    </div>
                </app-layout>

                <app-layout header="RingCentral" class="userSettings__containerMargin">
                    <div class="userSettings__componentInner">

                        <!-- todo this input never in the old format i assumed it was a text ? -->
                        <app-text-input *ngIf="settingsService.userSettings['Extension3CX']"
                            label="RingCentral.Label.Extension"
                            [(ngModel)]="settingsService.userSettings['Extension3CX'].value"
                            (valueChange)="settingsService.setUserSettingEx(settingsService.userSettings['Extension3CX'])"></app-text-input>

    <!--                         <input [(ngModel)]="settingsService.userSettings['Extension3CX'].value"
                            (change)="settingsService.setUserSettingEx(settingsService.userSettings['Extension3CX'])"> -->

                        <app-checkbox label="RingCentral.Label.UseWebRtc"
                            [(ngModel)]="applicationInfoService.userSettings['useRingCentralWebRTC']"
                            (valueChange)="settingsService.updateSecurityUserSettingsJson()"></app-checkbox>

                        <app-checkbox *ngIf="applicationInfoService.applicationSettings['showPhoneSelection'] == 'true'"
                            label="RingCentral.Label.UseHardphone"
                            [(ngModel)]="applicationInfoService.userSettings['ringCentralUseHardphone']"
                            (valueChange)="changeRingCentralHardphone()"></app-checkbox>

                            <!-- todo talk to stucky about this select, when the array is empty is disabled ? compare with older input-->
                        <app-select-input
                            *ngIf="applicationInfoService.applicationSettings['showPhoneSelection'] == 'true'"
                            label="RingCentral.Label.OwnNumber"
                            [(ngModel)]="applicationInfoService.userSettings[ringCentralOwnNumberSetting]"
                            [options]="ringcentralwebphoneService.phoneNumbers" bind="phoneNumber" display="phoneNumber"
                            (valueChange)="changeRingCentralNumber()"
                            [disabled]="ringcentralwebphoneService.phoneNumbers.length === 0"></app-select-input>

                    </div>

                    <!--                     <div class="userSettings__item__interaction" >
                        <select [(ngModel)]="applicationInfoService.userSettings[ringCentralOwnNumberSetting]"
                            (change)="changeRingCentralNumber()">
                            <option [ngValue]=null></option>
                            <option *ngFor="let ownNumber of ringcentralwebphoneService.phoneNumbers" [ngValue]="ownNumber.phoneNumber">{{ ownNumber.phoneNumber }}</option>
                        </select>
                    </div> -->

                </app-layout>

                <app-layout header="UserSettings.Label.PageSize" class="userSettings__containerMargin">

                    <app-select-input *ngIf="settingsService.userSettings['MatTablePagesize']"
                        label="UserSettings.Label.PageSize"
                        [(ngModel)]="settingsService.userSettings['MatTablePagesize'].value" bind="value"
                        display="label"
                        (valueChange)="settingsService.setUserSettingEx(settingsService.userSettings['MatTablePagesize'])"
                        [options]="[{value:'10', label:'10'}, {value:'20', label:'20'},{value:'50', label:'50'}, {value:'100',label:'100'},{value:'200', label:'200'}]"></app-select-input>

                </app-layout>

            </div>

           <!--  <div *ngIf="tab === 1">
                <app-layout header="User faken" *ngIf="userService.hasPermission('CanFakeUser')">
                    <div class="userSettings__componentInner">
                    <app-select-input label="Zu fakender User:" [(ngModel)]="applicationInfoService.user.fakeUserId"
                        (valueChange)="fakeUser(applicationInfoService.user.fakeUserId)" bind="id" display="userName"
                        [options]="userList"></app-select-input>
                    </div>
                </app-layout>
            </div> -->

          <!--   <div *ngIf="tab === 2">
                <app-layout header="UserSettings.Label.ExpertMode" *ngIf="userService.hasPermission('CanUpdateExpertMode')">
                    <div class="userSettings__componentInner">
                    <div class="userSettings__info">{{ 'UserSettings.Label.ExpertModeDescription' | texttransform }}
                    </div>

                
                    <app-select-input label="UserSettings.Label.ExpertModeActive"
                        [(ngModel)]="settingsService.userSettings['ExpertMode'].value"
                        (valueChange)="changeExpertMode()" bind="value" display="label"
                        [options]="[{value:'true', label:'UserSettings.Label.ExpertModeOn'},{value:'false', label: 'UserSettings.Label.ExpertModeOff'}]"></app-select-input>
                    </div>
                </app-layout>
            </div> -->

            <div *ngIf="tab === 2">
                <app-layout header="debug" class="userSettings__containerMargin">
                    <div class="userStettings__componentInner">
                        <label>Version: {{ applicationInfoService.versionNumber }}</label><br>
                        <label>Application Name: {{ applicationName }}</label>
                        <br>
                        <app-debugpanel></app-debugpanel>
                    </div>

                </app-layout>

                <app-layout header="Editiermodus" class="userSettings__containerMargin">

                    <div class="userSettings__componentInner">
                        <div class=" userSettings__buttonsFlexWrapper">
                            <app-main-button [type]="applicationInfoService.designerMode ? 'border': undefined"
                                label="Editiermodus aktivieren" (onClick)="changeDesignerMode()">
                            </app-main-button>

                            <app-main-button label="Neues Design aktivieren" (onClick)="changeNewDesignMode()"
                                [type]="applicationInfoService.useNewDesign ? 'border': undefined"></app-main-button>

                            <app-main-button label="Testumgebung anzeigen" (onClick)="changeTestEnvironmentMode()"
                                [type]="applicationInfoService.testEnvironment ? 'border' : undefined"></app-main-button>

                            <app-main-button label="E-Mail Queue aktualisieren"
                                (onClick)="updateEMailQueue()"></app-main-button>

                        </div>
                    </div>

                </app-layout>

                <app-layout header="Central" class="userSettings__containerMargin">

                    <app-main-button svg="database" label="Update Central Schema"
                        (onClick)="updateCentralSchema()"></app-main-button>

                    <div class="userSettings__componentInner">

                        <div class="userSettings__containerRow">
                            <label>Update Project</label>
                            <div class="userSettings__containerRow__inner">
                                <app-select-input label="Data" [(ngModel)]="schemaReloadProjectId" bind="id"
                                    display="projectName" [options]="projects"></app-select-input>

                                <app-main-button svg="database" type="small" label="Update Data Schema"
                                    (onClick)="updateSchema()"></app-main-button>
                            </div>
                        </div>

                        <div class="userSettings__containerRow">
                            <label>Delete Project</label>
                            <div class="userSettings__containerRow__inner">
                                <app-select-input label="Data" [(ngModel)]="deleteProjectId" bind="id"
                                    display="projectName" [options]="projects"></app-select-input>

                                <app-main-button svg="trash" type="small" label="Delete project"
                                    (onClick)="deleteProject()"></app-main-button>
                            </div>
                        </div>
                    </div>

                </app-layout>

                <app-layout header="Data Cache" class="userSettings__containerMargin">

                    <div class="userSettings__componentInner">
                        <div class="userSettings__containerRow">
                            <label>Cache Central</label>
                            <div class="userSettings__containerRow__inner">
                                <div class="userSettings__containerRow__inputContainer">
                                    <app-select-input label="Cache leeren (Custom)" [(ngModel)]="cacheCentral"
                                        [complex]="true" display="option"
                                        [options]="centralCacheResetItems"></app-select-input>
                                    <app-text-input label="Cache Central" [(ngModel)]="cacheCentral"></app-text-input>
                                </div>

                                <app-main-button type="small" svg="database" label="Aktualisieren"
                                    (onClick)="updateCache(cacheCentral)"></app-main-button>

                            </div>
                        </div>

                        <div class="userSettings__containerRow">
                            <label>Data</label>
                            <div class="userSettings__containerRow__inner">
                                <div class="userSettings__containerRow__inputContainer">
                                    <app-select-input label="Cache leeren (Custom)" [(ngModel)]="cacheData"
                                        [complex]="true" display="option"
                                        [options]="dataCacheResetItems"></app-select-input>
                                    <app-text-input label="cache Data" [(ngModel)]="cachedata"></app-text-input>
                                </div>

                                <app-main-button type="small" svg="database" label="Aktualisieren"
                                    (onClick)="updateDataCache(cacheData)"></app-main-button>
                            </div>
                        </div>

                        <div class="userSettings__containerRow">
                            <label>unload</label>
                            <div class="userSettings__containerRow__inner">
                                <div class="userSettings__containerRow__inputContainer">
                                    <app-select-input label="Data" [(ngModel)]="schemaUnloadProjectId"
                                        [options]="projects" bind="id" display="projectName"></app-select-input>
                                </div>
                                <app-main-button type="small" svg="database" label="Unload Data Schema"
                                    (onClick)="unloadSchema()"></app-main-button>
                            </div>
                        </div>

                    </div>
                </app-layout>

                <app-layout header="API" class="userSettings__containerMargin">
                    <div class=" userSettings__containerInner">
                        <div class="userSettings__containerRow">
                            <label>API - CENTRAL: {{apiUrl}} </label>
                            <!--    {{apiUrl}} -->
                            <div class="userSettings__buttonsContainer">
                                <app-main-button svg="database" label="CentralAPI - Repo Cache löschen"
                                    (onClick)="resetCacheCentral()"></app-main-button>

                                <app-main-button svg="database" label="Hasura"
                                    (onClick)="openExternalSite('hasuraCentral')"></app-main-button>

                                <app-main-button svg="database" label="Playground"
                                    (onClick)="openExternalSite('playgroundCentral')"></app-main-button>

                                <app-main-button svg="database" label="Portainer"
                                    (onClick)="openExternalSite('portainer')"></app-main-button>
                            </div>
                        </div>

                        <div class="userSettings__containerRow">
                            <label> API - BACKEND: {{ apiBackend }}</label>
                      <!--       {{ apiBackend }} -->

                            <div class="userSettings__buttonsContainer">
                                <app-main-button svg="database" label="Hasura"
                                    (onClick)="openExternalSite('hasuraData')"></app-main-button>
                                <app-main-button svg="database" label="Playground"
                                    (onClick)="openExternalSite('playgroundData')"></app-main-button>
                                <app-main-button svg="database" label="HasuraReload"
                                    (onClick)="reloadHasura()"></app-main-button>
                            </div>
                        </div>

                        <div class="userSettings__containerRow">
                            <label>Do Not Untrack</label>
                            <div class="userSettings__containerRow__inner">
                                <app-text-input label="Do Not Untrack" [(ngModel)]="doNotUntrackValue"></app-text-input>
                                <app-main-button type="small" svg="database" label="Ausführen"
                                    (onClick)="doNotUntrack()"></app-main-button>
                            </div>
                        </div>
                    </div>
                </app-layout>

            </div>

            <div *ngIf="tab === 3">
                <app-layout header="Multi-factor authentication">
                    <div class="userSettings__componentInner">
                        <app-checkbox label="Setup MFA" [(ngModel)]="mfaSetupEnabled"></app-checkbox>
                        @if (mfaSetupEnabled) {
                        @if (!userAuthenticated) {
                        <div class="userSettings__containerRow__inner">
                            <app-text-input label="Enter your password" type="password"
                                [(ngModel)]="userPass"></app-text-input>
                            <app-main-button svg="paper-plane-tilt" type="small" label="Submit"
                                (onClick)="authenticateUser()"></app-main-button>
                        </div>
                        } @else {
                        <br>
                        @if (isMfaEnabled) {
                        <div class="userSettings__containerRow">
                            <label>MFA status: <span class="everleadGreen">enabled</span></label>
                            <div class="userSettings__containerRow__inner">
                                <app-text-input label="Enter Current MFA Code to disable"
                                    [(ngModel)]="setupMfaAnswer"></app-text-input>
                                <app-main-button svg="paper-plane-tilt" type="small"
                                    (onClick)="disableMfa()">Send</app-main-button>
                            </div>
                        </div>

                        } @else {
                        <div class="userSettings__containerRow">
                            <label>MFA status: <span class="everleadRed">disabled</span></label>

                            <div class="userSettings__containerRow__inner">
                                <div class="qrCodeContainer">
                                    <qrcode
                                        [qrdata]="'otpauth://totp/Everlead:' + applicationInfoService.user.email + '?secret=' + setupMfaSecretCode + '&issuer=Everlead'"
                                        [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                                    <p>Scan this code with your auth app</p>
                                </div>
                                <app-text-input label="Enter code to enable"
                                    [(ngModel)]="setupMfaAnswer"></app-text-input>
                                <app-main-button label="send" (onClick)="finishMfaSetup()"></app-main-button>
                            </div>
                        </div>

                        }


                        }}


                    </div>
                </app-layout>

            </div>
            
            <div *ngIf="tab === 4">
                <app-layout header="Supervisor.Label.ManageVip">
                    <div class="userSettings__componentInner">
                        <div class="userSettings__info">{{ 'Vip.Label.VipDescription' | texttransform }}</div>

                        <app-select-input
                        *ngIf="settingsService.userSettings['VipAlertFrequency']"
                        label="UserSettings.Label.VipAlertFrequency"
                        [(ngModel)]="settingsService.userSettings['VipAlertFrequency'].value"
                        (valueChange)="settingsService.setUserSettingEx(settingsService.userSettings['VipAlertFrequency'])"
                        bind="value"
                        display="label"
                        [options]="[
                            {value:'none', label:'UserSettings.Label.NormalVisitorAlertFrequency.None'},
                            {value: 'direct', label: 'UserSettings.Label.NormalVisitorAlertFrequency.Direct'},
                            {value: 'daily', label: 'UserSettings.Label.NormalVisitorAlertFrequency.Daily'},
                            {value: 'weekly', label: 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly'}]"></app-select-input>

                        <app-select-input
                        *ngIf="settingsService.userSettings['NormalVisitorAlertFrequency']"
                        label="UserSettings.Label.NormalVisitorAlertFrequency"
                        [(ngModel)]="settingsService.userSettings['NormalVisitorAlertFrequency'].value"
                        (valueChange)="settingsService.setUserSettingEx(settingsService.userSettings['NormalVisitorAlertFrequency'])"
                        bind="value"
                        display="label"
                        [options]="[
                            {value:'none', label: 'UserSettings.Label.NormalVisitorAlertFrequency.None'},
                            {value:'direct', label:'UserSettings.Label.NormalVisitorAlertFrequency.Direct'},
                            {value: 'daily', label: 'UserSettings.Label.NormalVisitorAlertFrequency.Daily'},
                            {value:'weekly', label: 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly'}]"></app-select-input>

                        <div class="userSettings__buttonsContainer">
                            <app-main-button *ngIf="settingsService.userSettings['Extension3CX']"
                            svg="list-star" 
                            label="UserSettings.Label.OpenVipSettings" 
                            (onClick)="openVipSettings()"></app-main-button>
                        </div>
                    </div>

                </app-layout>

            </div>
        </div>

        <div class="applicationIds">pool ID: {{ applicationInfoService.poolId }} / campaign Mode ID: {{ applicationInfoService.campaingnModeId }} </div>
    </div>
</app-layout>