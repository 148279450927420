import { Component, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-dashboardmaincontainer',
  templateUrl: './dashboardmaincontainer.component.html',
  styleUrl: './dashboardmaincontainer.component.scss'
})
export class DashboardmaincontainerComponent implements OnInit {
  reports = null;
  selectedReport = 6;
  dashboards = [
    { id: 1, defaultName: 'Projektübersicht' },
    { id: 2, defaultName: 'Opportunity' },
    { id: 3, defaultName: 'Activity' },
    { id: 4, defaultName: 'Leadstatus - Entwicklung' },
    { id: 5, defaultName: 'Consent' },
    { id: 6, defaultName: 'Agent WVL' }
  ]
  constructor(
    private externaldatasourceService: ExternaldatasourceService
  ) {}

  ngOnInit(): void {
    this.getReports();
  }
  
  getReports() {
    this.externaldatasourceService.executeExternalDataSource(956).then(getReportsResult => {
      this.reports = getReportsResult;      
    });
  }
}
