import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss'
})
export class AccordionComponent {

  @Input() title: string | null = null;
  @Input() isOpen: boolean = false;
  @Input() date: any | null= null;
  @Input() everleadHeader: boolean | null = false;
  @Input() value: number | null = null;
  @Input() valueTwo: number | null = null; 

  // todo 
  @Input() borderBottom: boolean | null = true;
  
  

  togglePanel(){
    this.isOpen= !this.isOpen;
  }

}
