import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import tippy from 'tippy.js';


@Component({
  selector: 'app-tippy-tooltip',
  templateUrl: './tippy-tooltip.component.html',
  styleUrls: ['./tippy-tooltip.component.scss']
})
export class TippyTooltipComponent implements OnInit , AfterViewInit, OnDestroy, OnChanges{
  @ViewChild('tippyTemplateRef',{ read: ElementRef, static: true }) tippyTemplateRef: ElementRef; 

  @Input() content;
  @Input() useTemplate: boolean = false;
  @Input() tooltipOptions: NgxTippyProps;
  
  tooltippyOptions: NgxTippyProps;


   private tippyInstance : any;

  constructor(
    private elementRef: ElementRef,
    private commonService: CommonService,
    private domSanitizer: DomSanitizer) 
    { }


  ngOnInit(): void {
   if(this.content && !this.useTemplate){
    this.tippyInstance = tippy(this.elementRef.nativeElement,{
      content: this.content,
      arrow: true,
      placement:'auto',
      theme: 'light-border',
      interactive: false,
      ...this.tooltipOptions
     })
   } 

   if(this.useTemplate){
    this.tooltippyOptions = {
      arrow: true,
      placement:'auto',
      theme: 'light-border',
      interactive: true,
      allowHTML:true,
      ...this.tooltipOptions
     }
   }
    //console.log('innerHTML',this.innerHTML)

   }

  ngOnChanges(changes: SimpleChanges){
    if(changes){
      //console.log('changes first change',changes)
    }

  }

  ngAfterViewInit(): void{
    //console.log('tippyRef', this.tippyTemplateRef)
  }

  ngOnDestroy(): void{
    this.tippyInstance
  }
}


