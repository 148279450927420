import { Component, Input, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardMode, WizardService } from '../wizard.service';

@Component({
  selector: 'app-wizard-translations',
  templateUrl: './wizard-translations.component.html',
  styleUrls: ['./wizard-translations.component.scss']
})

export class WizardTranslationsComponent implements OnInit {
  @Input() array = null;
  @Input() mode = 1;
  @Input() exclude = [];
  @Input() defaultValue = 'defaultName';
  @Input() updateBaseArrayExternaldatasourceId = null;
  @Input() projectSpecific = true;
  @Input() svgW :number | string | null= null;
  @Input() svgH :number | string | null= null;

  
  
  translation = [];

  constructor(
    public wizardService: WizardService,
    public externaldatasourceService: ExternaldatasourceService,
  ) { }

  ngOnInit(): void {
  }

  closePopup() {
    this.wizardService.showTranslationEditor = false;
  }
 
  showPopup() {    
    this.wizardService.translationArrayBase = this.array;
    this.wizardService.translationProjectSpecific = this.projectSpecific;
    this.wizardService.exclude = this.exclude;
    this.wizardService.translationArrayBaseUpdateExternalDataSourceId = this.updateBaseArrayExternaldatasourceId;
    this.wizardService.translationIds = [];
    this.wizardService.translationArray = [];
    this.wizardService.translationDefaultValue = this.defaultValue;    
    this.wizardService.showWizard(WizardMode.Translation, this.array, '80', '80');
  }  
}
