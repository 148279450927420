import { Component } from '@angular/core';

@Component({
  selector: 'app-ratingbar',
  templateUrl: './ratingbar.component.html',
  styleUrl: './ratingbar.component.scss'
})
export class RatingbarComponent {

}
