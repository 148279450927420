<div class="websiteDashboard__wrapper">
    <div class="websiteDashboard__topRow">
        <div class="websiteDashboard__topRow__item websiteDashboard__topRow__item__left">
           <!--  <app-dashbdashboardcomponent-myvisitors class="maxHeight"></app-dashbdashboardcomponent-myvisitors> -->
          <!--  <app-dashboardcomponent-myconversions class="maxHeight"></app-dashboardcomponent-myconversions> -->
            <app-flex-dashboard type="myvisitors"></app-flex-dashboard>
        </div>
        <div class="websiteDashboard__topRow__item websiteDashboard__topRow__item__right">
            <app-flex-dashboard type="myconversions"></app-flex-dashboard>
           <!--  <app-dashbdashboardcomponent-myvisitors class="maxHeight"></app-dashbdashboardcomponent-myvisitors> -->
          <!--   <app-dashboardcomponent-myconversions class="maxHeight"></app-dashboardcomponent-myconversions> -->
        </div>
    </div>
    <div class="websiteDashboard__bottomRow">
        <div class="everleadContentContainer" class="maxHeight">
            <div [innerHTML]="innerHTML" class="everleadMaxHeightOverflow"></div>
        </div>
    </div>
</div>

