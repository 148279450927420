import { Component, Input, OnInit } from '@angular/core';
import { MethodService , InternalMethodType} from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-visitors-list',
  templateUrl: './visitors-list.component.html',
  styleUrl: './visitors-list.component.scss'
})
export class VisitorsListComponent implements OnInit{
  constructor(private methodService: MethodService,){}

  @Input() data = null;
  @Input() isOdd = false;

  ngOnInit(): void {
      this.data = JSON.parse(this.data)
      /* console.log('this.data', this.data) */
  }

  navigateTo(route){
    this.methodService.launchInternalMethod(InternalMethodType.NavigateTo, [route]);
  }

}
