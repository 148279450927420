import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrl: './radio-button.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true
    },
/*     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true
    } */
  ]
})
export class RadioButtonComponent implements ControlValueAccessor{
  @Input() options: any[] = [];
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() type: string = 'column';

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();



  value: any;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.required && !this.value) {
      return { required: true };
    }
    return null;
  }

  updateValue(option: any): void {
    if (!this.disabled) {
      this.value = option;
      this.valueChange.emit(this.value)
      this.onChange(this.value);
      this.onTouched();
    }
  }
}
