<div class="ajandoHeaderWrapper">    
    <div class="ajandoHeaderMiscInfo">
        <div class="headerLeftNav">
            <div [id]=applicationInfoService.projectID class="headerLeftNav__button" role="button"
                (click)="showProjectSelection()">
                <div class="headerLeftNav__button__header">
                    <div class="circle">
                        <app-jj-icons type="pencil-simple-fill" [width]="10" [height]="10"></app-jj-icons>
                    </div>
                    {{ 'Projekt' | texttransform }}
                </div>
                <label class="headerLeftNav__button__label">
                    <svg title="Testumgebung - Änderungen in diesem System werden täglich überschrieben. <br>BITTE NICHT PRODUKTIV NUTZEN!"
                        *ngIf="applicationInfoService.testEnvironment == true" class="exclamation"
                        xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                        <path
                            d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z">
                        </path>
                    </svg>
                    {{ applicationInfoService.projectName }}
                </label>
            </div>
            <div (click)="showProjectSettings()" role="button" *ngIf="!applicationInfoService.blockSettingChange"
                class="headerLeftNav__button">

                <div class="headerLeftNav__button__header">
                    <div class="circle">
                        <app-jj-icons type="pencil-simple-fill" [width]="10" [height]="10"></app-jj-icons>
                    </div>
                    {{ 'Pool-DatenSatz' | texttransform }}
                </div>
                <label class="headerLeftNav__button__label">{{ getPoolName() }}</label>
                <div class="headerItemSettings second-color"
                    *ngIf="applicationInfoService?.availableUserGroups?.length > 1">
                    <svg-icon src="assets/images/svg/contact-groups.svg" class="ajandoHeaderIcon "></svg-icon>
                    <span class="navigaionInfoSpan">{{ getUserFollowupGroup() | texttransform }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="ajandoHeaderPhoneWidget">
        <app-phone-ringcentral-widget></app-phone-ringcentral-widget>
    </div>

    <div class="ajandoHeaderAppToolbar">
        <app-toolbar-header></app-toolbar-header>
    </div>
</div>