import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-main-button',
  templateUrl: './main-button.component.html',
  styleUrl: './main-button.component.scss'
})
export class MainButtonComponent implements OnInit{

  @Input() id: string | number | null = null ;
  @Input() label: string | null = null ;
  @Input() disabled: boolean = false;
  @Input() svg: string = null;
  @Input() type: string | null = null;
  @Output() onClick = new EventEmitter<any>();



  ngOnInit(){
    //console.log('main button label', this.label)
  }


  buttonClick(){
    this.onClick.emit();
  }

}
